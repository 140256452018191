import React, { memo, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Offers from 'modules/Offers';
import { Typography, TypographyVariant } from 'components/Typography';
import Modals from 'modules/Modals';
import SpinRewardsCarousel from './components/SpinRewardsCarousel';
import useSpinRewardsRetry from './hooks/useSpinRewardsRetry';
import './spinRewards.css';

const SpinRewards = () => {
  const dispatch = useAppDispatch();
  const [carouselRewards] = useAppSelector(Offers.selectors.getCarouselRewards) ?? [];
  const hasData = Boolean(carouselRewards && carouselRewards.subOffers?.length > 0);
  const currentIndex = useMemo(
    () => carouselRewards?.subOffers.findIndex((spin) => spin.status === 'CURRENT'),
    [carouselRewards]
  );
  // Retry if the current day is either blocked or still assigning free spins.
  const isCurrentDayBlockedOrAssigning = hasData
    ? carouselRewards?.subOffers[currentIndex]?.isBlocked ||
      carouselRewards?.subOffers[currentIndex]?.isAssigningFreeSpins
    : false;

  const openTCModal = useCallback(
    () =>
      dispatch(
        Modals.actions.open('rewardTermsConditions', {
          content: carouselRewards?.termsAndConditions
        })
      ),
    [dispatch, carouselRewards]
  );

  useSpinRewardsRetry({
    refName: carouselRewards.ref,
    shouldRetry: isCurrentDayBlockedOrAssigning
  });

  if (!hasData) {
    return null;
  }

  return (
    <div className="spinRewards">
      <div className="spinRewards__head">
        <Typography variant={TypographyVariant.BodySmStrong}>
          <div className="spinRewards__title">{carouselRewards.title}</div>
        </Typography>
        <Typography variant={TypographyVariant.BodyXs}>
          <div className="spinRewards__terms" onClick={openTCModal}>
            T&C’s apply
          </div>
        </Typography>
      </div>
      <SpinRewardsCarousel
        sectionRef={carouselRewards.ref}
        sectionTitle={carouselRewards.title}
        subOffers={carouselRewards.subOffers}
        currentSlideIndex={currentIndex ?? 0}
      />
    </div>
  );
};

export default memo(SpinRewards);
