import React, { useCallback } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { EffectTypes } from 'services/Effects';
import Analytics from 'modules/Analytics';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import 'components/RewardOfferCard/offerCard.css';
import type { RewardOffer } from 'modules/User';
import RewardOfferInfoWithStickyActions from './components/RewardOfferInfoWithStickyActions';
import RewardOfferInfo from './components/RewardOfferInfo';
import ErrorState from './components/ErrorState';
import useRewardOfferDetails from './hooks/useRewardOfferDetails';

export enum RewardOfferDetailsModalType {
  Sticky = 'sticky'
}

interface RewardOfferDetailsProps {
  close: () => void;
  rewardOfferData: RewardOffer;
  modalParams: {
    source: EffectTypes.OfferClickedSourceType;
    modalType?: RewardOfferDetailsModalType;
    ref?: string;
  };
}

const RewardOfferDetails = ({ close, rewardOfferData, modalParams }: RewardOfferDetailsProps) => {
  const dispatch = useAppDispatch();
  const { source, modalType, ref: offerRef } = modalParams;
  const { data, isLoading } = useRewardOfferDetails({ offerRef, rewardOfferData });
  const { ref, type, title, content, heading, termsAndConditions, cta, ctaText, imageUrl } =
    data || {};
  const showCTAButton = Boolean(cta);

  const handleClaimOfferClick = useCallback(() => {
    if (!ref) return;

    dispatch(
      Analytics.actions.offerClicked({
        refName: ref,
        type: type || 'SIMPLE',
        source,
        interactionType: EffectTypes.OfferClickedInteractionType.ModalCTAClick
      })
    );
    close();
  }, [close, dispatch, ref, type, source]);

  return (
    <>
      {isLoading && !data && (
        <UserBox id="offerCardModal" title="Loading..." closeCallback={close}>
          <div className="loading-element loading-spinner--element-block" />
        </UserBox>
      )}
      {!isLoading && !data && (
        <UserBox id="offerCardModal" title={title || 'Invalid offer'} closeCallback={close}>
          <ErrorState />
        </UserBox>
      )}
      {data &&
        (modalType === RewardOfferDetailsModalType.Sticky ? (
          <RewardOfferInfoWithStickyActions
            title={title}
            termsAndConditions={termsAndConditions}
            content={content}
            heading={heading}
            imageUrl={imageUrl}
            showCTAButton={showCTAButton}
            cta={cta}
            ctaText={ctaText}
            onClaimOffer={handleClaimOfferClick}
            close={close}
          />
        ) : (
          <UserBox id="offerCardModal" title={title || 'Invalid offer'} closeCallback={close}>
            <RewardOfferInfo
              title={title}
              termsAndConditions={termsAndConditions}
              content={content}
              heading={heading}
              imageUrl={imageUrl}
              showCTAButton={showCTAButton}
              cta={cta}
              ctaText={ctaText}
              onClaimOfferClick={handleClaimOfferClick}
            />
          </UserBox>
        ))}
    </>
  );
};

export default makeModal(
  'rewardOffer',
  { name: 'modal-fade', timeout: 200 },
  { className: 'modal rewardOffer' }
)(RewardOfferDetails);
