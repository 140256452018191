import { useCallback } from 'react';
import { useAppDispatch } from 'hooks/redux';
import Modals from 'modules/Modals';
import Analytics from 'modules/Analytics';
import type { RewardOffer } from 'modules/User';
import { EffectTypes } from 'services/Effects';
import { RewardOfferDetailsModalType } from 'components/RewardOfferDetails';
import { OfferClickedInteractionType } from 'services/Effects/src/effectMap';

type useDailyFreeGamesReturnType = {
  openRewardTCModal: (content: string) => void;
  openPlayGuide: (rewardOfferData: RewardOffer) => void;
  openHowToUnlockModal: (rewardOfferData: RewardOffer) => void;
};

const useDailyFreeGames = (): useDailyFreeGamesReturnType => {
  const dispatch = useAppDispatch();

  const openRewardTCModal = useCallback(
    (content) => dispatch(Modals.actions.open('rewardTermsConditions', { content })),
    [dispatch]
  );

  const offerClicked = useCallback(
    ({
      interactionType,
      refName,
      type
    }: {
      interactionType: EffectTypes.OfferClickedInteractionType;
      refName: string;
      type: string;
    }) => {
      dispatch(
        Analytics.actions.offerClicked({
          refName,
          type,
          source: EffectTypes.OfferClickedSourceType.Rewards,
          interactionType
        })
      );
    },
    [dispatch]
  );

  const openPlayGuide = useCallback(
    (rewardOfferData: RewardOffer) => {
      offerClicked({
        interactionType: OfferClickedInteractionType.SecondaryCTAClick,
        refName: rewardOfferData.ref,
        type: rewardOfferData.type
      });

      dispatch(
        Modals.actions.open('rewardOffer', {
          rewardOfferData,
          modalParams: {
            source: EffectTypes.OfferClickedSourceType.Rewards,
            ref: rewardOfferData.ref
          }
        })
      );
    },
    [dispatch, offerClicked]
  );

  const openHowToUnlockModal = useCallback(
    (rewardOfferData: RewardOffer) => {
      offerClicked({
        interactionType: OfferClickedInteractionType.CardClick,
        refName: rewardOfferData.ref,
        type: rewardOfferData.type
      });

      dispatch(
        Modals.actions.open('rewardOffer', {
          rewardOfferData,
          modalParams: {
            source: EffectTypes.OfferClickedSourceType.Rewards,
            ref: rewardOfferData.ref,
            modalType: RewardOfferDetailsModalType.Sticky
          }
        })
      );
    },
    [dispatch, offerClicked]
  );

  return {
    openRewardTCModal,
    openPlayGuide,
    openHowToUnlockModal
  };
};

export default useDailyFreeGames;
