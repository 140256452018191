import { actionTypes as userTypes } from 'modules/User';
import { actionTypes as authTypes } from 'modules/Auth';
import { STATUS_CODE } from 'services/Api';
import { disconnectAll } from 'services/Socket/src/actions';

export function authMiddleware() {
  return ({ getState, dispatch }) =>
    (next) =>
    (action) => {
      const reset = () => setTimeout(() => dispatch({ type: 'Root/RESET' }), 0);

      if (action.type === userTypes.AT.GET_CURRENT.FULFILLED) {
        const state = getState();
        const hasAuthSideEffects =
          state.Auth.authenticated === false && !state.Auth.loggedThisWindow;
        if (hasAuthSideEffects) {
          // Api.cancelPending();
          dispatch(disconnectAll);
          reset();
          return next({
            ...action,
            type: action.type.replace('FULFILLED', 'REJECTED')
          });
        }
      } else if (
        action.type === authTypes.AT.LOGOUT.FULFILLED ||
        action.type === authTypes.AT.LOGOUT.REJECTED
      ) {
        const state = getState();
        if (state.Auth.loggedThisWindow) {
          reset();
        }
      } else if (
        action.type === userTypes.AT.GET_CURRENT.REJECTED ||
        action.type === authTypes.AT.CHECK_AUTH.REJECTED
      ) {
        reset();
      } else if (action.payload && action.payload.status === STATUS_CODE.UNAUTHORIZED) {
        reset();
      }
      return next(action);
    };
}
