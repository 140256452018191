import React, { memo } from 'react';
import { useAppSelector } from 'hooks/redux';
import { formatDate } from 'lib/formatters';
import Wallet from 'modules/Wallet';
import ExceededSpendLimits from './ExceededSpendLimits';

interface ExceededSpendLimitsProps {
  onConfirm: () => void;
}

const ExceededSpendLimitsAdapter = ({ onConfirm }: ExceededSpendLimitsProps) => {
  const nextAllowedDepositDate = useAppSelector(Wallet.selectors.getNextAllowedDepositDate);
  return (
    <ExceededSpendLimits
      nextAllowedDepositDate={formatDate(nextAllowedDepositDate, 'dd/MM/yyyy')}
      onConfirm={onConfirm}
    />
  );
};

export default memo(ExceededSpendLimitsAdapter);
