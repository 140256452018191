import { actionTypeCreator, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REGISTRATION: ASYNC,
  REFER_A_FRIEND: ASYNC,
  REWARDS_CAROUSEL: ASYNC,
  GET_OFFER: ASYNC
});
