import React, { useMemo, useCallback } from 'react';
import amplitude from 'lib/analytics';
import { replaceItemsInString } from 'lib/formatters/replaceItemsInString';
import { Typography, TypographyVariant } from 'components/Typography';
import CopyToClipboard from 'components/CopyToClipboard';
import { CopyIcon, CheckmarkIcon } from 'components/IconsLegacy';
import Button from 'components/Button';
import { inviteText } from './data';
import './inviteActionCard.css';

const canNativeShare = !!navigator.share;

interface ShareErrorProps {
  name: string;
  message: string;
}

interface InviteActionCardProps {
  username: string;
}

const InviteActionCard = ({ username }: InviteActionCardProps) => {
  const inviteTextFormatted = useMemo(
    () => replaceItemsInString(inviteText, { username }),
    [username]
  );

  const onShareHandler = useCallback(async () => {
    try {
      await navigator.share({ text: inviteTextFormatted });
      amplitude.track('Share option clicked');
    } catch (error: unknown) {
      const { name: errorName, message: errorMessage }: ShareErrorProps = error as ShareErrorProps;
      // eslint-disable-next-line no-console -- log share was cancelled errors
      console.log(`${errorName} - ${errorMessage}`);
    }
  }, [inviteTextFormatted]);

  return (
    <div className="inviteActionCard">
      <CopyToClipboard
        name="referFriend"
        className="inviteActionCard__inviteCopyContainer"
        text={inviteTextFormatted}
        notification="Copied to clipboard."
        suffix={
          !canNativeShare ? (
            <Button name="referFriendCopy" className="button__smaller inviteActionCard__copyButton">
              <CopyIcon className="inviteActionCard__icon" width="1em" height="1em" />
              Copy
            </Button>
          ) : null
        }
        suffixCopied={
          !canNativeShare ? (
            <Button name="referFriendCopy" className="button__smaller inviteActionCard__copyButton">
              <CheckmarkIcon className="inviteActionCard__icon" width="1em" height="1em" />
              Copied
            </Button>
          ) : null
        }
      >
        <div className="inviteActionCard__inviteLinkDetails">
          <Typography
            variant={TypographyVariant.BodySmStrong}
            tabletVariant={TypographyVariant.BodyMdStrong}
          >
            <span>Invite friends</span>
          </Typography>
          <Typography variant={TypographyVariant.BodyXs} tabletVariant={TypographyVariant.BodySm}>
            <span className="inviteActionCard__inviteLinkText">
              mrq.com/invited/
              <Typography
                variant={TypographyVariant.BodyXsStrong}
                tabletVariant={TypographyVariant.BodySmStrong}
              >
                <span>{username}</span>
              </Typography>
            </span>
          </Typography>
        </div>
      </CopyToClipboard>
      {canNativeShare && (
        <Button
          name="referFriendShare"
          onClick={onShareHandler}
          className="button__smaller inviteActionCard__shareButton"
        >
          <svg className="inviteActionCard__icon">
            <use href="/assets/images1/referAFriend/share-icon.svg#icon" />
          </svg>
          Share
        </Button>
      )}
    </div>
  );
};

export default InviteActionCard;
