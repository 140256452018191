import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import useDownloadHtml from 'hooks/useDownloadHtml';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import { DynamicModalAction } from './constants';
import usePublicCmsDataDynamicModal from './usePublicCmsDataDynamicModal';

const UserBox = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "UserBox" */ 'components/UserBox'),
  {
    fallback: <LoadingElement />
  }
);
const Button = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "Button" */ 'components/Button'),
  {
    fallback: <LoadingElement />
  }
);
const LegalDocArticle = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LegalDocArticle" */ './src/LegalDocArticle'
    ),
  {
    fallback: <LoadingElement />
  }
);

const mapping = {
  'modal.legal-content': LegalDocArticle
};

interface DynamicModalProps {
  id: string;
  slug: string;
  close: () => void;
  onBack?: () => void;
  actions?: Array<DynamicModalAction>;
  isPublic: boolean;
}

const DynamicModal = ({ id, slug, close, onBack, actions, isPublic }: DynamicModalProps) => {
  const { ref, handleDownload } = useDownloadHtml('MrQ customer data and privacy policy.html');
  const { content: data, isLoading, hasError } = usePublicCmsDataDynamicModal(slug);

  const actionComponentMapping = useMemo(
    () => ({
      [DynamicModalAction.CLOSE]: (
        <Button
          className="submit"
          onClick={close}
          buttonText="Close"
          title="Close"
          name="close"
          id="privacyPolicy-close"
        />
      ),
      [DynamicModalAction.DOWNLOAD]: (
        <Button
          onClick={handleDownload}
          name="download"
          id="privacyPolicy-download"
          buttonText="Download&nbsp;&nbsp;&nbsp;⇩"
          title="Download the entire document"
          className="submit terms__download"
        />
      )
    }),
    [close, handleDownload]
  );

  return (
    <UserBox id={id} title={data?.title} closeCallback={close} onBack={onBack}>
      {isLoading && <LoadingElement />}
      {hasError && <FormError errorMessage="Whoops, something went wrong..." />}
      {data && (
        <>
          <article ref={ref}>
            {data?.components.map((component) => {
              const Component = mapping[component.__component];
              return <Component key={component.id} isPublic={isPublic} {...component} />;
            })}
          </article>

          {actions && actions.length > 0 && (
            <div className="fieldset fieldset--centerflex">
              {actions.map((action) => actionComponentMapping[action])}
            </div>
          )}
        </>
      )}
    </UserBox>
  );
};

export { DynamicModalAction };
export default DynamicModal;
