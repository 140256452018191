import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import type { FluxStandardAction } from 'redux-promise-middleware';
import module from 'lib/module';
import { NAME } from './constants';
import { AT } from './actionTypes';
import { initialState } from './model';
import type { OffersState } from './types';

const getRegistrationOffers = (state: OffersState, { payload }: FluxStandardAction) =>
  update(state, {
    registration: { $set: payload }
  });

const getReferAFriendOffers = (state: OffersState, { payload }: FluxStandardAction) =>
  update(state, {
    referAFriend: { $set: payload }
  });

const getRewardsCarousel = (state: OffersState, { payload }: FluxStandardAction) =>
  update(state, {
    rewardsCarousel: { $set: payload }
  });

const getOffer = (state: OffersState, { payload }: FluxStandardAction) =>
  update(state, {
    offers: { $merge: { [payload.ref]: payload } }
  });

const reducer = module(
  handleActions(
    {
      [AT.REGISTRATION.FULFILLED]: getRegistrationOffers,
      [AT.REFER_A_FRIEND.FULFILLED]: getReferAFriendOffers,
      [AT.REWARDS_CAROUSEL.FULFILLED]: getRewardsCarousel,
      [AT.GET_OFFER.FULFILLED]: getOffer
    },
    initialState
  ),
  NAME
);

export default reducer;
