import React, { useCallback, useEffect, useState, memo, useRef } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Media from 'components/Media';
import { makeEdgePanel } from 'components/Modals/edgePanel/makeEdgePanel';
import Lobby from 'modules/Lobby';
import Carousel from 'modules/Carousel';
import User from 'modules/User';
import { PANEL_CORNER } from 'lib/edgePanel';
import { isLoading } from 'lib/redux-utils';
import type { SearchOverlayProps } from './SearchOverlay';
import SearchOverlay from './SearchOverlay';

const PAGE_SIZE = 30;
const DEBOUNCE_TIME = 450;

const SearchOverlayMobileWrapper = makeEdgePanel({
  name: 'searchOverlay',
  className: 'searchOverlay',
  swipeable: true,
  panelCorner: PANEL_CORNER.BOTTOM_LEFT
});

const SearchOverlayDesktopWrapper = makeEdgePanel({
  name: 'searchOverlay',
  className: 'searchOverlay',
  swipeable: true,
  panelCorner: PANEL_CORNER.LEFT_BOTTOM
});

export interface SearchOverlayAdapterProps {
  close: () => void;
  pathname: string;
}

const SearchOverlayAdapter = (props: SearchOverlayAdapterProps) => {
  const dispatch = useAppDispatch();
  const { close, pathname } = props;
  const latestSearch = useAppSelector(Lobby.selectors.getQuery, () => true);
  const [query, setQuery] = useState(latestSearch || '');
  const debouncedQuery = useDebounce(query, DEBOUNCE_TIME);

  // section could be set on BottomTabBar at openSearchOverlay({ section: Carousel.constants.TAB.SLOTS }).
  // however that would require options drilling
  // and more than that when SearchOverlay (which is a blocking modal) is launched automatically from URL.
  // we can't specify section, so we rely on pathname to determine the section.
  const section = pathname.includes(`lobby/${Carousel.constants.TAB.SLOTS}`)
    ? Carousel.constants.TAB.SLOTS
    : Carousel.constants.TAB.LIVE;

  const searchResult = useAppSelector(Lobby.selectors.getSearchResult);
  const searchPopularResult = useAppSelector(Lobby.selectors.getSearchPopularResult);
  const isKycPassed = useAppSelector(User.selectors.getKycStatus) === User.constants.kyc.PASS;

  const loadingSearch = useAppSelector((state) => isLoading(state, Lobby.actionTypes.AT.SEARCH._));
  const loadingSearchPopular = useAppSelector((state) =>
    isLoading(state, Lobby.actionTypes.AT.SEARCH_POPULAR._)
  );
  const loading = loadingSearch || loadingSearchPopular;

  const popularItems = searchPopularResult[section]?.items.contents;
  const resultItems = searchResult?.searchResult?.items?.contents;
  const defaultItems = searchResult?.defaultResult?.items?.contents;
  const tiles = resultItems || defaultItems || popularItems || [];

  const sectionName = resultItems
    ? ''
    : defaultItems
    ? searchResult.defaultResult.info.name
    : popularItems
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know it is there since we have popularItems
      searchPopularResult[section]!.info.name
    : '';
  const info = defaultItems ? searchResult?.defaultResult?.info.info : '';
  const description = defaultItems ? searchResult?.defaultResult?.info.description : '';

  useEffect(() => {
    if (popularItems) return;
    const args = {
      section,
      offset: 0,
      limit: PAGE_SIZE
    };
    void Lobby.actions.searchPopular(args)(dispatch);
  }, [section, dispatch, popularItems]);

  useEffect(() => {
    if (!query) {
      dispatch(Lobby.actions.clearSearchResult());
      return;
    }
    if (query !== debouncedQuery) return; // minding the delay

    dispatch(Lobby.actions.updateQuery(debouncedQuery));

    const args = {
      section,
      query: debouncedQuery,
      offset: 0,
      limit: PAGE_SIZE
    };
    void Lobby.actions.search(args)(dispatch);
  }, [section, query, debouncedQuery, dispatch]);

  // Clearing search result when closing by swiping out (in particular)
  useEffect(
    () => () => {
      dispatch(Lobby.actions.clearSearchResult());
    },
    [dispatch]
  );

  // The following is a "quick and dirty" approach until we figure out
  // a different way to track the searchRef at BE (maybe by involving a new endpoint)
  // and from withing SearchOverlay (not from withing SlotFrame as we do it now).
  // We're also clearing SearchResult on unmount for the swiping out case in particular,
  // even it's too late to clear at that moment (closing happens just when animation is finished).
  // So, for the swiping out case, if the user opens a game very quickly after swiping out,
  // there's still a chance that the searchRef will be sent to BE (due to not closing quickly enough so that searchRef is cleared in time).
  // For the scenario when a user double clicks outside SearchOverlay and launch a game,
  // we clear the searchRef instantly with the help of the following event listener.
  const clearSearchResult = useCallback(
    (evt) => {
      if (!evt.target.closest('.searchOverlay')) {
        dispatch(Lobby.actions.clearSearchResult());
        // NOT clearing the query, so that it is still there when the user opens the search again,
        // triggering a new search and returning the same result
        // thus getting back the searchResult part of the state that we lost when we cleared it.
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener('pointerdown', clearSearchResult);
    return () => {
      document.removeEventListener('pointerdown', clearSearchResult);
    };
  }, [clearSearchResult]);

  const handleSearchChange = useCallback<SearchOverlayProps['onSearchChange']>((_, __, value) => {
    setQuery(value);
  }, []);

  const fields = useRef({
    search: { initial: query }
  });

  return (
    <SearchOverlay
      tiles={tiles}
      onSearchChange={handleSearchChange}
      fields={fields.current}
      sectionName={sectionName}
      info={info}
      description={description}
      loading={loading}
      isKycPassed={isKycPassed}
      close={close}
    />
  );
};

/*
We don't want a re-render, especially when the user is scrolling.
Any prop that is seen as new other than "close" we are not interested in it.
Now, currently there is a "match" prop that keeps coming in (regularly, even when idle) from Modal.
That causes a hick up while scrolling.
So we memoize the component and only re-render when "close" prop changes.
*/
const SearchOverlayAdapterMemoized = memo(
  SearchOverlayAdapter,
  (prevProps, nextProps) => prevProps.close === nextProps.close
);

const SearchOverlayModal = (props: any) => (
  <Media query="(min-width: 500px)">
    {(matches) =>
      matches ? (
        <SearchOverlayDesktopWrapper template={<SearchOverlayAdapterMemoized {...props} />} />
      ) : (
        <SearchOverlayMobileWrapper template={<SearchOverlayAdapterMemoized {...props} />} />
      )
    }
  </Media>
);
export default SearchOverlayModal;
