const getPhoneNumberErrorMessage = () => {
  if (__ENV__.MRQ_COUNTRY === 'UK') {
    return 'Please enter a valid UK phone number';
  }
  if (__ENV__.MRQ_COUNTRY === 'MT') {
    return 'Please enter a valid Maltese phone number';
  }
  if (__ENV__.MRQ_COUNTRY === 'IE') {
    return 'Please enter a valid Irish phone number';
  }
};

const getPostcodeErrorMessage = () => {
  if (__ENV__.MRQ_COUNTRY === 'UK') {
    return 'Please enter a valid UK postcode';
  }
  if (__ENV__.MRQ_COUNTRY === 'MT') {
    return 'Please enter a valid Maltese postcode';
  }
  if (__ENV__.MRQ_COUNTRY === 'IE') {
    return 'Please enter a valid Irish postcode';
  }
};

const getCountry = () => {
  if (__ENV__.MRQ_COUNTRY === 'UK') {
    return 'United Kingdom';
  }
  if (__ENV__.MRQ_COUNTRY === 'MT') {
    return 'Malta';
  }
  if (__ENV__.MRQ_COUNTRY === 'IE') {
    return 'Ireland';
  }
};

export const passwordErrorMessage = 'At least 8 characters';

export const phoneNumberErrorMessage = getPhoneNumberErrorMessage();
export const postcodeErrorMessage = getPostcodeErrorMessage();
export const country = getCountry();
