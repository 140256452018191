import React, { useCallback, useState, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useAppSelector } from 'hooks/redux';
import User from 'modules/User';
import amplitude, { experiments, getExperiment } from 'lib/analytics';
import { replaceItemsInString } from 'lib/formatters/replaceItemsInString';
import Dialog from 'components/Dialog';
import { DialogHeader } from 'components/Dialog/Header';
import { Typography, TypographyVariant } from 'components/Typography';
import Heading from 'components/Heading';
import Button from 'components/Button';
import { CopyIcon, CheckmarkIcon } from 'components/IconsLegacy';
import CopyToClipboard from 'components/CopyToClipboard';
import InitialScreen from '../InitialScreen';
import InviteScreen from '../InviteScreen';
import { contactFieldTypeToScreen, screens, screenToContactFieldType } from '../../constants';
import type { Screen, ContactFieldType } from '../../constants';
import { inviteText } from '../../data';
import './referAFriendModalContent.css';

interface ReferAFriendModalContentProps {
  screen: Screen;
  username: string;
  freeRounds: number;
  freeRoundsGameName: string;
  isRafShareOptionsEnabled?: boolean;
  onClose: () => void;
  onInviteTypeButtonClicked: (contactFieldType: ContactFieldType) => void;
  onInviteSuccess?: () => void;
}

interface ShareErrorProps {
  name: string;
  message: string;
}

export const ReferAFriendModalContent = ({
  screen,
  username,
  freeRounds,
  freeRoundsGameName,
  isRafShareOptionsEnabled,
  onClose,
  onInviteTypeButtonClicked,
  onInviteSuccess
}: ReferAFriendModalContentProps) => {
  const inviteTextFormatted = useMemo(
    () =>
      replaceItemsInString(inviteText, {
        freeRounds: freeRounds.toString(),
        freeRoundsGameName,
        username
      }),
    [freeRounds, freeRoundsGameName, username]
  );

  const onShareHandler = useCallback(async () => {
    try {
      await navigator.share({ text: inviteTextFormatted });
      amplitude.track('Share option clicked');
    } catch (error: unknown) {
      const { name: errorName, message: errorMessage }: ShareErrorProps = error as ShareErrorProps;
      // eslint-disable-next-line no-console -- log share was cancelled errors
      console.log(`${errorName} - ${errorMessage}`);
    }
  }, [inviteTextFormatted]);

  return (
    <Dialog name="referAFriendBox">
      <DialogHeader icon="/assets/images1/dialogIcon.svg" onClose={onClose}>
        {isRafShareOptionsEnabled ? 'Invite Friends to MrQ' : 'Refer A Friend'}
      </DialogHeader>
      {isRafShareOptionsEnabled ? (
        <div className="referAFriendContent">
          <div className="referAFriendContent__content">
            <Typography variant={TypographyVariant.HeaderXs}>
              <Heading level={2} className="referAFriendContent__heading">
                Invite a friend, and you both get <em>{freeRounds} Free Spins!</em>
              </Heading>
            </Typography>
            <Typography variant={TypographyVariant.BodySm}>
              <div className="referAFriendContent__description">
                Just copy the unique link below and send it to your friends to use when they sign
                up. You can also let us text or email your friends.
              </div>
            </Typography>
            <CopyToClipboard
              name="referFriend"
              className="referAFriendContent__inviteCopyContainer"
              text={inviteTextFormatted}
              notification="Copied to clipboard."
              suffix={
                <Button name="referFriendCopy" className="referAFriendContent__inviteCopyButton">
                  <CopyIcon
                    className="referAFriendContent__inviteCopyIcon"
                    width="1em"
                    height="1em"
                  />
                  Copy link
                </Button>
              }
              suffixCopied={
                <Button name="referFriendCopy" className="referAFriendContent__inviteCopyButton">
                  <CheckmarkIcon
                    className="referAFriendContent__inviteCopyIcon"
                    width="1em"
                    height="1em"
                  />
                  Copied
                </Button>
              }
            >
              <div className="referAFriendContent__inviteLinkTextContainer">
                <Typography variant={TypographyVariant.BodySm}>
                  <span className="referAFriendContent__inviteLinkText">
                    mrq.com/invited/
                    <Typography variant={TypographyVariant.BodySmStrong}>
                      <span>{username}</span>
                    </Typography>
                  </span>
                </Typography>
              </div>
            </CopyToClipboard>
          </div>

          <div className="referAFriendContent__actionGroup">
            <Button
              tag="Link"
              href="/promotions/refer-a-friend#terms-and-conditions"
              target="_blank"
              className="button--quaternary referAFriendContent__tAndCButton"
            >
              <Typography variant={TypographyVariant.BodySm}>
                <span>T&Cs Apply</span>
              </Typography>
            </Button>
            {!!navigator.share && (
              <Button
                name="referFriendShare"
                onClick={onShareHandler}
                className="button--fakeLink referAFriendContent__shareButton"
              >
                <img
                  alt="Share"
                  src="/assets/images1/referAFriend/share.svg"
                  className="referAFriendContent__shareIcon"
                />
                Share
              </Button>
            )}
          </div>
        </div>
      ) : (
        <>
          {screen === screens.initial && (
            <InitialScreen onInviteTypeButtonClicked={onInviteTypeButtonClicked} />
          )}
          {(screen === screens.tel || screen === screens.email) && (
            <InviteScreen
              contactFieldType={screenToContactFieldType[screen]}
              onInviteSuccess={onInviteSuccess}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export const ReferAFriendModalContentAdapter = ({ close }: { close?: () => void }) => {
  const username = useAppSelector(User.selectors.getUsername);
  const freeRounds = useAppSelector(User.selectors.getFreeRounds);
  const freeRoundsGameName = useAppSelector(User.selectors.getFreeRoundsGameName);
  const [screen, setScreen] = useState(screens.initial);
  const isRafShareOptionsEnabled = Boolean(getExperiment(experiments.rafShareOptions.flag)?.key);

  useEffectOnce(() => {
    if (isRafShareOptionsEnabled && !navigator.share) {
      amplitude.track('Referral Rejected', {
        'Referral Type': 'share-link',
        'Error Type': 'Sharing not supported'
      });
    }
  });

  const handleOnClose = useCallback(() => {
    if (screen === screens.email || screen === screens.tel) {
      setScreen(screens.initial);
    } else if (close) {
      close();
    }
  }, [screen, setScreen, close]);

  const handleInviteTypeButtonClicked = useCallback(
    (contactFieldType: ContactFieldType) => {
      const nextScreen: Screen | null = contactFieldTypeToScreen[contactFieldType];
      if (nextScreen) {
        setScreen(nextScreen);
      }
    },
    [setScreen]
  );

  return freeRounds ? (
    <ReferAFriendModalContent
      screen={screen}
      username={username}
      freeRounds={freeRounds}
      freeRoundsGameName={freeRoundsGameName}
      onClose={handleOnClose}
      onInviteTypeButtonClicked={handleInviteTypeButtonClicked}
      onInviteSuccess={close}
      isRafShareOptionsEnabled={isRafShareOptionsEnabled}
    />
  ) : null;
};

export default ReferAFriendModalContentAdapter;
