import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEqual, difference } from 'lodash';
import loadable from '@loadable/component';
import DepositOfferPromoBanner from 'components/DepositOfferPromoBanner';
import LoadingElement from 'components/Loading/LoadingElement';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import requiresData from 'components/RequiresData';
import { MagicMove } from 'components/Transitions';
import { DepositTransferablePropsNames } from 'components/Deposit/types';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Wallet from 'modules/Wallet';
import TrueLayerForm from 'components/TrueLayerForm';
import type { AppDispatch, RootState } from '../../../store';
import FirstDepositSetup from './components/FirstDepositSetup';
import evaluateModalTitle from './helpers/evaluateModalTitle';
import AddMobile from './AddMobile';
import AddPaypal from './AddPaypal';
import AddEcospend from './AddEcospend';
import './payments.css';

const FRESHNESS = 4000;

const Braintree = loadable(() => import('components/Braintree'), {
  fallback: <LoadingElement />
});

const PciProxyForm = loadable(() => import('components/PciProxyForm'), {
  fallback: <LoadingElement />
});

interface AddPaymentReduxProps {
  openDeposit: (extraProps: any) => void;
  init: () => void;
  close: () => void;
}

interface AddPaymentProps extends AddPaymentReduxProps {
  init: () => void;
  close: () => void;
  openDeposit: (extraProps: any) => void;
  hasAgreedPaymentTerms: boolean;
  hasMobile: boolean;
  hasPaypal: boolean;
  method: string;
  hasLoaded: boolean;
  isPromoCodeDisabled: boolean;
  isFirstDeposit: boolean;
  paymentMethodRefs: string[];
}

interface AddPaymentState {
  selected: string;
}

export class _AddPayment extends Component<AddPaymentProps, AddPaymentState> {
  constructor(props: AddPaymentProps) {
    super(props);
    const { hasMobile, method, hasPaypal } = props;

    let selected;
    if (method === 'braintree') {
      selected = 'braintree';
    } else if (method === 'pci-proxy') {
      selected = 'pci-proxy';
    } else if (!hasMobile && method === 'mobile') {
      selected = 'mobile';
    } else if (!hasPaypal && method === 'paypal') {
      selected = 'paypal';
    } else if (method === 'ecospend') {
      selected = 'ecospend';
    } else if (method === 'truelayer') {
      selected = 'truelayer';
    } else {
      selected = '';
    }

    this.state = {
      selected
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentDidUpdate(prevProps: Readonly<AddPaymentProps>) {
    // This will need to be refactored in order to make it as performant
    // as it was previously with the UNSAFE_componentWillReceiveProps.
    // However, until we prioritize that, this will do.
    // 1 more re-render than necessary is not a big deal.
    // See the commit history for the previous implementation.
    if (
      prevProps.paymentMethodRefs !== this.props.paymentMethodRefs &&
      !isEqual(prevProps.paymentMethodRefs, this.props.paymentMethodRefs)
    ) {
      const newMethodRef = difference(this.props.paymentMethodRefs, prevProps.paymentMethodRefs)[0];
      this.props.openDeposit({ [DepositTransferablePropsNames.initialMethodRef]: newMethodRef });
      this.props.close();
    }

    if (prevProps.method !== this.props.method) {
      this.setState({ selected: this.props.method });
    }
  }

  handleClick = (e: any) => {
    e.preventDefault();
    this.setState({ selected: e.currentTarget && e.currentTarget.value });
  };

  render() {
    const { selected } = this.state;
    const { hasAgreedPaymentTerms, hasLoaded, isFirstDeposit, isPromoCodeDisabled } = this.props;
    const hasSelectedPaymentMethod = [
      'braintree',
      'mobile',
      'paypal',
      'ecospend',
      'truelayer',
      'pci-proxy'
    ].includes(selected);
    const isFirstDepositFlow = !hasSelectedPaymentMethod;
    return (
      <UserBox
        id="addPaymentBox"
        title={evaluateModalTitle(hasAgreedPaymentTerms, selected)}
        closeCallback={this.props.close}
      >
        <DepositOfferPromoBanner />
        <MagicMove>
          {isFirstDepositFlow ? (
            <FirstDepositSetup hasLoaded={hasLoaded} handleClick={this.handleClick} />
          ) : selected === 'braintree' ? (
            <Braintree show ownSubmit key="braintree" />
          ) : selected === 'pci-proxy' ? (
            <PciProxyForm key="pci-proxy" onDone={this.props.close} />
          ) : selected === 'mobile' ? (
            <AddMobile key="addMobile" onDone={this.props.close} />
          ) : selected === 'ecospend' ? (
            <AddEcospend
              key="ecospend"
              onDone={this.props.close}
              // TODO: MQPV-638 refactor source of these props, they should be inside
              isPromoCodeDisabled={isPromoCodeDisabled}
              isDepositWelcomeOfferVisible={isFirstDeposit}
            />
          ) : selected === 'truelayer' ? (
            <TrueLayerForm />
          ) : (
            <AddPaypal key="addPaypal" onDone={this.props.close} />
          )}
        </MagicMove>
      </UserBox>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isPromoCodeDisabled: User.selectors.getIsPromoCodeDisabled(state),
  isFirstDeposit: !Wallet.selectors.getHasDeposited(state),
  hasAgreedPaymentTerms: !Wallet.selectors.hasAgreedPaymentTerms(state),
  hasMobile: Wallet.selectors.hasMobile(state),
  hasPaypal: Wallet.selectors.hasPaypal(state),
  paymentMethodRefs: Wallet.selectors.getPaymentMethodRefs(state)
});

const mapDispatchToProps = (dispatch: AppDispatch, { close }: AddPaymentProps) => ({
  // For now, we don't support extraProps types
  openDeposit: (extraProps: any) => dispatch(Modals.actions.open('deposit', extraProps)),
  agreePaymentTerms: () => {
    void Api.actions.wallet.agreePaymentTerms()(dispatch);
  },
  init: () => {
    void Api.actions.wallet.listPaymentMethods()(dispatch);
    void Api.actions.wallet.agreedPaymentTerms()(dispatch);
    void Api.actions.wallet.suggestedDepositAmounts()(dispatch, true);
  },
  close: () => {
    void User.actions.getKYC()(dispatch);

    if (close) {
      close();
    }
  }
});

export default compose(
  makeModal('addPayment', { name: 'modal-fade', timeout: 200 }, { className: 'payment modal' }),
  connect(mapStateToProps, mapDispatchToProps),
  requiresData(Wallet.actionTypes.AT.AGREED_PAYMENT_TERMS._, FRESHNESS)
)(_AddPayment);
