import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { importScript, removeScript } from 'lib/importScript';
import Auth from 'modules/Auth';
import User from 'modules/User';
import Api from 'services/Api';
import { isLoading } from 'lib/redux-utils';
import amplitude from 'lib/analytics';
import type { GnattaWebChat, GnattaProfile, AuthenticationStatus } from './types';
import { AUTHENTICATION_STATUS, GNATTA_SDK } from './constants';
import './gnatta.css';

let gnatta: GnattaWebChat;

const loadScript = () => importScript(GNATTA_SDK, 'gnatta') as Promise<GnattaWebChat>;

/**
 * Opens the live chat if it is enabled and not already open
 */
export const openLiveChat = (location: string) => {
  if (
    gnatta?.chatInstancesDetail?.length > 0 &&
    !document.getElementById(gnatta.inlineChatFrameId)
  ) {
    const iframeSrc = gnatta.chatInstancesDetail.find((instance) => instance?.enabled)?.href;
    gnatta.launchInlineChat(iframeSrc, gnatta.inlineChatFrameId);
    amplitude.track('Live Chat Clicked', {
      'Live Chat Location': location
    });
  }
};

/**
 * Initiates the chat with the given profile, name and email.
 * If no profile is given, the chat will be initiated without a profile.
 * Calling gnatta.configure, gnatta.set and then gnatta.initiate must be done in this exact order
 * Here you can find some examples: https://ref.develop.gnatta.site
 * @param profile
 * @param firstName
 * @param email
 */
const initiateChat = (profile?: GnattaProfile, firstName?: string, email?: string) => {
  gnatta.configure(__ENV__.GNATTA_API_KEY, 'https://chat.system.gnatta.com');
  if (profile) {
    Object.keys(profile)?.forEach((key) => {
      gnatta.set(key, { value: profile[key] });
    });
    gnatta.setOriginator(firstName, email);
  }

  gnatta.initiate(__ENV__.GNATTA_CHAT_INSTANCE_ID, null, null, null);
};

const Gnatta = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [initiatedState, setInitiatedState] = useState<AuthenticationStatus | null>(null);

  const dispatch = useAppDispatch();
  const isLoadingProfile = useAppSelector((state) =>
    isLoading(state, [User.actionTypes.AT.GET_CURRENT._])
  );
  const isAuthenticated = useAppSelector(Auth.selectors.getIsAuthenticated);
  const firstName = useAppSelector(User.selectors.getFirstName);
  const email = useAppSelector(User.selectors.getEmail);
  const location = useLocation();

  useEffect(() => {
    const body = document.body;
    if (location.pathname.startsWith('/secure/lobby')) {
      body.classList.add('gnatta-inline-webchat--lobby');
    } else {
      body.classList.remove('gnatta-inline-webchat--lobby');
    }

    if (
      location.pathname.startsWith('/secure/games') ||
      location.pathname.startsWith('/secure/bingo')
    ) {
      body.classList.add('gnatta-inline-webchat--hidden');
    } else {
      body.classList.remove('gnatta-inline-webchat--hidden');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!scriptLoaded) {
      void loadScript().then((script) => {
        gnatta = script;
        setScriptLoaded(true);
      });
    }

    return () => {
      if (scriptLoaded) {
        removeScript(GNATTA_SDK);
      }
    };
  }, [scriptLoaded]);

  useEffect(() => {
    if (scriptLoaded && !isLoadingProfile) {
      if (isAuthenticated) {
        if (firstName && email && initiatedState !== AUTHENTICATION_STATUS.AUTHENTICATED) {
          void Api.actions.user
            .gnattaProfile()(dispatch)
            .then((res) => {
              // @ts-expect-error - res.data is not typed
              initiateChat(res?.data, firstName, email);
            })
            .then(() => {
              setInitiatedState(AUTHENTICATION_STATUS.AUTHENTICATED);
            })
            .catch((error) => {
              // eslint-disable-next-line no-console -- This is a catch block
              console.error('Error happened during Gnatta initialisation', error);
            });
        }
      } else if (!initiatedState) {
        initiateChat();
        setInitiatedState(AUTHENTICATION_STATUS.NOT_AUTHENTICATED);
      }
    }
  }, [dispatch, isLoadingProfile, isAuthenticated, email, firstName, scriptLoaded, initiatedState]);

  return null;
};

export default Gnatta;
