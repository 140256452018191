import React, { type MouseEvent } from 'react';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import SetLimits from 'components/AddPayment/components/SetLimits';
import PaymentMethodsProvidersList from './components/PaymentMethodsProvidersList';

interface FirstDepositSetupProps {
  hasLoaded: boolean;
  limitsDone: boolean;
  handleClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDoneDepositLimit: () => void;
}

const FirstDepositSetup = ({
  hasLoaded,
  limitsDone,
  onDoneDepositLimit,
  handleClick
}: FirstDepositSetupProps) => (
  <MagicMove childKey={hasLoaded ? 'LoadingElement' : 'firstDepositSetup'}>
    {!hasLoaded ? (
      <LoadingElement />
    ) : (
      <>
        <PaymentMethodsProvidersList key="chooser" onClickHandler={handleClick} />
        {!limitsDone && <SetLimits key="setLimits" onDoneDepositLimit={onDoneDepositLimit} />}
      </>
    )}
  </MagicMove>
);

export default FirstDepositSetup;
