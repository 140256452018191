import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import Offers from 'modules/Offers';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';

const MAX_ATTEMPTS = 2;

interface useSpinRewardsRetryProps {
  refName: string;
  shouldRetry: boolean;
}

const useSpinRewardsRetry = ({ refName, shouldRetry }: useSpinRewardsRetryProps) => {
  const dispatch = useAppDispatch();
  const [attemptCount, setAttemptCount] = useState(0);

  const trackRewardCarouselRetried = useCallback(
    (retryCount) => {
      dispatch(
        Analytics.actions.rewardCarouselRetried({
          refName,
          retryCount
        })
      );
    },
    [refName, dispatch]
  );

  const trackRewardCarouselRetryInSentry = useCallback((retryCount) => {
    Sentry.captureMessage('Retry attempt for Reward Carousel', {
      level: 'info',
      contexts: {
        retry: {
          count: retryCount,
          max: MAX_ATTEMPTS
        }
      }
    });
  }, []);

  const performRetry = useCallback(
    (count: number) => {
      trackRewardCarouselRetried(count);
      trackRewardCarouselRetryInSentry(count);
      void Offers.actions.rewardsCarousel({ requestRetryCount: count })(dispatch);
    },
    [dispatch, trackRewardCarouselRetried, trackRewardCarouselRetryInSentry]
  );

  useEffect(() => {
    if (!shouldRetry || attemptCount >= MAX_ATTEMPTS) {
      return;
    }

    const timeoutDuration = attemptCount === 0 ? 3000 : 10000;
    const timer = setTimeout(() => {
      const newAttemptCount = attemptCount + 1;
      setAttemptCount((prev) => prev + 1);
      performRetry(newAttemptCount);
    }, timeoutDuration);

    return () => clearTimeout(timer);
  }, [dispatch, shouldRetry, attemptCount, performRetry]);
};

export default useSpinRewardsRetry;
