import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { FormProvider } from 'react-hook-form';
import Modals from 'modules/Modals';
import BottomSheet from 'components/BottomSheet';
import ModalOverlay from 'components/Modals/ModalOverlay';
import { useAppDispatch } from 'hooks/redux';
import { useUpdateAccountMarketingPreferences } from 'components/Account/components/AccountMarketingPreferences';

const MarketingPreferencesModal = loadable(() => import('./MarketingPreferencesModal'));

export const MarketingPreferencesModalAdapter = () => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { methods, submitFormData } = useUpdateAccountMarketingPreferences();

  // set as true to hide modal for all players
  const hasConsentedToMarketingPreferences = true;

  useEffect(() => {
    if (!hasConsentedToMarketingPreferences) {
      dispatch(Modals.actions.open('verticalMarketingPreferences'));
    }

    return () => {
      dispatch(Modals.actions.close('verticalMarketingPreferences'));
    };
  }, [hasConsentedToMarketingPreferences, dispatch]);

  const onSubmitClick = () => {
    void (async () => {
      setIsSubmitting(true);
      try {
        await submitFormData();
        dispatch(Modals.actions.close('verticalMarketingPreferences'));
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
      }
    })();
  };

  return (
    <div>
      <ModalOverlay key={+new Date() + 100} />
      <BottomSheet name="verticalMarketingPreferences">
        <FormProvider {...methods}>
          <MarketingPreferencesModal isSubmitting={isSubmitting} onSubmitClick={onSubmitClick} />
        </FormProvider>
      </BottomSheet>
    </div>
  );
};
