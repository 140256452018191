import { useMemo } from 'react';
import { useAppSelector } from 'hooks/redux';
import Lobby from 'modules/Lobby';
import type { AccessRef, LiveGame, Normalized, Provider } from 'lib/LobbyEntities';

interface useLiveTileProps {
  game: AccessRef;
}

const useLiveTile = ({ game }: useLiveTileProps) => {
  const gameTile = useAppSelector((state) =>
    Lobby.selectors.getEntityByKey(state, game.__access)
  ) as Normalized<LiveGame> | null;
  const provider = useAppSelector((state) =>
    Lobby.selectors.getEntityByKey(state, gameTile?.provider?.__access)
  ) as Normalized<Provider> | null;

  const tile = useMemo(() => {
    if (!gameTile) return null;

    const { refName, title, imageURL, tableInfo, blur } = gameTile;
    const { minBet, playerCount, takenSeats, tileType, results, dealer, open } = tableInfo ?? {};
    return {
      refName,
      title,
      imageURL,
      blur,
      minBet,
      takenSeats,
      dealer,
      results,
      playerCount,
      tileType,
      open,
      providerLabel: provider?.label ?? ''
    };
  }, [gameTile, provider]);

  return useMemo(() => ({ tile, gameTile }), [tile, gameTile]);
};

export default useLiveTile;
