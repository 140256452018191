import React from 'react';
import cc from 'classcat';
import Lottie from 'lottie-react';
import animationData from './animationData.json';
import './confetti.css';

interface ConfettiProps {
  className?: string;
  autoplay?: boolean;
  loop?: boolean;
}

const Confetti = ({ className, autoplay = true, loop = false }: ConfettiProps) => (
  <div className={cc(['confetti', className])}>
    <Lottie
      className="confetti__lottie"
      animationData={animationData}
      autoplay={autoplay}
      loop={loop}
    />
  </div>
);

export default Confetti;
