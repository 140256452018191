import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import getType from 'lib/getType';
import * as Icons from 'components/IconsLegacy';
import RouterLink from 'components/RouterLink';

class Tab extends PureComponent {
  _onClick = (evt) => {
    const { handleClick, name, refName } = this.props;
    if (handleClick) handleClick(name, refName, evt);
  };

  render() {
    const { className, children, counter, icon, href, replace } = this.props;
    const Icon = Icons[icon];
    return (
      <RouterLink className={className} href={href} replace={replace} onClick={this._onClick}>
        {icon ? <Icon /> : null}
        <span className="tab-text">
          {getType(children) === 'String' ? children.replace(/\\u00A0/g, '\u00a0') : children}
        </span>
        {counter !== 0 ? (
          <span className="tab__counter">{counter > 9 ? '9+' : counter}</span>
        ) : null}
      </RouterLink>
    );
  }
}

Tab.propTypes = {
  className: PropTypes.string,
  refName: PropTypes.string.isRequired,
  href: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.node,
  counter: PropTypes.number,
  /** From react-router Link, replace in browser history instead of pushing new state (browser back-forward) */
  replace: PropTypes.bool,
  icon: PropTypes.string
};

Tab.defaultProps = {
  className: 'tab',
  children: null,
  href: undefined,
  handleClick: undefined,
  counter: 0,
  replace: false,
  icon: null
};

export default Tab;
