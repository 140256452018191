import React, { memo, useMemo, useRef } from 'react';
import type { LiveGame, TableInfo } from 'lib/LobbyEntities';
import { useTouchIsolation } from 'lib/useTouchIsolation';
import RouterLink from 'components/RouterLink';
import Tile, { VariantHover } from 'components/Tile';
import withLazyStyle from 'components/LazyStyle';
import { Typography, TypographyVariant } from 'components/Typography';
import Badge from 'components/Badge';
import { ProfileIcon } from 'components/IconsLegacy';
import { EmbeddedTileInfo } from 'components/LiveTile';
import LiveTileInfo from 'components/LiveTileInfo';
import style from './liveTile.css?lazy';

const slotTileSizes = {
  width: 260,
  height: 260
};

export type Tile = Omit<LiveGame, 'provider' | 'tableInfo'> & TableInfo & { providerLabel: string };

interface LiveTileProps {
  tile: Tile;
  imageLoadingType?: 'lazy' | 'eager';
  path: string;
  isolateTouch?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

// TODO: Using a version suffix V2 during the transitional period. After fully migrating,
//  remove the version suffix, so the new component becomes LiveTile, and the old one is deprecated.
const LiveTile = ({
  tile,
  path,
  onClick,
  imageLoadingType,
  isolateTouch = false
}: LiveTileProps) => {
  const containerRef = useRef(null);
  const {
    imageURL,
    blur,
    title,
    open,
    minBet,
    takenSeats,
    providerLabel,
    playerCount,
    dealer,
    tileType,
    results
  } = tile;
  const hasInfo = Boolean(dealer || minBet || takenSeats);
  const tileInfo = useMemo(
    () => ({
      tileType,
      results,
      takenSeats
    }),
    [tileType, results, takenSeats]
  );

  useTouchIsolation({ containerRef, isActive: isolateTouch });

  // open is a value returned by BE, it can be boolean
  // or null, so this adds context to the value
  const isGameDisabled = open === false;

  return (
    <RouterLink
      className="liveTileV2"
      href={isGameDisabled ? null : path}
      onClick={isGameDisabled ? null : onClick}
      innerRef={containerRef}
    >
      <section className="liveTileV2__image">
        {Boolean(playerCount) && (
          <div className="liveTileV2__totalPlayers">
            <Badge suffix={<ProfileIcon />}>{playerCount}</Badge>
          </div>
        )}
        <Tile
          imageUrl={imageURL}
          imageAlt={title}
          isBlurred={blur}
          isDisabled={isGameDisabled}
          resizeWidth={slotTileSizes.width}
          resizeHeight={slotTileSizes.height}
          variantHover={VariantHover.None}
          imageLoadingType={imageLoadingType}
        />
        <EmbeddedTileInfo tileInfo={tileInfo} />
      </section>
      <section className="liveTileV2__content">
        <div className="liveTileV2__baseInfo">
          <Typography variant={TypographyVariant.BodyXsStrong}>
            <span className="liveTileV2__title">{title}</span>
          </Typography>
          <Typography variant={TypographyVariant.BodyXs}>
            <span className="liveTileV2__provider">{providerLabel}</span>
          </Typography>
        </div>
        {hasInfo && <LiveTileInfo minBet={minBet} takenSeats={takenSeats} dealerName={dealer} />}
      </section>
    </RouterLink>
  );
};

export default memo(withLazyStyle(style)(LiveTile));
