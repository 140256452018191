import React, { useEffect, useState } from 'react';
import Wallet from 'modules/Wallet';
import { useAppSelector } from 'hooks/redux';
import usePrevious from 'hooks/usePrevious';
import FirstDepositSetup from './FirstDepositSetup';

interface FirstDepositSetupAdapterProps {
  hasLoaded: boolean;
  handleClick: (e: any) => void;
}

const FirstDepositSetupAdapter = ({ hasLoaded, handleClick }: FirstDepositSetupAdapterProps) => {
  const [limitsDone, setLimitsDone] = useState(false);
  const hasAgreedPaymentTerms = useAppSelector(Wallet.selectors.hasAgreedPaymentTerms);
  const prevHasAgreed = usePrevious(hasAgreedPaymentTerms);

  useEffect(() => {
    if (hasAgreedPaymentTerms !== prevHasAgreed && hasAgreedPaymentTerms) {
      setLimitsDone(true);
    }
  }, [setLimitsDone, hasAgreedPaymentTerms, prevHasAgreed]);

  const handleDoneDepositLimit = () => setLimitsDone(true);

  return (
    <FirstDepositSetup
      hasLoaded={hasLoaded}
      limitsDone={limitsDone}
      handleClick={handleClick}
      onDoneDepositLimit={handleDoneDepositLimit}
    />
  );
};

export default FirstDepositSetupAdapter;
