import React, { useEffect, memo, useCallback } from 'react';
import User from 'modules/User';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import { EffectTypes } from 'services/Effects';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import usePrevious from 'hooks/usePrevious';
import RewardsList from 'components/RewardsList';
import RewardOffersGridList from 'components/RewardOffersGridList';
import useRefreshRewards from './hooks/useRefreshRewards';
import './rewardsView.css';

const RewardsContainer = () => {
  const dispatch = useAppDispatch();
  const offers = useAppSelector(User.selectors.getRewardOffers);
  const rewardsCount = useAppSelector(User.selectors.getRewardsCount);
  useRefreshRewards();

  const openOfferModal = useCallback(
    (rewardOfferData) =>
      dispatch(
        Modals.actions.open('rewardOffer', {
          rewardOfferData,
          modalParams: {
            source: EffectTypes.OfferClickedSourceType.Rewards,
            ref: rewardOfferData.ref
          }
        })
      ),
    [dispatch]
  );

  const prevRewardCount = usePrevious(rewardsCount);
  useEffect(() => {
    if (rewardsCount !== prevRewardCount) {
      Api.actions.user.getRewards()(dispatch);
      Api.actions.offers.rewardsCarousel({ requestRetryCount: null })(dispatch);
    }
  }, [rewardsCount, prevRewardCount, dispatch]);

  useEffect(() => {
    Api.actions.user.getRewards()(dispatch);
    Api.actions.user.getRewardOffers()(dispatch);
    Api.actions.user.getDailyFreeGames()(dispatch);
  }, [dispatch]);
  return (
    <div className="rewardsView">
      <div className="rewardsView__rewardCards">
        <h6>For you</h6>
        <RewardsList />
      </div>
      <div className="rewardsView__offers">
        <h6>Offers</h6>
        <RewardOffersGridList offers={offers} openOfferModal={openOfferModal} />
      </div>
    </div>
  );
};

export default memo(RewardsContainer);
