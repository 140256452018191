import React, { type ChangeEvent, useCallback } from 'react';
import cc from 'classcat';
import RadioGroup from 'components/RadioGroup';
import { formatCurrency } from 'lib/formatters';
import './suggestedAmounts.css';

interface SuggestedAmountsProps {
  value: number;
  values: number[];
  disabled: boolean;
  onChange: (value: number) => void;
}

const SuggestedAmounts = ({ value, values, disabled, onChange }: SuggestedAmountsProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(+event.target.value);
    },
    [onChange]
  );

  if (values.length === 0) {
    return null;
  }

  return (
    <div className={cc(['field field--form', disabled && 'noEdit'])}>
      <span className="field__title">Deposit amount</span>
      <div className="field__content">
        <div className="fieldset fieldset--fullwidth depositPreset">
          <RadioGroup
            uid="presets"
            className="suggestedAmounts"
            values={values}
            onChange={handleChange}
            value={value}
            valueFormatter={formatCurrency}
          />
        </div>
      </div>
    </div>
  );
};

export default SuggestedAmounts;
