import { createSelector } from 'reselect';
import type { RootState } from '../../../../store';
import { NAME } from './constants';
import * as m from './model';
import { VerificationDocumentStatus, type KycState } from './types';

type Selector<A extends undefined | keyof KycState = undefined> = (
  state: RootState
) => A extends keyof KycState ? KycState[A] : KycState;

export const model: Selector = (state) => (state[NAME] || m.initialState) as KycState;
export const getVerificationDocuments = (state: RootState) => model(state).verificationDocuments;

export const getRequiredVerificationDocuments = createSelector(
  [getVerificationDocuments],
  (documents) =>
    documents.filter(
      ({ status }) =>
        status === VerificationDocumentStatus.REQUESTED ||
        status === VerificationDocumentStatus.REJECTED
    )
);

export const getPendingVerificationDocuments = createSelector(
  [getVerificationDocuments],
  (documents) =>
    documents.filter(
      ({ status }) =>
        status === VerificationDocumentStatus.IN_PROGRESS ||
        status === VerificationDocumentStatus.RECEIVED
    )
);

export const getApprovedVerificationDocuments = createSelector(
  [getVerificationDocuments],
  (documents) => documents.filter(({ status }) => status === VerificationDocumentStatus.APPROVED)
);
