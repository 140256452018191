import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import type { FluxStandardAction } from 'redux-promise-middleware';
import module from 'lib/module';
import { NAME } from './constants';
import { AT } from './actionTypes';
import { initialState } from './model';
import type { KycState } from './types';

const setVerificationDocuments = (state: KycState, { payload }: FluxStandardAction) =>
  update(state, {
    verificationDocuments: { $set: payload }
  });

const reducer = module(
  handleActions(
    {
      [AT.VERIFICATION_DOCUMENTS.FULFILLED]: setVerificationDocuments
    },
    initialState
  ),
  NAME
);

export default reducer;
