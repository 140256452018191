import React from 'react';
import DynamicModal from 'components/DynamicModal';

interface GamifiedReferralsMoreInfoProps {
  onBack: () => void;
  close: () => void;
}

const GamifiedReferralsMoreInfo = ({ onBack, close }: GamifiedReferralsMoreInfoProps) => (
  <DynamicModal
    id="inviteProgressInfo"
    slug="invite-progress-info"
    isPublic
    close={close}
    onBack={onBack}
  />
);

export default GamifiedReferralsMoreInfo;
