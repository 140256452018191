import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import './errorState.css';

interface ErrorStateProps {
  message?: string;
}

const ErrorState = ({
  message = 'Something went wrong. Please try again later.'
}: ErrorStateProps) => (
  <div className="errorState">
    <div className="errorState__icon">
      <img src="/assets/images1/notification-3.svg" alt="An error occurred" />
    </div>
    <Typography variant={TypographyVariant.BodyLg}>
      <p className="errorState__message">{message}</p>
    </Typography>
  </div>
);

export default ErrorState;
