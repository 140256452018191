import React from 'react';
import { Checkbox } from 'components/Checkbox';
import { paymentMethodType } from 'lib/constants';
import './fundsProtectionCheck.css';

interface FundsProtectionCheckProps {
  selectedMethodType?: string;
  checked: boolean;
  openTermsConditions: () => void;
  onChange: () => void;
}

const FundsProtectionCheck = ({
  selectedMethodType,
  checked,
  onChange,
  openTermsConditions
}: FundsProtectionCheckProps) => (
  <div className="fieldset">
    <Checkbox
      onChange={onChange}
      checked={checked}
      className="fundsProtectionCheck"
      name="acceptFundProtectionTermsCheckbox"
      label="I accept the fund protection terms"
    />
    <div className="toc-text toc-text--notAccepted">
      {selectedMethodType === paymentMethodType.MOBILE && (
        <div>Payment will be charged to your mobile bill.</div>
      )}
      MrQ holds all player funds in a separate bank account in accordance with the Gambling
      Commission&apos;s basic level of protection. This means that in case of insolvency customer
      funds will not be protected.
    </div>
    <a className="toc-text toc-text--notAccepted underline" onClick={openTermsConditions}>
      Terms and conditions apply
    </a>
  </div>
);

export default FundsProtectionCheck;
