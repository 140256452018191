import React from 'react';
import cc from 'classcat';
import TileMediaCard from 'components/TileMediaCard';

interface InviteOfferCardSkeletonProps {
  className?: string;
}

const InviteOfferCardSkeleton = ({ className }: InviteOfferCardSkeletonProps) => (
  <TileMediaCard.Root className={cc(['inviteOfferCard', className])}>
    <TileMediaCard.Skeleton isDarkVariant />
  </TileMediaCard.Root>
);

export default InviteOfferCardSkeleton;
