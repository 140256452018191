import React, { memo } from 'react';
import { Launcher } from './src/components/Launcher';
import Gnatta, { openLiveChat as openGnattaChat } from './src/components/Gnatta';

const openLiveChat = (location: string) => {
  openGnattaChat(location);
};

export { openLiveChat };

const LiveChat = () => (
  <>
    <Gnatta />
    <Launcher openLiveChat={openLiveChat} />
  </>
);

export default memo(LiveChat);
