import React, { Component } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import FormError from 'components/FormError';
import Button from 'components/Button';
import LoadingElement from 'components/Loading/LoadingElement';
import { formatCurrency } from 'lib/formatters';
import { isLoading } from 'lib/redux-utils';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import { Typography, TypographyVariant } from 'components/Typography';
import {
  MIN_DEPOSIT,
  MAX_DEPOSIT_CARD,
  depositAmount as depositAmountValidation
} from 'lib/valFunctors';
import Wallet from 'modules/Wallet';
import { paymentMethodType } from 'lib/constants';
import DepositRange from 'components/DepositRange';
import SuggestedAmounts from 'components/SuggestedAmounts';
import EcospendSearchableBanks from './EcospendSearchableBanks';
import './ecospend.css';

const enhance = compose(reform());

class BaseForm extends Component {
  handleSuggestedAmountChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  handleBankChange = (value) => {
    const { handleChange, onAnotherMethod } = this.props;
    if (value?.anotherMethod) {
      onAnotherMethod();
      return;
    }
    handleChange({}, { name: 'bank', value: value });
  };

  render() {
    const {
      isPromoCodeDisabled,
      depositAmount,
      promotionCode,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      minAmount,
      maxAmount,
      errorMessage,
      onSubmit,
      openTermsConditions,
      providerUnavailableDescription,
      fundsProtectionCheck,
      disableDepositButton,
      isDepositWelcomeOfferVisible,
      bank,
      ecospendBanks
    } = this.props;
    const buttonClass = cc([{ 'button--loading': loading }]);
    const bankUnavailable = bank?.value && !bank.value?.available;
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || bankUnavailable || disableDepositButton;

    return (
      <div className="addEcospendFormContainer">
        <form
          id="depositCardForm"
          name="addEcospend"
          onSubmit={onSubmit}
          className={disabled ? 'form form--disabled' : 'form'}
        >
          <Typography variant={TypographyVariant.BodyMdStrong}>
            <div className="depositForm__label">Select Amount</div>
          </Typography>
          <SuggestedAmounts
            value={depositAmount.value}
            onChange={this.handleSuggestedAmountChange}
            paymentMethodType={paymentMethodType.BANK_ACCOUNT}
            disabled={disabled}
          />
          <Fieldset
            field={depositAmount}
            editable={!disabled}
            inputType="number"
            min={(minAmount || MIN_DEPOSIT).toString()}
            max={(maxAmount || MAX_DEPOSIT_CARD).toString()}
            step="1"
            name="depositAmount"
            className="fieldset--pound"
            labelText="Other"
            onChange={handleChange}
            onFocus={handleFocus}
          />
          <DepositRange paymentMethodType={paymentMethodType.BANK_ACCOUNT} />
          {!isPromoCodeDisabled && (
            <>
              <div className="depositForm__separator" />
              <Fieldset
                field={promotionCode}
                inputType="text"
                name="promotionCode"
                labelText="Promo code"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                editable={!disabled}
              />
              {isDepositWelcomeOfferVisible && promotionCode.value.length > 0 && (
                <Typography variant={TypographyVariant.BodySm}>
                  <span className="promoCode__offer">
                    If this code is valid, it will override the offer above.
                  </span>
                </Typography>
              )}
            </>
          )}
          <EcospendSearchableBanks
            bankOptions={Object.values(ecospendBanks)}
            onSelect={this.handleBankChange}
            onClear={() => this.handleBankChange(null)}
          />
          {fundsProtectionCheck}
          <div className="fieldset fieldset--centerflex">
            <Button
              className={buttonClass}
              disabled={isDepositButtonDisabled}
              loading={loading}
              type="submit"
              onClick={onSubmit}
              buttonText={cc([
                'Deposit',
                depositAmount.value && formatCurrency(depositAmount.value)
              ])}
            />
          </div>
          <FormError
            errorMessage={
              errorMessage ||
              (bankUnavailable && `${bank?.value?.friendlyName} ${providerUnavailableDescription}`)
            }
          />
        </form>
        {!fundsProtectionCheck && (
          <>
            <div className="toc-text">
              MrQ holds all player funds in a separate bank account in accordance with the Gambling
              Commission&apos;s basic level of protection. This means that in case of insolvency
              customer funds will not be protected.
            </div>
            <a className="toc-text underline" onClick={openTermsConditions}>
              Terms and conditions apply
            </a>
          </>
        )}
      </div>
    );
  }
}

const Form = enhance(BaseForm);

export const _AddEcospendForm = (props) => {
  const fields = {
    bank: {
      required: true
    },
    depositAmount: {
      initial: props.defaultAmount || 50,
      required: true,
      error: `Minimum amount ${formatCurrency(10)}`,
      onChange: depositAmountValidation(props.minAmount, props.maxAmount)
    },
    promotionCode: {
      initial: props.promoCode || '',
      required: false,
      onChange: promotionCodeValidation,
      error: "This doesn't look like a valid code"
    }
  };

  // If we haven't retrieved the suggested amounts yet, show a loading spinner.
  // In this case, we are not initializing the form with the default preselected value,
  // which means the initial validation will run properly once the value is set.
  return props.isLoading ? (
    <LoadingElement display="medium" />
  ) : (
    <Form fields={fields} {...props} />
  );
};

_AddEcospendForm.propTypes = {
  ecospendBanks: PropTypes.object,
  fundsProtectionCheck: PropTypes.node.isRequired,
  onAnotherMethod: PropTypes.func.isRequired
};

_AddEcospendForm.defaultProps = {
  ecospendBanks: {}
};

const mapStateToProps = (state, { provider = 'ECOSPEND' }) => ({
  isLoading: isLoading(state, [Wallet.actionTypes.AT.SUGGESTED_DEPOSIT_AMOUNTS._]),
  defaultAmount:
    provider &&
    Wallet.selectors.getDefaultDepositAmountForPaymentType(state, paymentMethodType.BANK_ACCOUNT),
  minAmount:
    provider && Wallet.selectors.getMinAmountForPaymentType(state, paymentMethodType.BANK_ACCOUNT),
  maxAmount:
    provider && Wallet.selectors.getMaxAmountForPaymentType(state, paymentMethodType.BANK_ACCOUNT),
  providerUnavailableDescription:
    provider && Wallet.selectors.getPaymentProviderUnavailableDescription(state, provider)
});

export default connect(mapStateToProps)(_AddEcospendForm);
