export const NAME = 'Auth';

export const ACCESS_DENIED_PROMPT = {
  LOGIN: 'LOGIN',
  FAILED: 'FAILED',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT'
} as const;

export const MULTI_FACTOR_METHODS = {
  SMS: 'SMS',
  EMAIL: 'EMAIL'
} as const;

export const MULTI_FACTOR_FAILURE_TYPE = {
  MFA_REQUIRED: 'MFA_REQUIRED',
  INVALID_MFA_CODE: 'INVALID_MFA_CODE',
  TOO_MANY_FAILED_MFA_CODE_CHECKS: 'TOO_MANY_FAILED_MFA_CODE_CHECKS',
  TOO_MANY_MFA_CODES_SENT: 'TOO_MANY_MFA_CODES_SENT'
} as const;
