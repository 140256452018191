import React from 'react';
import cc from 'classcat';
import { constants } from 'modules/User';
import TileMediaCard from 'components/TileMediaCard';
import ActiveOfferCard from './components/ActiveOfferCard';
import UpcomingOfferCard from './components/UpcomingOfferCard';
import CompletedOfferCard from './components/CompletedOfferCard';
import InviteTileIcon from './components/InviteTileIcon';
import './inviteOfferCard.css';

const offerStatusClass = {
  [constants.GamifiedOfferStatus.ACTIVE]: 'inviteOfferCard--active',
  [constants.GamifiedOfferStatus.UPCOMING]: 'inviteOfferCard--upcoming',
  [constants.GamifiedOfferStatus.COMPLETED]: 'inviteOfferCard--completed'
};

interface InviteOfferCardProps {
  imageUrl: string;
  freeRounds: number;
  freeRoundsGameName: string;
  invitedCount: number;
  maxInvitedCount: number;
  offerStatus?: constants.GamifiedOfferStatus;
  canClaim?: boolean;
}

const InviteOfferCard = ({
  imageUrl,
  freeRounds,
  freeRoundsGameName,
  invitedCount,
  maxInvitedCount,
  offerStatus = constants.GamifiedOfferStatus.UPCOMING,
  canClaim
}: InviteOfferCardProps) => {
  const isClaimable = offerStatus === constants.GamifiedOfferStatus.ACTIVE && canClaim;

  return (
    <TileMediaCard.Root
      className={cc([
        'inviteOfferCard',
        offerStatusClass[offerStatus],
        {
          'inviteOfferCard--claimable': isClaimable
        }
      ])}
    >
      <TileMediaCard.Body
        imageUrl={imageUrl}
        imageSize={80}
        imageAlt={freeRoundsGameName}
        isDarkVariant
        tileChildren={
          offerStatus === constants.GamifiedOfferStatus.ACTIVE ? null : (
            <InviteTileIcon
              className="inviteOfferCard__tileIcon"
              isCompleted={offerStatus === constants.GamifiedOfferStatus.COMPLETED}
            />
          )
        }
      >
        <div className="inviteOfferCard__body">
          {offerStatus === constants.GamifiedOfferStatus.ACTIVE ? (
            <ActiveOfferCard
              freeRounds={freeRounds}
              invitedCount={invitedCount}
              maxInvitedCount={maxInvitedCount}
              isClaimable={isClaimable}
            />
          ) : offerStatus === constants.GamifiedOfferStatus.UPCOMING ? (
            <UpcomingOfferCard
              freeRounds={freeRounds}
              freeRoundsGameName={freeRoundsGameName}
              maxInvitedCount={maxInvitedCount}
            />
          ) : (
            <CompletedOfferCard
              freeRounds={freeRounds}
              freeRoundsGameName={freeRoundsGameName}
              maxInvitedCount={maxInvitedCount}
            />
          )}
        </div>
      </TileMediaCard.Body>
      {isClaimable && (
        <TileMediaCard.Action
          className="inviteOfferCard__footer"
          buttonName="getSpins"
          buttonText="Get spins!"
          buttonIcon="/assets/images1/referAFriend/tada-white.svg"
          buttonHref="secure/lobby/rewards"
        />
      )}
    </TileMediaCard.Root>
  );
};

export default InviteOfferCard;
