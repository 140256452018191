import React, { useCallback, useRef } from 'react';
import type { MouseEvent } from 'react';
import Markdown from 'components/Markdown';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { DialogHeader } from 'components/Dialog/Header';
import { Typography, TypographyVariant } from 'components/Typography';
import useScrollVisibility from 'hooks/useScrollVisibility';
import './rewardOfferInfoWithStickyActions.css';

interface RewardOfferInfoProps {
  title?: string;
  content?: string;
  heading?: string;
  showCTAButton?: boolean;
  termsAndConditions?: string;
  cta?: string;
  ctaText?: string;
  imageUrl?: string;
  onClaimOffer: () => void;
  close: () => void;
}

const RewardOfferInfoWithStickyActions = ({
  title = '',
  heading = '',
  content = '',
  termsAndConditions = '',
  imageUrl = '',
  cta = '',
  showCTAButton = true,
  ctaText,
  onClaimOffer,
  close
}: RewardOfferInfoProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const { hasVerticalScroll } = useScrollVisibility(containerRef);

  const scrollToTermsAndConditions = useCallback((e: MouseEvent) => {
    e.preventDefault();
    termsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <Dialog name="RewardOfferInfo">
      <DialogHeader icon="/assets/images1/dialogIcon.svg" onClose={close}>
        {title}
      </DialogHeader>
      <div ref={containerRef} className="rewardOfferInfo__container hide-scrollbar">
        <img className="rewardOfferInfo__image" src={imageUrl} alt="offerImage" />

        <Typography variant={TypographyVariant.HeaderXs}>
          <h4 className="rewardOfferInfo__title">{heading}</h4>
        </Typography>

        <div className="rewardOfferInfo__content">
          <Markdown content={content} />
        </div>

        <Typography variant={TypographyVariant.BodyXs}>
          <div ref={termsRef} className="rewardOfferInfo__termsAndConditions">
            <Markdown content={termsAndConditions} />
          </div>
        </Typography>

        <div className="rewardOfferInfo__actionsContainer">
          {showCTAButton && (
            <Button
              className="button--fullWidth rewardOfferInfo_primaryAction"
              name="claimOffer"
              id="offerCard-cta"
              tag={cta ? 'Link' : 'Button'}
              onClick={onClaimOffer}
              to={cta}
              disabled={!cta}
            >
              {ctaText || 'Claim offer'}
            </Button>
          )}

          {termsAndConditions && hasVerticalScroll && (
            <Typography variant={TypographyVariant.BodyXsStrong}>
              <a href="#" onClick={scrollToTermsAndConditions}>
                Full T&Cs apply
              </a>
            </Typography>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default RewardOfferInfoWithStickyActions;
