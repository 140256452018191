import React, { forwardRef, memo } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import SearchableDropdown from 'components/SearchableDropdown';
import PaymentMethodStateLabel from 'components/PaymentMethodStatus';
import Wallet from 'modules/Wallet';
import './ecospend.css';

// 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
const FUSE_MATCH_THRESHOLD = 0.35;

const BankOption = memo(
  forwardRef(({ data, onClick, classes = [] }, ref) => (
    <div ref={ref} className={cc(['ecospendBank', ...classes])} onClick={onClick}>
      {data.anotherMethod ? (
        <span>
          Can&apos;t find your bank?{' '}
          <span style={{ color: 'var(--secondaryColor)' }}> Try another method</span>
        </span>
      ) : (
        <>
          {data.logo && (
            <img
              className="ecospendBank__icon"
              src={data.logo}
              title={data.name}
              alt={data.friendlyName}
            />
          )}
          <div className="ecospendBank__labels">
            <div className="text-truncate">{data.friendlyName}</div>
            {!data?.available && (
              <PaymentMethodStateLabel
                provider={Wallet.constants.Provider.ECOSPEND}
                isSemiTransparent
              />
            )}
          </div>
        </>
      )}
    </div>
  ))
);

const EcospendSearchableBanks = ({
  onSelect,
  onClear,
  openUpwards,
  showAlternativeMethod,
  bankOptions
}) => {
  // Add persistent "Can't find your bank? ..." option
  const newBankOptions = showAlternativeMethod
    ? [
        ...bankOptions,
        {
          anotherMethod: true,
          isPersistent: true,
          friendlyName: 'Try another option'
        }
      ]
    : bankOptions;

  return (
    <SearchableDropdown
      OptionComponent={BankOption}
      options={newBankOptions}
      fuseConfigOptions={{ keys: ['friendlyName'], threshold: FUSE_MATCH_THRESHOLD }}
      onSelect={onSelect}
      onClear={onClear}
      openUpwards={openUpwards}
      label="* Search your bank"
    />
  );
};

EcospendSearchableBanks.defaultProps = {
  onSelect: null,
  onClear: null,
  openUpwards: false,
  showAlternativeMethod: true,
  bankOptions: []
};

EcospendSearchableBanks.propTypes = {
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  openUpwards: PropTypes.bool,
  showAlternativeMethod: PropTypes.bool,
  bankOptions: PropTypes.array
};

export default EcospendSearchableBanks;
