import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import Auth from 'modules/Auth';
// consider moving this to a shared location
import EcospendSearchableBanks from 'components/AddPayment/src/EcospendSearchableBanks';
import Button from 'components/Button';
import FormError from 'components/FormError';
import Modals from 'modules/Modals';
import { isLoading } from 'lib/redux-utils';
import { MIN_WITHDRAWAL } from 'lib/valFunctors';
import VerticalSteps from 'components/VerticalSteps';
import { stepsData, hasPaymentMethodStepsData } from './constants';
import type { SelectedBank } from './types';
import './openLoop.css';

interface OpenLoopProps {
  hasPaymentMethod: boolean;
  onClose: () => void;
}

const OpenLoop = ({ hasPaymentMethod, onClose }: OpenLoopProps) => {
  const dispatch = useAppDispatch();
  const [selectedBank, setSelectedBank] = useState<SelectedBank | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const ecospendBanks = useAppSelector(Wallet.selectors.getEcospendBanks);
  const balance = useAppSelector((state) => Wallet.selectors.getBalance(state));
  const isLoadingData = useAppSelector((state) =>
    isLoading(state, [Wallet.actionTypes.AT.DEPOSIT_ECOSPEND_FIRST._, Auth.AT.FINGERPRINT._])
  );

  useEffect(() => {
    void Api.actions.wallet.ecospendBanks()(dispatch);
  }, [dispatch]);

  const handleDeposit = async () => {
    if (!selectedBank) return;

    if (balance < MIN_WITHDRAWAL) {
      setErrorMessage('Oops! Minimum amount £10. Your balance is too low.');
      return;
    }
    setErrorMessage(null);

    try {
      const fingerprintResult = dispatch(
        Auth.actions.getDeviceFingerprint()
      ) as unknown as Promise<{
        value: any;
      }>;
      const { value: deviceInfo } = await fingerprintResult;
      const payload = {
        // agreeDepositTerms: acceptFundsProtection,
        bankId: selectedBank.bankId,
        amount: 0.01,
        bankName: selectedBank.friendlyName,
        deviceInfo
      };

      await Api.actions.wallet.depositEcospendFirst(null, payload)(dispatch);
      const modalPayload = {
        bankName: selectedBank.name,
        bankLogo: selectedBank.logo
      };
      onClose();
      dispatch(Wallet.actions.setHasOpenLoopWithdrawalStarted(true));
      dispatch(Modals.actions.open('depositEcospendFlow', modalPayload));
    } catch (e) {
      // error is shown as notification
    }
  };

  return (
    <div>
      <img
        src="/assets/images1/mastercard-bank.svg"
        className="userBox__image"
        alt=""
        data-chromatic="ignore"
      />
      <VerticalSteps items={hasPaymentMethod ? hasPaymentMethodStepsData : stepsData} />
      {!hasPaymentMethod && (
        <>
          {/* Known issue / bug: The searchable dropdown is not dismissed when the user clicks outside because of this line here:
            https://github.com/lindar-joy/mrq-front/blob/fbf483d19e4cdfc1a97a46801f42b76d2edbf230/src/App/components/UserBox/index.js#L67
            Which was added back 2018. I'm not sure if it's still needed, and what would the implications be if it was removed, so I'm not going to remove it.
            Effectively, that line disables the ClickOutside component from working inside the Modals (UserBox) component.
          */}
          <EcospendSearchableBanks
            bankOptions={Object.values(ecospendBanks)}
            openUpwards
            showAlternativeMethod={false}
            onSelect={(bank) => setSelectedBank(bank)}
            onClear={() => setSelectedBank(null)}
          />
          <div className="fieldset fieldset--centerflex">
            <Button
              loading={isLoadingData}
              type="submit"
              onClick={handleDeposit}
              disabled={!selectedBank}
              buttonText="Continue"
            />
          </div>
          <FormError errorMessage={errorMessage} />
        </>
      )}
    </div>
  );
};

export default OpenLoop;
