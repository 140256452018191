import type { VerticalStepsItem } from 'components/VerticalSteps/types';

export const stepsData: VerticalStepsItem[] = [
  {
    id: 1,
    icon: '/assets/images1/clock.svg',
    title: 'How long does it take?',
    description: `Withdrawal times may vary. Some withdrawals are typically processed within 2 hours, but in some cases, they may take up to 2 days.`
  },
  {
    id: 2,
    icon: '/assets/images1/chat.svg',
    title: 'Need help?',
    description: `If you haven&apos;t received an update regarding your withdrawal after 2 days, please reach out to our Player Experience team, who will be happy to provide an update. Be sure to check your emails before contacting us.`
  }
];
