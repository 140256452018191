import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import update from 'update-immutable';
import { isNil, isFunction, every, isEmpty, omit, difference, debounce } from 'lodash';
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import amplitude from 'lib/analytics';
import isMobile from 'lib/isMobile';
import { sanitizePiiMessage } from 'lib/valFuncs';
// TODO: This really needs cleanup, if you need to modify it contact @GeKorm

const VALIDATION_INTERVAL = 400;
const blurOnSubmit = isMobile();
const toErrorMap = (acc, val) => {
  acc[val.field] = val.msg;
  return acc;
};

const setField = (name, key, value) => (state) =>
  update(state, {
    fields: {
      [name]: {
        [key]: { $set: value }
      }
    }
  });

// Map<key, val>: Array
const setFields = (name, map) => (state) =>
  update(state, {
    fields: {
      [name]: map.reduce((acc, cur) => {
        acc[cur[0]] = { $set: cur[1] };
        return acc;
      }, {})
    }
  });

/**
 *
 * @param opts Object - { forceErrorsVisible: false }
 * @returns {function(*): Reform}
 */

const reform =
  (opts = {}) =>
  (BaseComponent) =>
    class Reform extends PureComponent {
      // eslint-disable-next-line react/static-property-placement -- preferred for HOCs
      static propTypes = {
        fields: PropTypes.object.isRequired,
        errors: PropTypes.object,
        onChange: PropTypes.func,
        submit: PropTypes.func,
        /** This function is called with the same signature as submit */
        extra: PropTypes.func,
        refName: PropTypes.string,
        validateOnPropChange: PropTypes.bool,
        /** Do not run the field validators on component mount */
        skipInitialCheck: PropTypes.bool,
        /** Only submit if at least 1 field has changed */
        noSubmitInitial: PropTypes.bool,
        resetAfterSuccessfulSubmit: PropTypes.bool,
        errorExpiration: PropTypes.number
      };

      // eslint-disable-next-line react/static-property-placement -- preferred for HOCs
      static defaultProps = {
        onChange: null,
        errors: {},
        refName: '',
        skipInitialCheck: false,
        validateOnPropChange: false,
        noSubmitInitial: false,
        resetAfterSuccessfulSubmit: false,
        submit: null,
        extra: null,
        errorExpiration: 5000
      };

      constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
          formValid: false,
          // eslint-disable-next-line react/no-unused-state -- could be useful
          submitFailed: false,
          fields: this.getInitialFields(props.fields)
        };
        this.timeout = null;
        this.validatorsRunning = 0;
        this.pendingSubmit = null;
        this.currentEditingFieldName = null;
        // DOM event validation registry to prevent race conditions
        this.eventRegistry = {};
      }

      componentDidMount() {
        this.mounted = true;
        // For initial values to work, validate all fields with a value
        this.init();
      }

      UNSAFE_componentWillReceiveProps({ fields: nextFields, errors: nextErrors }) {
        if (this.props.fields !== nextFields) {
          // Remove unused fields, add new ones, does not touch existing ones
          const oldKeys = Object.keys(this.state.fields);
          const newFields = omit(
            this.getInitialFields(nextFields),
            oldKeys.filter(
              (key) =>
                nextFields[key] &&
                this.state.fields[key].initial === nextFields[key].initial &&
                this.state.fields[key].required === nextFields[key].required
            )
          );
          const prunedFields = omit(
            this.state.fields,
            difference(Object.keys(this.state.fields), Object.keys(nextFields))
          );
          const fields = {
            ...prunedFields,
            ...newFields
          };
          this.setState(
            { fields },
            this.props.validateOnPropChange ? this.runValidators : undefined
          );
        }

        if (this.props.errors !== nextErrors) {
          const fieldKeys = Object.keys(nextFields);
          const newFields = Object.keys(nextErrors).reduce((acc, val) => {
            if (fieldKeys.includes(val)) {
              // Filter would be slower
              acc[val] = {
                serverError: { $set: nextErrors[val] }
              };
            }
            return acc;
          }, {});
          this.setState((prevState) =>
            update(prevState, {
              fields: newFields
            })
          );
        }
      }

      componentWillUnmount() {
        this.mounted = false;
        this._debouncedValidate.cancel();
      }

      getInitialFields = (fields) =>
        Object.assign(
          {},
          ...Object.keys(fields).map((key) => {
            const field = fields[key];
            return {
              [key]: {
                value: isNil(field.initial) ? '' : field.initial,
                touched: false,
                dirty: false,
                name: key,
                initial: isNil(field.initial) ? undefined : field.initial,
                required: field.required,
                hidden: field.hidden,
                serverError: this.props.errors[key],
                valid: field.required ? undefined : null,
                tooltip: field.tooltip
              }
            };
          })
        );

      init = () => {
        const { fields } = this.state;
        Object.keys(fields).forEach((key) => {
          const field = fields[key];
          const propField = this.props.fields[key];
          if (field.value || field.initial) {
            this.validate({
              field: propField,
              name: key,
              value: field.value || field.initial,
              validation:
                !this.props.skipInitialCheck &&
                (propField.onChange || propField.onBlur || propField.onFocus)
            });
          }
        });
      };

      handleError = (field, name, result, revalidate = true) => {
        const { value, error } = field;
        if (this.mounted) {
          this.setState(
            setField(name, 'valid', false),
            revalidate ? this.updateFormValid : undefined
          );
          this.setState(
            setField(name, 'error', isFunction(error) ? error(value, result) : result || error)
          );
        }
      };

      updateFormValid = () => {
        if (this.mounted) {
          this.setState(
            (state) =>
              update(state, {
                formValid: {
                  $set: every(
                    state.fields,
                    (field) =>
                      (field.valid === true && !field.serverError) ||
                      ((!field.required || !field.touched) && field.valid === null) ||
                      (field.required && field.touched && field.valid === null)
                  )
                }
              }),
            async () => {
              this.validatorsRunning--;
              if (this.pendingSubmit && this.validatorsRunning <= 0) {
                const submit = this.pendingSubmit();
                this.pendingSubmit = null;
                const result = await submit;
                this.resolver(result);
              }
            }
          );
        }
      };

      runValidators = (names, excluded) => {
        const { fields } = this.props;
        (names || Object.keys(fields)).forEach((name) => {
          if (name !== excluded) {
            const field = fields[name];
            this.validate({
              field,
              name,
              value: this.state.fields[name].value,
              validation: field.onChange || field.onBlur || field.onFocus,
              isAutofill: false,
              noRevalidation: true
            });
          }
        });
      };

      handleValidation = async (validator, field, name, noRevalidation, timestamp) => {
        const result = await validator;

        if (timestamp < this.eventRegistry[name]) return; // Dip out of race condition

        if (result === true && this.mounted) {
          this.setState(
            setFields(name, [
              ['valid', true],
              ['warn', false]
            ]),
            this.updateFormValid
          );
        } else if (Array.isArray(result) && this.mounted) {
          this.setState(
            setFields(name, [
              ['valid', true],
              ['warn', false]
            ]),
            () => {
              if (!noRevalidation) this.runValidators(result, name);
              this.updateFormValid();
            }
          );
          // TODO: Normalize all results into an object, not different types
        } else if (result && result.warn && this.mounted) {
          this.setState(
            setFields(name, [
              ['valid', true],
              ['warn', result.warn]
            ]),
            this.updateFormValid
          );
        } else {
          this.handleError(field, name, result);
        }
      };

      handleSubmit = (event) => {
        event.preventDefault?.(); // Custom event may not have `preventDefault`
        const { currentTarget } = event;
        if (this.props.submit) {
          this.runValidators(); // Also runs this.updateFormValid
          this.pendingSubmit = !this.pendingSubmit
            ? async () => {
                if (!this.state.formValid) return;
                let data;
                if (this.props.noSubmitInitial) {
                  let atLeastOneDifferent = false;
                  data = Object.assign(
                    {},
                    ...Object.keys(this.state.fields).map((name) => {
                      const field = this.state.fields[name];
                      if (field.value !== field.initial) {
                        atLeastOneDifferent = true;
                      }
                      return {
                        [name]: field.value
                      };
                    })
                  );
                  if (!atLeastOneDifferent) {
                    return true;
                  }
                } else {
                  data = Object.assign(
                    {},
                    ...Object.keys(this.state.fields).map((name) => ({
                      [name]:
                        this.state.fields[name].value ||
                        (!this.state.fields[name].dirty && this.state.fields[name].initial) ||
                        null
                    }))
                  );
                }
                const res = this.props.submit(data, event, currentTarget);
                try {
                  await res;
                  this.reset(this.props.resetAfterSuccessfulSubmit);
                  if (this.props.onSuccess) this.props.onSuccess(res);
                  return true;
                } catch (err) {
                  this.reset(this.props.resetAfterSuccessfulSubmit);
                  clearTimeout(this.timeout);
                  const errorState = {
                    formValid: { $set: false },
                    submitFailed: { $set: true },
                    errors: { $set: err.errors?.reduce(toErrorMap, {}) }
                  };
                  if (err.visible || opts.forceErrorsVisible) {
                    errorState.errorMessage = {
                      $set: err.msg || "Sorry, we couldn't process your request"
                    };
                    if (this.lastFocused) {
                      this.lastFocused.blur();
                      this.lastFocused = null;
                    }
                  }
                  if (this.mounted) {
                    this.setState((state) => update(state, errorState), this.props.onError?.(err));
                    amplitude.track('Form Submission Rejected', {
                      [constants.AMPLITUDE_EVENT_PROP_FORM_NAME]: currentTarget?.name,
                      [constants.AMPLITUDE_EVENT_PROP_FORM_ID]: currentTarget?.id,
                      // err.message for tracking thrown errors from submission
                      'Error Message': sanitizePiiMessage(err.message || this.state.errorMessage)
                    });
                  }
                }
              }
            : null;
          return new Promise((resolve) => {
            this.resolver = resolve;
          });
        }
      };

      validate = async ({
        field,
        name,
        value,
        validation,
        isAutofill,
        noRevalidation,
        timestamp
      }) => {
        this.validatorsRunning++;
        if (isEmpty(value) && !field.required) {
          if (this.mounted) this.setState(setField(name, 'valid', null), this.updateFormValid);
          return;
        } else if (isEmpty(value) && field.required && isAutofill) {
          if (this.mounted) this.setState(setField(name, 'valid', true), this.updateFormValid);
          return;
        } else if (isNil(validation) && !field.required) {
          this.updateFormValid();
          return;
        } else if (isNil(validation) && field.required) {
          if (isEmpty(value)) {
            this.handleError(field, name, false);
          } else if (this.mounted) {
            this.setState(setField(name, 'valid', null), this.updateFormValid);
          }
          return;
        }
        if (!isNil(value) && field) {
          if (validation instanceof RegExp) {
            if (validation.test(field.noTrim ? value : value.trim())) {
              if (this.mounted) this.setState(setField(name, 'valid', true), this.updateFormValid);
            } else {
              this.handleError(field, name);
            }
          } else if (isFunction(validation)) {
            await this.handleValidation(
              validation(value, this.state.fields, name),
              field,
              name,
              noRevalidation,
              timestamp
            );
          }
        } else if (isEmpty(value) && field.required) {
          if (this.mounted) this.setState(setField(name, 'valid', false), this.updateFormValid);
        }
      };

      // eslint-disable-next-line react/sort-comp -- cannot fully satisfy order due to this.validate
      _debouncedValidate = debounce(this.validate, VALIDATION_INTERVAL);

      debouncedValidate = this._debouncedValidate;

      validateOnChange = (args) => {
        if (args.name !== this.currentEditingFieldName) {
          if (this.currentEditingFieldName) this._debouncedValidate.flush();
          this.currentEditingFieldName = args.name;
        }
        this.debouncedValidate(args);
      };

      // TODO: MQPV-330 - Refactor value to be constructed from one place
      handleChange = (event, customProps) => {
        const target = event.target;
        let value =
          target && target.type === 'checkbox'
            ? target.checked
            : (customProps && customProps.value) || (target && target.value);
        const name = (customProps && customProps.name) || target.name;
        const field = this.props.fields[name];
        // Input format; used for formatting input value
        if (field.format) {
          const formattedValue = field.format(value);
          if (formattedValue) {
            value = formattedValue;
          } else if (formattedValue === '') {
            event.preventDefault?.(); // Custom event may not have `preventDefault`
            return;
          }
        }
        // Track last DOM event
        const timestamp = Date.now();
        this.eventRegistry[name] = timestamp;
        const validation = field.onChange;
        // Validate can handle invalid onChange, so always call it
        if (event.animationName === 'onAutoFillStart') {
          this.validate({
            field,
            name,
            value,
            validation,
            isAutofill: true,
            noRevalidation: true
          }); // CHECKED
        } else {
          this.validateOnChange({
            field,
            name,
            value,
            validation,
            isAutofill: false
          }); // CHECKED
        }
        if (this.mounted) {
          this.setState((state) => {
            const currentField = state.fields[name];
            return update(state, {
              fields: {
                [name]: {
                  value: { $set: value || '' },
                  dirty: { $set: !currentField.dirty ? true : currentField.dirty },
                  serverError: { $set: currentField.serverError ? null : currentField.serverError }
                }
              }
            });
          });
        }
        if (this.state.errorMessage) {
          this.timeout = setTimeout(() => {
            // && !errorState?
            if (this.mounted) {
              this.setState((state) => update(state, { errorMessage: { $set: undefined } }));
            }
          }, this.props.errorExpiration);
        }
        if (this.props.onChange) {
          this.props.onChange(this.props.refName, name, value);
        }
        let otherRequiredFieldsAreValid = true;
        let i = 0;
        while (i < Object.keys(this.props.fields).length && otherRequiredFieldsAreValid === true) {
          const key = Object.keys(this.props.fields)[i];
          const fieldProp = this.props.fields[key];
          const fieldState = this.state.fields[key];
          if (key !== name && fieldProp.required && !fieldState.valid) {
            otherRequiredFieldsAreValid = false;
          }
          i++;
        }
        if (otherRequiredFieldsAreValid && validation?.constructor?.name !== 'AsyncFunction') {
          this._debouncedValidate.flush();
          this.debouncedValidate = this.validate;
        } else {
          this.debouncedValidate = this._debouncedValidate;
        }
      };

      handleBlur = (event, customProps) => {
        const target = event.target;
        const name = target.name || customProps.name;
        let value =
          target.type === 'checkbox'
            ? target.checked
            : (customProps && customProps.value) || target.value;
        if (!value) {
          value = this.state.fields[name].value;
        }
        const field = this.props.fields[name];
        const validation = field.onBlur;
        if (validation) {
          // Track last DOM event
          const timestamp = Date.now();
          this.eventRegistry[name] = timestamp;
          this.validate({
            field,
            name,
            value,
            validation,
            isAutofill: false
          }); // CHECKED
        }
      };

      handleFocus = (event, customProps) => {
        const target = event.target;
        const name = target.name || customProps.name;
        let value =
          target.type === 'checkbox'
            ? target.checked
            : (customProps && customProps.value) || target.value;
        if (!value) {
          value = this.state.fields[name].value;
        }
        if (!this.state.fields[name].touched && this.mounted) {
          this.setState(setField(name, 'touched', true));
        }
        const field = this.props.fields[name];
        const validation = field.onFocus;
        if (validation) {
          // Track last DOM event
          const timestamp = Date.now();
          this.eventRegistry[name] = timestamp;
          this.validate({
            field,
            name,
            value,
            validation,
            isAutofill: false
          }); // CHECKED
        }
        if (blurOnSubmit) this.lastFocused = target;
      };

      reset = (fullReset) => {
        if (this.mounted) {
          if (fullReset) {
            this.setState((state) => {
              const result = update(state, {
                formValid: { $set: false },
                submitFailed: { $set: false },
                errors: { $set: null },
                serverError: { $set: null },
                fields: { $merge: this.getInitialFields(this.props.fields) }
              });
              return result;
            }, this.init);
          } else {
            this.setState((state) => update(state, { serverError: { $set: null } }), this.init);
          }
        }
      };

      callWithData = async (event) => {
        event.preventDefault?.(); // Custom event may not have `preventDefault`
        const { currentTarget } = event;
        const data = Object.assign(
          {},
          ...Object.keys(this.state.fields).map((name) => ({
            [name]: this.state.fields[name].value
          }))
        );
        const res = this.props.extra(data, event, currentTarget);
        try {
          await res;
          return true;
        } catch (err) {
          const name = currentTarget.name;
          const field = this.props.fields[name];
          this.handleError(field, name, err.message, false);
        }
      };

      // used for setting field values programmatically
      setField = (name, newValue) => {
        let value = newValue;
        const field = this.props.fields[name];
        // Input format; used for formatting input value
        if (field.format) {
          const formattedValue = field.format(value);
          if (formattedValue) {
            value = formattedValue;
          } else if (formattedValue === '') {
            return;
          }
        }
        // Track last DOM event
        const timestamp = Date.now();
        this.eventRegistry[name] = timestamp;
        const validation = field.onChange;
        // Validate can handle invalid onChange, so always call it
        this.validateOnChange({
          field,
          name,
          value,
          validation,
          isAutofill: false
        });

        if (this.mounted) {
          this.setState((state) => {
            const currentField = state.fields[name];
            return update(state, {
              fields: {
                [name]: {
                  value: { $set: value || '' },
                  dirty: { $set: !currentField.dirty ? true : currentField.dirty },
                  serverError: { $set: currentField.serverError ? null : currentField.serverError }
                }
              }
            });
          });
        }
        if (this.state.errorMessage) {
          this.timeout = setTimeout(() => {
            // && !errorState?
            if (this.mounted) {
              this.setState((state) => update(state, { errorMessage: { $set: undefined } }));
            }
          }, this.props.errorExpiration);
        }
        if (this.props.onChange) {
          this.props.onChange(this.props.refName, name, value);
        }
        let otherRequiredFieldsAreValid = true;
        let i = 0;
        while (i < Object.keys(this.props.fields).length && otherRequiredFieldsAreValid === true) {
          const key = Object.keys(this.props.fields)[i];
          const fieldProp = this.props.fields[key];
          const fieldState = this.state.fields[key];
          if (key !== name && fieldProp.required && !fieldState.valid) {
            otherRequiredFieldsAreValid = false;
          }
          i++;
        }
        if (otherRequiredFieldsAreValid && validation?.constructor?.name !== 'AsyncFunction') {
          this._debouncedValidate.flush();
          this.debouncedValidate = this.validate;
        } else {
          this.debouncedValidate = this._debouncedValidate;
        }
      };

      render() {
        return (
          <BaseComponent
            {...this.props}
            fields={Object.keys(this.props.fields)}
            errorMessage={this.state.errorMessage}
            errors={this.state.errors}
            {...this.state.fields}
            onSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            handleFocus={this.handleFocus}
            revalidate={this.runValidators}
            formValid={this.state.formValid}
            reset={this.reset}
            setField={this.setField}
            callWithData={this.callWithData}
          />
        );
      }
    };

export default reform;
