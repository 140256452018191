import React from 'react';
import { useAppSelector } from 'hooks/redux';
import Wallet from 'modules/Wallet';
import { logos, paymentMethodProvider } from 'lib/constants';
import { isLoading } from 'lib/redux-utils';
import { Typography, TypographyVariant } from 'components/Typography';
import PaymentMethodsProvidersList from './PaymentMethodsProvidersList';
import type { PaymentMethodMapValues } from './types';
import { paymentMethodMap } from './types';

interface PaymentMethodsProvidersListAdapterProps {
  onClickHandler: (e: any) => void;
}

// This is temporary fallback for the icons, until we complete the A/B Test.
// After that, we will get the icons from the backend
const mapTypeToIcon = (type: string) => {
  switch (type) {
    case paymentMethodProvider.PCI_PROXY:
      return logos.CARD_NEW;
    case paymentMethodProvider.BRAINTREE:
      return logos.CARD_NEW;
    case paymentMethodProvider.FONIX:
      return logos.MOBILE_NEW;
    case paymentMethodProvider.PAYPAL:
      return logos.PAYPAL;
    case paymentMethodProvider.ECOSPEND:
      return logos.BANK_ACCOUNT_NEW;
    case paymentMethodProvider.TRUELAYER:
      return logos.BANK_ACCOUNT_NEW;
    default:
      return null;
  }
};

const PaymentMethodsProvidersListAdapter = ({
  onClickHandler
}: PaymentMethodsProvidersListAdapterProps) => {
  const availablePaymentOptionRefs = useAppSelector(Wallet.selectors.getPossibleNewPaymentsRefs);
  const paymentProviders = useAppSelector(Wallet.selectors.getPaymentProviders);

  const loadingProviders = useAppSelector((state) =>
    isLoading(state, [
      Wallet.actionTypes.AT.LIST_PAYMENT_PROVIDERS._,
      Wallet.actionTypes.AT.LIST_PAYMENT_PROVIDERS_AVAILABLE._
    ])
  );

  const options = availablePaymentOptionRefs?.map((type: PaymentMethodMapValues) => ({
    type,
    title: paymentProviders[type]?.firstDepositFields.label,
    // TODO: After A/B test is done, we will get the icons from backend
    // icon: paymentProviders[type]?.firstDepositFields.icon,
    icon: mapTypeToIcon(type),
    tag: [paymentMethodProvider.BRAINTREE, paymentMethodProvider.PCI_PROXY].includes(type)
      ? 'Recommended'
      : undefined, // TODO: This is good enough until we support tags on the backend
    value: paymentMethodMap[type]
  }));

  if (!loadingProviders && !options?.length)
    return (
      <form className="paymentMethodsProvidersList">
        <Typography variant={TypographyVariant.HeaderXxs}>
          <div className="paymentMethodsProvidersList__title">Add a deposit method</div>
        </Typography>
        We are currently experiencing some issues, please try again later.
      </form>
    );
  return <PaymentMethodsProvidersList options={options} onClickHandler={onClickHandler} />;
};

export default PaymentMethodsProvidersListAdapter;
