import React from 'react';
import BottomSheet from 'components/BottomSheet';
import ReferAFriendModalContent from './components/ReferAFriendModalContent';

const ReferAFriendModal = () => (
  <BottomSheet name="referAFriend" className="referAFriendModal">
    <ReferAFriendModalContent />
  </BottomSheet>
);

export default ReferAFriendModal;
