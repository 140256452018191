import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import cc from 'classcat';
import { paymentMethodType, labels } from 'lib/constants';
import { derivePaymentMethodLogo } from 'lib/paymentUtils';
import List from 'components/List';
import Badge, { BadgeVariant } from 'components/Badge';
import './paymentMethod.css';

const PaymentMethod = ({
  className,
  operator,
  type,
  identifier,
  cardholder,
  expireDate,
  name,
  primary,
  canAlterPrimaryMethod,
  makePrimary,
  isUnavailable,
  bankLogo,
  // TODO: Maybe remove all method related props and just use the props param.
  method
}) => {
  const operatorDisplay = capitalize(operator || type || 'Unknown');
  const { ownerName } = method;
  const handleMakePrimary = useCallback(() => {
    makePrimary(name);
  }, [makePrimary, name]);

  let identifierRow = null;
  switch (type) {
    case paymentMethodType.CARD:
      identifierRow = (
        <>
          <span className="paymentMethod__cardholder">{cardholder}</span>
          <span className="paymentMethod__expireDate">Expires {expireDate}</span>
        </>
      );
      break;
    case paymentMethodType.BANK_ACCOUNT:
      identifierRow = identifier ? (
        <>
          <span className="paymentMethod__cardholder">{ownerName}</span>
          <span className="paymentMethod__expireDate">{identifier}</span>
        </>
      ) : (
        <span className="paymentMethod__expireDate">Pending deposit to get bank account info</span>
      );
      break;
    default:
      identifierRow = identifier;
      break;
  }

  return (
    <List.Item.Root
      prefix={
        <div className="paymentMethod__icon">
          <img
            src={derivePaymentMethodLogo({ operator, type, bankLogo })}
            title={`Deposit with ${operatorDisplay}`}
            alt={operatorDisplay}
          />
        </div>
      }
      suffix={
        !isUnavailable ? (
          makePrimary && (
            <Badge
              onClick={!primary ? handleMakePrimary : null}
              variant={primary ? BadgeVariant.SUCCESS : BadgeVariant.NEUTRAL}
              disabled={!canAlterPrimaryMethod}
            >
              {primary ? 'Primary' : 'Make primary'}
            </Badge>
          )
        ) : (
          <Badge variant={BadgeVariant.WARNING}>Temporarily unavailable</Badge>
        )
      }
    >
      <div
        className={cc([
          className,
          'paymentMethod',
          `paymentMethod--${type.toLowerCase()}`,
          operator && type !== operator && `paymentMethod--${operator.toLowerCase()}`,
          isUnavailable && 'unavailable'
        ])}
      >
        <span className="paymentMethod__description">
          <div className="paymentMethod__title">
            <b>
              {labels[operator] || operator || labels[type] || type}{' '}
              {type === paymentMethodType.CARD && `Ending in ${identifier}`}
            </b>
          </div>
          <div className="paymentMethod__identifier">{identifierRow}</div>
        </span>
      </div>
    </List.Item.Root>
  );
};

PaymentMethod.propTypes = {
  type: PropTypes.string.isRequired,
  identifier: PropTypes.string,
  className: PropTypes.string,
  operator: PropTypes.string,
  cardholder: PropTypes.string,
  expireDate: PropTypes.string,
  name: PropTypes.string,
  primary: PropTypes.bool,
  canAlterPrimaryMethod: PropTypes.bool,
  makePrimary: PropTypes.func,
  isUnavailable: PropTypes.bool,
  bankLogo: PropTypes.string,
  method: PropTypes.object
};

PaymentMethod.defaultProps = {
  identifier: '',
  className: null,
  operator: null,
  cardholder: null,
  expireDate: null,
  name: null,
  primary: false,
  canAlterPrimaryMethod: false,
  makePrimary: null,
  isUnavailable: true,
  bankLogo: undefined,
  method: {}
};

export default memo(PaymentMethod);
