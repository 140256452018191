import { useEffect } from 'react';
import type { RewardOffer } from 'modules/User';
import Offers from 'modules/Offers';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

interface useRewardOfferDetailsProps {
  offerRef?: string;
  rewardOfferData?: RewardOffer;
}

const useRewardOfferDetails = ({ offerRef, rewardOfferData }: useRewardOfferDetailsProps) => {
  const dispatch = useAppDispatch();
  const offer = useAppSelector((state) => Offers.selectors.getOffer(state, offerRef));
  const isLoading = useAppSelector(Offers.selectors.getOfferLoading);

  useEffect(() => {
    if (!rewardOfferData && offerRef) {
      void Offers.actions.getOffer({ offerRef })(dispatch);
    }
  }, [offerRef, rewardOfferData, dispatch]);

  return { data: rewardOfferData || offer, isLoading };
};

export default useRewardOfferDetails;
