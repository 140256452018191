import React from 'react';
import type { paymentMethodType } from 'lib/constants';
import { useAppSelector } from 'hooks/redux';
import Wallet from 'modules/Wallet';
import DepositRange from './DepositRange';

interface DepositRangeAdapterProps {
  paymentMethodType: keyof typeof paymentMethodType;
}

const DepositRangeAdapter = ({ paymentMethodType }: DepositRangeAdapterProps) => {
  const { min, max } = useAppSelector((s) =>
    Wallet.selectors.getMinMaxRangeForPaymentType(s, paymentMethodType)
  );
  return <DepositRange min={min} max={max} />;
};

export default DepositRangeAdapter;
