const _loginPassword = /^(?=.*[a-zA-Z0-9]).{6,64}$/;
const _password = /^(?=.*[a-zA-Z0-9]).{8,64}$/;
const _email =
  // eslint-disable-next-line max-len -- long regex ¯\_(ツ)_/¯
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const _postcode = /^[a-zA-Z0-9\u0020]{1,20}$/;
const _username = /^[a-zA-Z0-9._-]{3,30}$/;
const _name = RegExp(
  '^[a-zA-Z' + // Characters allowed by Deed Poll, aka, Alpha (A-Za-z),
    '\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u00FF' + // Alphabetic parts of Latin-1 Supplement i.e.
    // \u00C0 to \u00FF (both included), except characters \u00D7 (multiplication sign)
    // and \u00F7 (division sign).
    '\\u0100-\\u017F' + // Latin Extended-A
    // Latin letters with one or more general diacritical marks
    '\\u0020' + //  , aka space
    '\\u002D' + // -, aka Hyphen-minus
    '\\u0027' + // ', aka apostrophe
    '\\u0060' + // `, aka grave accent
    '\\u2018' + // ‘, aka left single quotation mark
    '\\u2019' + // ’, aka right single quotation mark
    ',.' + // comma and full stop
    ']{1,200}$',
  'u' // Enable all unicode features and interpret all unicode escape sequences as such
);
const _address = RegExp(
  '^[a-zA-Z0-9' + // Alpha (A-Za-z) and numbers
    '\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u00FF' + // Alphabetic parts of Latin-1 Supplement i.e.
    // \u00C0 to \u00FF (both included), except characters \u00D7 (multiplication sign)
    // and \u00F7 (division sign).
    '\\u0100-\\u017F' + // Latin Extended-A
    // Latin letters with one or more general diacritical marks
    '\\u0020' + //  , aka space
    '\\u0026' + // &, aka ampersand
    '\\u002D' + // -, aka Hyphen-minus
    '\\u2013' + // –, aka en dash
    '\\u0027' + // ', aka apostrophe
    '\\u0060' + // `, aka grave accent
    '\\u2018' + // ‘, aka left single quotation mark
    '\\u2019' + // ’, aka right single quotation mark
    '\\u002f' + // /, aka solidus (the technical name for the slash character on your keyboard)
    '\\u0028' + // (, aka left parenthesis
    '\\u0029' + // ), aka right parenthesis
    ',.' + // comma and full stop
    ']{0,500}$',
  'u' // Enable all unicode features and interpret all unicode escape sequences as such
);
const _city = RegExp(
  '^[a-zA-Z' +
    '\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u00FF' + // Alphabetic parts of Latin-1 Supplement i.e.
    // \u00C0 to \u00FF (both included), except characters \u00D7 (multiplication sign)
    // and \u00F7 (division sign).
    '\\u0100-\\u017F' + // Latin Extended-A
    '\\u0020' + //  , aka space
    '\\u0026' + // &, aka ampersand
    '\\u002D' + // -, aka Hyphen-minus
    '\\u0027' + // ', aka apostrophe
    '\\u0060' + // `, aka grave accent
    '\\u2018' + // ‘, aka left single quotation mark
    '\\u2019' + // ’, aka right single quotation mark
    ',.' + // comma and full stop
    ']{0,100}$',
  'u' // Enable all unicode features and interpret all unicode escape sequences as such
);
// /^[\p{Alpha}\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\p{L}\u0020\u0026\u002D\u0027\u0060\u2018\u2019,.]{0,100}$/u;
const _promoCode = /^[A-Za-z0-9]{1,30}$/;
const getPhoneRegex = () => {
  if (__ENV__.MRQ_COUNTRY === 'UK') {
    // eslint-disable-next-line max-len -- long regex ¯\_(ツ)_/¯
    return /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+?)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/;
  }
  if (__ENV__.MRQ_COUNTRY === 'MT') {
    return /^(?:\+|00)?(?:356\s?)?\d{1}(?:\s?\d){7}$/;
  }
  if (__ENV__.MRQ_COUNTRY === 'IE') {
    // eslint-disable-next-line max-len -- long regex ¯\_(ツ)_/¯
    return /^(?:(?:\+|00)?353|0)?\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}\s*\d{1}$/;
  }
  return /.*/;
};
// This is done to preserve equality, same as declaring new RegExp(), but less error prone
export const loginPassword = _loginPassword; // This is to allow user which were able to register with minimum 6 chars
export const password = _password;
export const email = _email;
export const phone = getPhoneRegex();
export const username = _username;
export const postcode = _postcode;
export const name = _name;
export const address = _address;
export const city = _city;
export const promoCode = _promoCode;

export const regexRemoveStartsAndEndsWith = (regex: RegExp): RegExp => {
  let regexString = regex.toString();
  if (regexString.indexOf('/^') === 0) {
    regexString = regexString.substring(2);
  }
  if (regexString.indexOf('$/') === regexString.length - 2) {
    regexString = regexString.substring(0, regexString.length - 2);
  }
  return new RegExp(regexString);
};
