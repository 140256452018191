import { ContactFieldType } from './constants';

export const referralTypeText = {
  [ContactFieldType.EMAIL]: {
    button: 'Invite by Email',
    notification: `We emailed your friends with the offer. Reach out to them if you think they may confuse this email with spam.`
  },
  [ContactFieldType.TEL]: {
    button: 'Invite by Text Message',
    notification: `We texted your friends with the offer. Reach out to them if you think they may confuse this SMS with spam.`
  }
};

export const inviteText = `Hey! Join me at my go-to casino, MrQ and get {freeRounds} free spins on {freeRoundsGameName} with your first £10 deposit! 🎰

No faff, no nonsense, and all the fun. Sign up using my link here: https://mrq.com/invited/{username}. T&Cs apply`;
