import React, { useCallback } from 'react';
import Modals from 'modules/Modals';
import { EffectTypes } from 'services/Effects';
import { RewardsCalendarDayStatus } from 'components/RewardOffersGridList/types';
import type { SubOffer } from 'components/RewardOffersGridList/types';
import { useAppDispatch } from 'hooks/redux';
import './rewardsCalendarDay.css';

interface RewardsCalendarDayProps {
  offer: SubOffer;
}

const RewardsCalendarDay = ({ offer }: RewardsCalendarDayProps) => {
  const dispatch = useAppDispatch();
  const openOfferModal = useCallback(
    () =>
      dispatch(
        Modals.actions.open('rewardOffer', {
          rewardOfferData: offer,
          modalParams: {
            source: EffectTypes.OfferClickedSourceType.Rewards,
            ref: offer.ref
          }
        })
      ),
    [dispatch, offer]
  );

  return (
    <button
      type="button"
      className="rewardsCalendarDay"
      disabled={offer.status === RewardsCalendarDayStatus.FUTURE}
      onClick={openOfferModal}
    >
      <img src={offer.thumbnailUrl} alt={offer.title} />
    </button>
  );
};

export default RewardsCalendarDay;
