import * as actions from './src/actions';
import * as constants from './src/constants';
import { AT, T } from './src/actionTypes';
import * as selectors from './src/selectors';
import * as model from './src/model';
import reducer from './src/reducer';

// TODO: Export AT, T  in all other modules too
export default { actions, constants, NAME: constants.NAME, reducer, selectors, model, AT, T };
export * as actionTypes from './src/actionTypes';
export * as types from './src/types';
export * as constants from './src/constants';
