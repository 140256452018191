import React from 'react';
import cc from 'classcat';
import Button from 'components/Button';

interface TileMediaCardActionProps {
  buttonName: string;
  buttonText: string;
  buttonIcon?: string;
  buttonClass?: string;
  buttonHref?: string;
  className?: string;
  onClick?: () => void;
}

const TileMediaCardAction = ({
  buttonName,
  buttonText,
  buttonIcon,
  buttonClass,
  buttonHref,
  className,
  onClick
}: TileMediaCardActionProps) => (
  <div className={className}>
    {buttonHref ? (
      <Button
        className={cc(['button--fullWidth button__smaller', buttonClass])}
        name={buttonName}
        buttonText={buttonText}
        icon={buttonIcon}
        tag="Link"
        href={buttonHref}
      />
    ) : (
      <Button
        className={cc(['button--fullWidth button__smaller', buttonClass])}
        name={buttonName}
        buttonText={buttonText}
        icon={buttonIcon}
        onClick={onClick}
      />
    )}
  </div>
);

export default TileMediaCardAction;
