import React, { useState, useEffect, useCallback, useRef } from 'react';
import { isNil } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import User from 'modules/User';
import Api from 'services/Api';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import Markdown, { defaultRenderers } from 'components/Markdown';
import './termsConditions.css';

const ERROR_MESSAGE = `Oops, computers failed us. Please check <a href="/terms-conditions">here for our latest General Terms & Conditions</a> or <a onClick="location.reload(true);">refresh</a> the page to try again`;

const publicRenderers = {
  ...defaultRenderers,
  link: ({ children }: { children: React.ReactNode }) => <>{children}</>
};

interface TermsConditionsType {
  isPublic?: boolean;
  title?: string;
  close: () => void;
  onBack?: () => void;
}

export const TermsConditions = ({ close, onBack, title, isPublic }: TermsConditionsType) => {
  const dispatch = useAppDispatch();
  const agreeButton = useAppSelector((state) => !User.selectors.model(state).agreedToLatestTerms);
  const [isError, setIsError] = useState(false);
  const [content, setContent] = useState(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const handleAgreeToLatestTerms = useCallback(async () => {
    close();
    await Api.actions.user.agreeLatestTerms()(dispatch);
  }, [close, dispatch]);

  const handleDownload = useCallback(() => {
    if (!ulRef.current) {
      return;
    }
    const element = document.createElement('a');
    const file = new Blob([ulRef.current.outerHTML], { type: 'text/html' });
    element.href = URL.createObjectURL(file);
    element.download = 'MrQ Terms & Conditions.html';
    element.click();
  }, []);

  useEffect(() => {
    void Api.actions.user
      .getTermsAndConditionsContent()(dispatch)
      .then((res) => {
        // @ts-expect-error - res.data is not typed
        setContent(res?.data);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, [dispatch]);

  if (!content) {
    return null;
  }

  return (
    <UserBox
      id="termsConditions"
      className="termsConditions modal terms"
      title={title || 'General Terms and Conditions'}
      closeCallback={close}
      onBack={onBack}
    >
      <>
        <article ref={ulRef}>
          <Markdown content={content} renderers={isPublic ? publicRenderers : defaultRenderers} />
        </article>
        {!isNil(content) ? (
          <div className="fieldset fieldset--centerflex termsConditions__buttons">
            {agreeButton ? (
              <Button
                onClick={handleAgreeToLatestTerms}
                buttonText="Agree"
                name="agreeLatestTerms"
                id="termsConditions-agreeLatestTerms"
                title="Agree MrQ Terms & Conditions"
                className="submit"
              />
            ) : (
              <Button
                onClick={close}
                buttonText="Close"
                name="close"
                id="termsConditions-close"
                title="Close"
                className="submit"
              />
            )}
            <Button
              onClick={handleDownload}
              name="download"
              id="termsConditions-download"
              buttonText="Download&nbsp;&nbsp;&nbsp;⇩"
              title="Download the entire document"
              className="submit"
            />
          </div>
        ) : (
          <>
            {isError ? (
              <FormError errorMessage={ERROR_MESSAGE} />
            ) : (
              <LoadingElement key="LoadingElement" />
            )}
          </>
        )}
      </>
    </UserBox>
  );
};

export default makeModal(
  'termsConditions',
  { name: 'modal-fade', timeout: 200 },
  { className: 'termsConditions modal' }
)(TermsConditions);
