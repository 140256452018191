import React, { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import cc from 'classcat';
import User from 'modules/User';
import { useAppSelector } from 'hooks/redux';
import './launcher.css';

interface LauncherProps {
  openLiveChat(location: string): void;
}

export const Launcher = ({ openLiveChat }: LauncherProps) => {
  const unreadCount = useAppSelector(User.selectors.getLiveChatUnreadCount);
  const isLoaded = useAppSelector(User.selectors.getIsLiveChatLoaded);
  const isVisible = useAppSelector(User.selectors.getIsLauncherVisible);
  const location = useLocation();
  const isAccountPage = location.pathname.startsWith('/secure/user/account');
  const isLobbyPage = location.pathname.startsWith('/secure/lobby');
  const handleClickLauncher = useCallback(() => openLiveChat('launcher'), [openLiveChat]);

  if (!isLoaded && !isAccountPage && !(isVisible || unreadCount)) {
    return null;
  }

  return (
    <button
      type="button"
      className={cc({
        liveChatLauncher: true,
        'liveChatLauncher--hidden': !isVisible && !unreadCount,
        'liveChatLauncher--lobby': isLobbyPage
      })}
      onClick={handleClickLauncher}
    >
      {unreadCount > 0 && <div className="liveChatLauncher__badge">{unreadCount}</div>}
      <img src="/assets/images1/liveChat/launcher.svg" alt="Open LiveChat" />
    </button>
  );
};

export default memo(Launcher);
