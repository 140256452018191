import type { ReactNode } from 'react';
import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import * as ListItem from '../ListItem';

const ExternalIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" color="var(--neutral200)">
    <use href="/assets/images1/up-right-from-square.svg#icon" />
  </svg>
);

interface LinkForwardProps {
  children: ReactNode;
  to?: string;
  onClick?: () => void;
}

const LinkExternal = ({ children, to, onClick }: LinkForwardProps) => (
  <ListItem.Root href={to} onClick={onClick} suffix={<ExternalIcon />}>
    <Typography variant={TypographyVariant.BodyMd}>
      <span className="listItem__text--secondary">{children}</span>
    </Typography>{' '}
  </ListItem.Root>
);

export default LinkExternal;
