import { actionTypeCreator, ASYNC, SYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REFRESH: ASYNC,
  ADD_NEW_CARD: ASYNC,
  DEPOSIT_CARD_PCI_PROXY: ASYNC,
  DEPOSIT_CARD_BRAINTREE: ASYNC,
  DEPOSIT_MOBILE: ASYNC,
  DEPOSIT_PAYPAL: ASYNC,
  DEPOSIT_ECOSPEND: ASYNC,
  ECOSPEND_SMS: ASYNC,
  DEPOSIT_ECOSPEND_FIRST: ASYNC,
  CREATE_PAYPAL: ASYNC,
  CREATE_PAYMENT_METHOD: ASYNC,
  COLLECT_DATA: ASYNC,
  PCI_PROXY_3D_CHALLENGE: ASYNC,
  LIST_PAYMENT_METHODS: ASYNC,
  LIST_PAYMENT_METHODS_DETAILED: ASYNC,
  LIST_PAYMENT_PROVIDERS: ASYNC,
  LIST_PAYMENT_PROVIDERS_AVAILABLE: ASYNC,
  GLOBAL_JACKPOT: ASYNC,
  UPDATE_BALANCE: ASYNC,
  REMOVE_CARD: ASYNC,
  SMS_VERIFICATION_STATE: ASYNC,
  VERIFY_SMS: ASYNC,
  SEND_SMS_VERIFICATION: ASYNC,
  UPDATE_MOBILE: ASYNC,
  WITHDRAW: ASYNC,
  WITHDRAW_PROCESSING_TIME: ASYNC,
  WITHDRAW_CANCEL: ASYNC,
  CANCEL_MULTIPLE_WITHDRAWS: ASYNC,
  PENDING_WITHDRAWALS: ASYNC,
  GAME_TRANSACTIONS: ASYNC,
  BANK_TRANSACTIONS: ASYNC,
  BANK_TRANSACTIONS_DEPOSIT: ASYNC,
  GET_DEPOSIT_LIMIT: ASYNC,
  SET_DEPOSIT_LIMIT: ASYNC,
  SET_DEPOSIT_LIMIT_DAILY: ASYNC,
  SET_DEPOSIT_LIMIT_WEEKLY: ASYNC,
  SET_DEPOSIT_LIMIT_MONTHLY: ASYNC,
  SUGGESTED_DEPOSIT_AMOUNTS: ASYNC,
  NET_DEPOSITS_UK: ASYNC,
  AGREE_PAYMENT_TERMS: ASYNC,
  AGREED_PAYMENT_TERMS: ASYNC,
  CHANGE_PRIMARY_CARD: ASYNC,
  UPDATE_PAYMENT_CARD: ASYNC,
  PAYPAL_TOKEN: ASYNC,
  GET_TOKEN: ASYNC,
  GET_NONCE: ASYNC,
  ECOSPEND_BANKS: ASYNC,
  WS_SUBSCRIBE: ASYNC,
  WS_UNSUBSCRIBE: ASYNC,
  GET_DEPOSIT_LIMIT_SOLICITATIONS: ASYNC,
  ACCEPT_DEPOSIT_LIMIT_SOLICITATIONS: ASYNC,
  CANCEL_DEPOSIT_LIMIT_SOLICITATIONS: ASYNC
});

export const T = namespace({
  DELETE_TOKEN: SYNC,
  SET_HAS_DEPOSITED: SYNC,
  OVERRIDE_BALANCE: SYNC,
  CANCEL_OVERRIDE_BALANCE: SYNC,
  SET_MOBILE: SYNC,
  UNSET_MOBILE: SYNC,
  SET_PCI_PROXY_ERROR: SYNC,
  SET_CURRENT_PCI_3D_CHALLENGE_TRANSACTION_ID: SYNC,
  SET_HAS_OPEN_LOOP_WITHDRAWAL_STARTED: SYNC,
  SELECT_TRANSACTIONS_DEPOSIT: SYNC,
  SELECT_ALL_TRANSACTIONS_DEPOSIT: SYNC,
  DESELECT_TRANSACTIONS_DEPOSIT: SYNC,
  DESELECT_ALL_TRANSACTIONS_DEPOSIT: SYNC,
  SELECT_TRANSACTIONS: SYNC,
  SELECT_ALL_TRANSACTIONS: SYNC,
  DESELECT_TRANSACTIONS: SYNC,
  DESELECT_ALL_TRANSACTIONS: SYNC,
  CLEAR_ECOSPED_DEPOSIT: SYNC,
  SET_EXPANDED_BANK_TRANSACTION_ID: SYNC,
  SET_EXPANDED_GAME_TRANSACTION_ID: SYNC
});
