import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import type { VerticalStepsItem } from './types';
import './verticalSteps.css';

interface VerticalStepsProps {
  items: VerticalStepsItem[];
}

const VerticalSteps = ({ items }: VerticalStepsProps) => (
  <div className="verticalSteps">
    {items.map((item, index) => (
      <div key={item.id} className="verticalSteps__item">
        <div className="verticalSteps__icon-container">
          <div className="verticalSteps__icon-wrapper">
            <img src={item.icon} className="icon" alt="" />
          </div>
          {index !== items.length - 1 && <div className="verticalSteps__line" />}
        </div>
        <div className="verticalSteps__content">
          <Typography variant={TypographyVariant.BodyMdStrong}>
            <span>{item.title}</span>
          </Typography>
          <Typography variant={TypographyVariant.BodySm}>
            <span
              className="verticalSteps__description"
              // eslint-disable-next-line react/no-danger -- description is sanitized
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </Typography>
        </div>
      </div>
    ))}
  </div>
);

export default VerticalSteps;
