import React, { useCallback } from 'react';
import Api from 'services/Api';
import { useAppDispatch } from 'hooks/redux';
import useTimeToUTCMidnight from './hooks/useTimeToUTCMidnight';

const NextGameCountdown = () => {
  const dispatch = useAppDispatch();
  const onMidnight = useCallback(() => {
    void Api.actions.user.getDailyFreeGames()(dispatch);
  }, [dispatch]);
  const timeLeft = useTimeToUTCMidnight({ onMidnight });

  return (
    <>
      Next free game in <strong>{timeLeft}</strong>
    </>
  );
};

export default NextGameCountdown;
