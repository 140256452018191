import React, { memo, useMemo } from 'react';
import { formatCurrency } from 'lib/formatters';
import type { TakenSeat } from 'lib/LobbyEntities';
import withLazyStyle from 'components/LazyStyle';
import { Typography, TypographyVariant } from 'components/Typography';
import Badge, { BadgeVariant } from 'components/Badge';
import { LiveOnAirIcon } from 'components/IconsLegacy';
import style from './liveTileInfo.css?lazy';

interface LiveTileInfoProps {
  minBet?: number | null;
  dealerName?: string;
  takenSeats?: TakenSeat[];
}

const LiveTileInfo = ({ minBet, takenSeats, dealerName }: LiveTileInfoProps) => {
  const minBetFormatted = useMemo(() => (minBet ? formatCurrency(minBet) : null), [minBet]);
  const seatsOccupancy = useMemo(() => {
    if (!takenSeats || takenSeats.length === 0) {
      return null;
    }
    return `${takenSeats.filter((seat) => seat.isTaken).length}/${takenSeats.length}`;
  }, [takenSeats]);

  return (
    <div className="liveTileInfo">
      <div className="liveTileInfo__details">
        {Boolean(minBet) && (
          <Typography variant={TypographyVariant.BodyXs}>
            <span className="liveTileInfo__textLabel">Min {minBetFormatted}</span>
          </Typography>
        )}
        {seatsOccupancy && (
          <Typography variant={TypographyVariant.BodyXs}>
            <span className="liveTileInfo__textLabel">Seats {seatsOccupancy}</span>
          </Typography>
        )}
      </div>
      {dealerName && (
        <Badge variant={BadgeVariant.NEUTRAL} prefix={<LiveOnAirIcon />}>
          {dealerName}
        </Badge>
      )}
    </div>
  );
};

export default memo(withLazyStyle(style)(LiveTileInfo));
