import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import './dailyFreeGamesRibbon.css';

interface DailyFreeGamesRibbonProps {
  label?: string;
}

const DailyFreeGamesRibbon = ({ label = 'Free' }: DailyFreeGamesRibbonProps) => (
  <div className="dailyFreeGamesRibbon">
    <img className="dailyFreeGamesRibbon__icon" src="/assets/images1/rewards/gift.svg" alt="Free" />
    <Typography variant={TypographyVariant.BodyXxsStrong}>
      <div className="text-truncate">{label}</div>
    </Typography>
  </div>
);

export default DailyFreeGamesRibbon;
