import React from 'react';
import { clampNumber } from 'lib/formatters/clampNumber';
import { Typography, TypographyVariant } from 'components/Typography';
import ProgressBar from 'components/ProgressBar';
import './invitedProgressBar.css';

interface InvitedProgressBarProps {
  current: number;
  total: number;
}

const InvitedProgressBar = ({ current, total }: InvitedProgressBarProps) => {
  const percentage = (() => {
    // total can be 0 which means the total is infinite
    // this check also avoids division by 0
    if (total > 0) return (current / total) * 100;
    // when total is infinite, as long as the current is above 0
    // show the progress bar as maxed out
    return current > 0 ? 100 : 0;
  })();
  const currentClamped = total > 0 ? clampNumber(current, 0, total) : current;

  return (
    <ProgressBar
      shouldAnimate={percentage !== 100}
      className="invitedProgressBar"
      percentage={percentage}
      suffix={
        <Typography
          variant={TypographyVariant.BodySmStrong}
          tabletVariant={TypographyVariant.BodyMdStrong}
        >
          <span className="invitedProgressBar__count">
            {currentClamped}
            {total > 0 && `/${total}`}
          </span>
        </Typography>
      }
    />
  );
};

export default InvitedProgressBar;
