import type { JSXElementConstructor } from 'react';
import React, { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, useLocation } from 'react-router-dom';
import cc from 'classcat';
import type { AppDispatch, RootState } from 'src/store';
import { applyContainerQuery } from 'lib/react-container-query';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import ThemeLogo from 'components/ThemeLogo';
import { CLASS_NAME_IGNORE_CLICK_OUTSIDE } from 'components/ClickOutside';
import { AttentionCircleIcon } from 'components/IconsLegacy';
import Api from 'services/Api';
import type { ApiOptions } from 'services/Api';
import type { Styles } from 'components/LazyStyle';
import withLazyStyle from 'components/LazyStyle';
import usePrevious from 'hooks/usePrevious';
import Balance from 'components/Balance';
import TopTabBar from 'components/TopTabBar';
import ButtonMenuWrap from './ButtonMenuWrap';
import style from './mainHead.css?lazy';

const query = {
  'max-height-48': { maxHeight: 48 }
};
const REWARD_TIME = 60_000;

interface NavbarProps extends PropsFromRedux {
  containerQuery: Record<string, string>;
  requiresVerification: boolean;
}

const Navbar = ({
  bingoRoomName = null,
  slotRoomName = null,
  roomLogo = null,
  requiresVerification,
  verificationStatus,
  toggleSideMenu,
  toggleUserMenu,
  avatar,
  containerQuery,
  getRewardsCount,
  rewardsCount = 0,
  username = null
}: NavbarProps) => {
  const { pathname, search } = useLocation();
  const locationKey = pathname + search;
  const roomName = bingoRoomName || slotRoomName;
  const headerClass = cc(['mainHead-wrap', containerQuery]);
  const isLobby = locationKey.includes('/secure/lobby');
  const prevUsername = usePrevious(username);
  const prevLocationKey = usePrevious(locationKey);

  useEffect(() => {
    if (prevUsername !== username || prevLocationKey !== locationKey) void getRewardsCount();
  }, [username, locationKey, prevUsername, prevLocationKey, getRewardsCount]);

  useEffect(() => {
    if (username) void getRewardsCount();
    const rewardInterval = setInterval(() => {
      if (username) void getRewardsCount({ skipSession: true });
    }, REWARD_TIME);

    return () => {
      clearInterval(rewardInterval);
    };
  }, [username, getRewardsCount]);

  return (
    <div className={headerClass}>
      <header id="mainHead" className="lobby">
        <section className="mainHeadContent">
          <section id="menuTrigger">
            <ButtonMenuWrap>
              <Button
                tag="button"
                className="button--iconButton button--quaternary buttonMenu
                ignore-react-onclickoutside"
                type="button"
                name="toggleSideMenu"
                onClick={toggleSideMenu}
              >
                <span className="buttonIcon icon" />
              </Button>
            </ButtonMenuWrap>
            <span className="roomTitle">{roomName}</span>
            {roomLogo && <div className="roomLogo" />}
          </section>
          <nav id="navBar" className="navbar">
            {!isLobby && <TopTabBar isLobby={isLobby} />}
          </nav>
          <section id="logo">
            <span>{roomName}</span>
            <Link to="/secure/lobby" title="Back Home">
              <ThemeLogo />
            </Link>
          </section>
          <section id="login">
            <Balance />
            <button
              className={CLASS_NAME_IGNORE_CLICK_OUTSIDE}
              id="umTrigger"
              onClick={toggleUserMenu}
              data-test-id="header-user-avatar"
              type="submit"
            >
              <Avatar
                image={avatar}
                id="rightUserAvatar"
                icon={
                  requiresVerification &&
                  verificationStatus === User.constants.VerificationStatus.REQUESTED ? (
                    <AttentionCircleIcon color="var(--danger500)" />
                  ) : null
                }
              />
            </button>
          </section>
        </section>
      </header>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  avatar: state.User.avatar,
  bingoRoomName: state.Bingo.roomName,
  slotRoomName: state.Slot.roomName,
  roomLogo: state.Slot.roomLogo,
  rewardsCount: state.User.rewardsCount,
  requiresVerification: User.selectors.getRequiresVerification(state),
  verificationStatus: User.selectors.getVerificationStatus(state),
  username: state.User.username
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getRewardsCount: (options?: ApiOptions) =>
    Api.actions.user.getRewardsCount(undefined, undefined, options)(dispatch),
  toggleSideMenu: () => dispatch(Modals.actions.toggle('sideMenu')),
  toggleUserMenu: () => dispatch(Modals.actions.toggle('userMenu'))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  connector,
  withLazyStyle(style as unknown as Styles),
  applyContainerQuery
)(Navbar, query) as JSXElementConstructor<Record<string, never>>;
