import { type MarketingPreferencesFormState } from './types';

export enum MarketingPreferencesFormVariant {
  BINGO = 'BINGO',
  CASINO = 'CASINO'
}
export const MarketingPreferencesFormValues = {
  email: false,
  phoneCall: false,
  sms: false,
  post: false
};

export const initialMarketingPreferencesFormState: MarketingPreferencesFormState = {
  receiveAll: false,
  BINGO: MarketingPreferencesFormValues,
  CASINO: MarketingPreferencesFormValues
};
