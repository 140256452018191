import type { FluxStandardAction } from 'redux-promise-middleware';
import ky from 'ky';
import type { EffectTypes as Types } from 'services/Effects';
import type { TrackedProfile } from './types';
import { AT, T } from './actionTypes';

const isDev = __ENV__.NODE_ENV === 'development';
type Payload<T extends FluxStandardAction> = Pick<T, 'payload'>[keyof Pick<T, 'payload'>];

export const registrationStarted = (affiliate: any) => ({
  type: T.REGISTRATION_STARTED,
  payload: affiliate
});

export const registrationSuccess = (affiliate: any) => ({
  type: T.REGISTRATION_SUCCESS,
  payload: affiliate
});

export const trackLogin = (user: any) => ({
  type: T.LOGIN,
  payload: user
});

export const trackProfile = (user: TrackedProfile) => ({
  type: T.PROFILE,
  payload: user
});

export const trackLogout = (user: any) => ({
  type: T.LOGGED_OUT,
  payload: user
});

export const reportError = (
  providerRef: string,
  errorMessage: string,
  errorCode?: string | number
) => ({
  type: T.REPORT_ERROR,
  payload: { providerRef, errorMessage, errorCode }
});

export const trackEvent = (eventType: any) => ({
  type: T.TRACK_EVENT,
  payload: eventType
});

/**
 * https://www.docs.developers.amplitude.com/experiment/general/experiment-event-tracking/#exposure-event-definition
 */
export const exposeExperiment = (payload: Payload<Types.ExperimentExposure>) => ({
  type: T.EXPOSE_EXPERIMENT,
  payload
});

export const gameLaunched = (payload: Payload<Types.GameLaunched>) => ({
  type: T.GAME_LAUNCHED,
  payload
});

export const modalOpened = (payload: Payload<Types.ModalOpened>) => ({
  type: T.MODAL_OPENED,
  payload
});

export const modalClosed = (payload: Payload<Types.ModalClosed>) => ({
  type: T.MODAL_CLOSED,
  payload
});

export const sectionScrolled = (payload: Payload<Types.SectionScrolled>) => ({
  type: T.SECTION_SCROLLED,
  payload
});

export const offerClicked = (payload: Payload<Types.OfferClicked>) => ({
  type: T.OFFER_CLICKED,
  payload
});

export const rewardCarouselRetried = (payload: Payload<Types.RewardCarouselRetried>) => ({
  type: T.REWARD_CAROUSEL_RETRIED,
  payload
});

export const addressInputModeChanged = (payload: Payload<Types.AddressInputModeChanged>) => ({
  type: T.ADDRESS_INPUT_MODE_CHANGED,
  payload
});

export const addressDropdownExpanded = () => ({
  type: T.ADDRESS_EXPANDED
});

export const addressDropdownOptionSelected = (payload: Payload<Types.AddressOptionSelected>) => ({
  type: T.ADDRESS_OPTION_SELECTED,
  payload
});

const _refreshExperiment = () => {
  const refreshEndpoint = isDev
    ? `/workers/experiment?evaluate__dev__=true&url=${encodeURIComponent(window.location.href)}`
    : '/workers/experiment/refresh';
  return ky(refreshEndpoint, {
    credentials: 'include',
    method: 'HEAD'
  });
};

export const refreshExperimentFlags = () => ({
  type: AT.REFRESH_EXPERIMENT._,
  payload: _refreshExperiment()
});
