import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  GET_CURRENT: ASYNC,
  COOL_OFF: ASYNC,
  SELF_EXCLUDE: ASYNC,
  CHANGE_AVATAR: ASYNC,
  CHANGE_ADDRESS: ASYNC,
  CHANGE_EMAIL: ASYNC,
  CHANGE_GAME_SOUNDS: ASYNC,
  EDIT: ASYNC,
  SMS_VERIFICATION_STATE: ASYNC,
  VERIFY_SMS: ASYNC,
  SEND_SMS_VERIFICATION: ASYNC,
  CHANGE_MARKETING_PREFERENCE: ASYNC,
  CHANGE_MARKETING_PREFERENCE_EMAIL: ASYNC,
  CHANGE_MARKETING_PREFERENCE_SMS: ASYNC,
  CHANGE_MARKETING_PREFERENCE_POST: ASYNC,
  CHANGE_MARKETING_PREFERENCE_CALLS: ASYNC,
  UPDATE_MOBILE: ASYNC,
  SET_REALITY_CHECK_INTERVAL: ASYNC,
  GET_REFERRAL_STATUS: ASYNC,
  GET_SETTINGS: ASYNC,
  OCCUPATION: ASYNC,
  KYC: ASYNC,
  GNATTA_PROFILE: ASYNC,
  GET_FREE_ROUNDS: ASYNC,
  GET_REWARDS: ASYNC,
  GET_DAILY_FREE_GAMES: ASYNC,
  NET_DEPOSITS: ASYNC,
  NET_BETS: ASYNC,
  GET_REWARDS_COUNT: ASYNC,
  GET_REWARD_OFFERS: ASYNC,
  GET_CHAT_AVAILABILITY: ASYNC,
  GET_FEEDBACK: ASYNC,
  CREATE_DOC_SCAN_SESSION: ASYNC,
  FINISH_DOC_SCAN_SESSION: ASYNC,
  CREATE_HOOYU_SESSION: ASYNC,
  CREATE_HOOYU_REQUIREMENT_ID_SESSION: ASYNC,
  KYC_VERIFICATION: ASYNC,
  KYC_VERIFICATION_DOCUMENTS: ASYNC,
  SESSION_STATS: ASYNC,
  AGREE_LATEST_TERMS: ASYNC
});

export const T = namespace({
  TOGGLE_SHOW_BALANCE: SYNC,
  SET_MOBILE: SYNC,
  SET_REALITY_TIMEOUT_ID: SYNC,
  SET_REALITY_INTERVAL_ID: SYNC,
  UNSET_MOBILE: SYNC,
  UPDATE_AVATAR_NOTIFICATION: SYNC,
  SET_RETURNING_PLAYER: SYNC,
  CLEAR_RETURNING_PLAYER: SYNC,
  SET_LIVE_CHAT_UNREAD_COUNT: SYNC,
  SET_LIVE_CHAT_LAUNCHER_VISIBLE: SYNC,
  SET_NOT_AGREED_TO_LATEST_TERMS: SYNC
});
// export const CHANGE_AVATAR = `${NAME}/CHANGE_AVATAR`;
// export const CHANGE_THEME = `${NAME}/CHANGE_THEME`;
// export const CHANGE_VOICE_GENDER = `${NAME}/CHANGE_VOICE_GENDER`;
// export const CHANGE_EMAIL = `${NAME}/CHANGE_EMAIL`;
// export const EDIT = `${NAME}/EDIT`;
// export const EDIT_PASSWORD = `${NAME}/EDIT_PASSWORD`;
// export const ENABLE_GAME_SOUNDS = `${NAME}/ENABLE_GAME_SOUNDS`;
// export const ENABLE_STATEFUL_CHAT = `${NAME}/ENABLE_STATEFUL_CHAT`;
// export const ENABLE_STICKY_CHAT = `${NAME}/ENABLE_STICKY_CHAT`;
// export const GET_SETTINGS = `${NAME}/GET_SETTINGS`;
// export const GET_THEME = `${NAME}/GET_THEME`;
// export const GET_ALL = `${NAME}/GET_ALL`;
// export const GET_AVATAR = `${NAME}/GET_AVATAR`;
// export const GET_CURRENT = `${NAME}/GET_CURRENT`;
// export const SEARCH = `${NAME}/SEARCH`;
// export const SEND_ACCOUNT_ACTIVATION_EMAIL = `${NAME}/SEND_ACCOUNT_ACTIVATION_EMAIL`;
// export const SET_MARKETING_PREFERENCE = `${NAME}/SET_MARKETING_PREFERENCE`;
// export const VERIFY_POSTCODE = `${NAME}/VERIFY_POSTCODE`;
