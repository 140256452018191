import React from 'react';
import type { paymentMethodType } from 'lib/constants';
import { useAppSelector } from 'hooks/redux';
import Wallet from 'modules/Wallet';
import SuggestedAmounts from './SuggestedAmounts';

interface SuggestedAmountsAdapterProps {
  paymentMethodType: keyof typeof paymentMethodType;
  value: number | string;
  disabled: boolean;
  onChange: (value: number) => void;
}

const SuggestedAmountsAdapter = ({
  paymentMethodType,
  value,
  disabled,
  onChange
}: SuggestedAmountsAdapterProps) => {
  // We need to parse the value to a number because the user can enter a custom amount,
  // and we want to preselect the suggested amount that matches the value.
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  const values = useAppSelector(
    (s) =>
      Wallet.selectors.getSuggestedDepositAmountsForPaymentType(s, paymentMethodType) as number[]
  );

  return (
    <SuggestedAmounts onChange={onChange} values={values} value={parsedValue} disabled={disabled} />
  );
};

export default SuggestedAmountsAdapter;
