import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import InvitedProgressBar from '../InvitedProgressBar';
import './completedOfferCard.css';

interface CompletedOfferCardProps {
  freeRounds: number;
  freeRoundsGameName: string;
  maxInvitedCount: number;
}

const CompletedOfferCard = ({
  freeRounds,
  freeRoundsGameName,
  maxInvitedCount
}: CompletedOfferCardProps) => (
  <>
    <Typography
      variant={TypographyVariant.BodySmStrong}
      tabletVariant={TypographyVariant.BodyMdStrong}
    >
      <span>{freeRounds} spins earned</span>
    </Typography>

    <InvitedProgressBar current={maxInvitedCount} total={maxInvitedCount} />

    <div className="completedOfferCard__bodyFooter">
      <Typography
        variant={TypographyVariant.BodyXsStrong}
        tabletVariant={TypographyVariant.BodySmStrong}
      >
        <span className="completedOfferCard__gameNameHighlight">On {freeRoundsGameName}</span>
      </Typography>
    </div>
  </>
);

export default CompletedOfferCard;
