import React from 'react';
import * as Animation from 'components/Animation';
import './tileMediaCardSkeleton.css';

interface TileMediaCardSkeletonProps {
  children?: React.ReactNode;
  isDarkVariant?: boolean;
}

const TileMediaCardSkeleton = ({ children, isDarkVariant }: TileMediaCardSkeletonProps) => {
  const Shimmer = isDarkVariant ? Animation.ShimmerInputSecondary : Animation.ShimmerInputPrimary;
  return (
    <div className="tileMediaCardSkeleton">
      <Animation.Root className="tileMediaCardSkeleton__tile">
        <Shimmer />
      </Animation.Root>
      <div className="tileMediaCardSkeleton__content">
        {children || (
          <>
            <Animation.Root className="tileMediaCardSkeleton__title">
              <Shimmer />
            </Animation.Root>
            <Animation.Root className="tileMediaCardSkeleton__textLine">
              <Shimmer />
            </Animation.Root>
          </>
        )}
      </div>
    </div>
  );
};

export default TileMediaCardSkeleton;
