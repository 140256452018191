import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import Button from 'components/Button';
import InvitedProgressBar from '../InvitedProgressBar';
import InvitedProgressPips from '../InvitedProgressPips';
import './activeOfferCard.css';

interface ActiveOfferCardProps {
  freeRounds: number;
  invitedCount: number;
  maxInvitedCount: number;
  isClaimable?: boolean;
}

const ActiveOfferCard = ({
  freeRounds,
  invitedCount,
  maxInvitedCount,
  isClaimable
}: ActiveOfferCardProps) => (
  <div className="activeOfferCard">
    <div className="activeOfferCard__bodyDetails">
      <Typography variant={TypographyVariant.BodySm} tabletVariant={TypographyVariant.BodyMd}>
        <span>
          <Typography
            variant={TypographyVariant.BodySmStrong}
            tabletVariant={TypographyVariant.BodyMdStrong}
          >
            <em className="activeOfferCard__freeSpinHighlight">{freeRounds} spins</em>
          </Typography>{' '}
          for each friend
        </span>
      </Typography>

      <InvitedProgressPips current={invitedCount} total={maxInvitedCount} />
    </div>

    <InvitedProgressBar current={invitedCount} total={maxInvitedCount} />

    <div className="activeOfferCard__bodyFooter">
      <Typography variant={TypographyVariant.BodyXs} tabletVariant={TypographyVariant.BodySm}>
        <span className="activeOfferCard__smallText">Unlock next level for spins!</span>
      </Typography>

      {isClaimable && (
        <Button
          className="activeOfferCard__bodyButton button__smallest"
          name="getSpins"
          buttonText="Get spins!"
          tag="Link"
          href="secure/lobby/rewards"
          icon="/assets/images1/referAFriend/tada-white.svg"
        />
      )}
    </div>
  </div>
);

export default ActiveOfferCard;
