import React, { PureComponent } from 'react';
import { compose } from 'redux';
import cc from 'classcat';
import SimpleSelect from 'components/SimpleSelect';
import reform from 'components/Reform';
import Button from 'components/Button';
import FormError from 'components/FormError';
import { experiments, getExperimentData } from 'lib/analytics';
import {
  DAILY_AMOUNTS,
  MONTHLY_AMOUNTS,
  WEEKLY_AMOUNTS,
  amountFormatter,
  DEPOSIT_LIMIT_INTERVAL
} from 'lib/depositLimit';

const shouldReorderSpendLimitsExperiment = getExperimentData(experiments.spendLimitsReorder.flag);
const shouldReorderSpendLimits = shouldReorderSpendLimitsExperiment?.isTreatment;

export class _DepositLimitForm extends PureComponent {
  componentDidMount() {
    this.props.exposeExperiment(shouldReorderSpendLimitsExperiment);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { onSubmit, onDone } = this.props;
    const res = await onSubmit(e);
    if (res === true && onDone) {
      onDone();
    }
  };

  render() {
    const {
      depositLimitDaily,
      depositLimitWeekly,
      depositLimitMonthly,
      formValid,
      errorMessage,
      loading,
      handleChange,
      handleFocus
    } = this.props;

    const dailySpendLimitAmounts = shouldReorderSpendLimits
      ? DAILY_AMOUNTS.toReversed()
      : DAILY_AMOUNTS;
    const weeklySpendLimitAmounts = shouldReorderSpendLimits
      ? WEEKLY_AMOUNTS.toReversed()
      : WEEKLY_AMOUNTS;
    const monthlySpendLimitAmounts = shouldReorderSpendLimits
      ? MONTHLY_AMOUNTS.toReversed()
      : MONTHLY_AMOUNTS;

    const dailySimpleSelect = (
      <div className="fieldset fieldset--fullflex">
        <label htmlFor={DEPOSIT_LIMIT_INTERVAL.DAILY}>Daily Spend Limit</label>
        <SimpleSelect
          onChange={handleChange}
          onFocus={handleFocus}
          numberFormatter={amountFormatter}
          options={dailySpendLimitAmounts}
          name={DEPOSIT_LIMIT_INTERVAL.DAILY}
          value={depositLimitDaily.value}
        />
      </div>
    );

    const weeklySimpleSelect = (
      <div className="fieldset fieldset--fullflex">
        <label htmlFor={DEPOSIT_LIMIT_INTERVAL.WEEKLY}>Weekly Spend Limit</label>
        <SimpleSelect
          onChange={handleChange}
          onFocus={handleFocus}
          numberFormatter={amountFormatter}
          options={weeklySpendLimitAmounts}
          name={DEPOSIT_LIMIT_INTERVAL.WEEKLY}
          value={depositLimitWeekly.value}
        />
      </div>
    );
    const monthlySimpleSelect = (
      <div className="fieldset fieldset--fullflex">
        <label htmlFor={DEPOSIT_LIMIT_INTERVAL.MONTHLY}>Monthly Spend Limit</label>
        <SimpleSelect
          onChange={handleChange}
          onFocus={handleFocus}
          numberFormatter={amountFormatter}
          options={monthlySpendLimitAmounts}
          name={DEPOSIT_LIMIT_INTERVAL.MONTHLY}
          value={depositLimitMonthly.value}
        />
      </div>
    );

    return (
      <form onSubmit={this.handleSubmit} className="form depositLimitForm">
        {shouldReorderSpendLimits ? (
          <>
            {monthlySimpleSelect} {weeklySimpleSelect} {dailySimpleSelect}
          </>
        ) : (
          <>
            {dailySimpleSelect} {weeklySimpleSelect} {monthlySimpleSelect}
          </>
        )}

        <div className="fieldset fieldset--centerflex">
          <Button
            disabled={!formValid}
            loading={loading}
            title="Save Spend Limits"
            className={cc(['button--primary', 'button__grey', 'button--fullWidth'])}
            name="saveSpendLimits"
            buttonText="Save Spend Limits"
            type="submit"
          />
        </div>
        <FormError errorMessage={errorMessage} />
      </form>
    );
  }
}

export default compose(reform())(_DepositLimitForm);
