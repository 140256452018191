import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cc from 'classcat';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import Iframe from 'components/Iframe';
import User, { constants as UserConstants } from 'modules/User';
import isValidHooyuUrl from 'lib/validateHooyuUrl';
import { useQuery } from 'hooks/useQuery';
import Api from 'services/Api';
import { useAppDispatch } from 'hooks/redux';
import useUnmount from 'hooks/useUnmount';
import './hooyu.css';

const DEFAULT_SIZE = {
  height: 667
};

const HooyuIframe = ({ close, journey }) => {
  const query = useQuery();
  const queryLink = decodeURIComponent(query.get('link'));
  const link = useSelector((state) => User.selectors.model(state).hooyuLink);
  const isValidLink = isValidHooyuUrl(link) || isValidHooyuUrl(queryLink);
  const [height, setHeight] = useState(`${DEFAULT_SIZE.height}px`);
  const [selfieMode, setSelfieMode] = useState(false);
  const dispatch = useAppDispatch();

  const handleReceiveMessage = useCallback(
    ({ origin, data }) => {
      if (origin === window.location.origin && data === 'hooyuSuccess') {
        close();
      }
      if (origin === window.location.origin && data === 'hooyuError') {
        Api.actions.auth.logout(null, new FormData())(dispatch);
      }
      if (origin === 'https://www.hooyu.com') {
        if (data.height) {
          setHeight(parseInt(data.height, 10));
        }
        setSelfieMode(data.selfie);
      }
    },
    [close, dispatch]
  );

  useEffect(() => {
    if (!isValidLink) {
      close();
    }
  }, [isValidLink, close]);

  const onUnmount = useCallback(() => {
    if (journey === UserConstants.VerificationType.SDD) {
      dispatch(Api.actions.user.kycVerification());
    } else if (journey === UserConstants.VerificationType.EDD) {
      dispatch(Api.actions.kyc.verificationDocuments());
    }
  }, [dispatch, journey]);

  useUnmount(onUnmount);

  return (
    <UserBox
      className={cc(['hooyu', 'modal', selfieMode && 'hooyu--large'])}
      title="Verify with Mitek"
      closeCallback={close}
    >
      <div className="hooyu__wrapper">
        {isValidLink ? (
          <Iframe
            title="Hooyu"
            src={link || queryLink}
            width="100%"
            height={height}
            frameBorder="0"
            allow="geolocation; camera"
            onReceiveMessage={handleReceiveMessage}
          />
        ) : null}
      </div>
    </UserBox>
  );
};

HooyuIframe.propTypes = {
  close: PropTypes.func.isRequired
};

export default makeModal(
  'hooyu',
  { name: 'modal-fade', timeout: 200 },
  { id: 'hooyu', className: 'hooyu modal' }
)(HooyuIframe);
