import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cc from 'classcat';
import { isEmpty } from 'lodash';
import Button from 'components/Button';
import RouterLink from 'components/RouterLink';
import { openLiveChat } from 'components/LiveChat';
import Modals from 'modules/Modals';
import { CLASS_NAME_IGNORE_CLICK_OUTSIDE } from 'components/ClickOutside';
import { supportEmailLink } from 'lib/constants';
import './notifications.css';

const regex = /{{2}(.*?)}(.*?)}/g;

const matches = (str) => {
  const result = {};
  let m;

  // eslint-disable-next-line no-cond-assign -- TODO: Please refactor https://mrq.atlassian.net/browse/MRQ-2231
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    const current = [];
    m.forEach((match) => {
      current.push(match);
    });
    const [head, ...tail] = current;
    result[head] = tail;
  }

  return result;
};

const parseMessage = (message, props) => {
  const matchGroup = matches(message);
  if (isEmpty(matchGroup)) return message;

  // TODO: Can easily be made to replace all (loop matchGroup)
  const key = Object.keys(matchGroup)[0];
  const text = matchGroup[key][0];
  const action = matchGroup[key][1];
  const splitText = message.split(key);
  const matchText = message.match(key);
  if (splitText.length <= 1) {
    return text;
  }

  return splitText.reduce(
    (arr, element, index) =>
      matchText[index]
        ? [
            ...arr,
            element,
            <a className="underline" key={action} onClick={props[action]}>
              {text}
            </a>
          ]
        : [...arr, element],
    []
  );
};

const onClickLiveChatLink = () => {
  openLiveChat('notification');
};

export const _Notification = ({
  title,
  message,
  severity,
  dismissible,
  isHtml,
  close,
  prompt,
  contactSupport,
  handleMouseEnter,
  handleMouseLeave,
  handleClick,
  ...rest
}) => (
  <div
    className={`notification ${CLASS_NAME_IGNORE_CLICK_OUTSIDE} notification--${severity}${
      prompt ? ' notification--with-prompt' : ''
    }`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <div className="notification__icon">
      <img src={`/assets/images1/notification-${severity}.svg`} alt="" />
    </div>
    <div className="notificationContent">
      {title ? (
        <span className="notificationContent__title" onClick={dismissible ? close : null}>
          {title}
        </span>
      ) : null}
      {isHtml ? (
        <div
          className="notificationContent__message"
          /* eslint-disable-next-line react/no-danger --
          The message will always come from our backend, and it's safe to set as HTML */
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        <div className="notificationContent__message util-paragraph">
          {parseMessage(message, rest)}
          {contactSupport ? (
            <>
              {' '}
              If you have any questions please{' '}
              {__ENV__.GNATTA_API_KEY ? (
                <a onClick={onClickLiveChatLink} className="underline">
                  contact us
                </a>
              ) : (
                <RouterLink className="underline" href={supportEmailLink}>
                  contact us
                </RouterLink>
              )}
              .
            </>
          ) : null}
        </div>
      )}
    </div>
    {dismissible ? (
      <img
        tabIndex={-1}
        onFocus={close}
        onClick={close}
        alt="close"
        src="/assets/images1/close-secondary.svg"
        className="notification__close"
      />
    ) : null}
    {prompt ? (
      <Button
        buttonText={prompt[0]}
        name="notificationPrompt"
        className={cc([
          'notification__action',
          {
            white: false, // TODO: Really consider this option! Neutral color makes sense @GeKorm
            red: severity === 3
          }
        ])}
        onClick={handleClick(prompt[1])}
      />
    ) : null}
  </div>
);

_Notification.propTypes = {
  prompt: PropTypes.array,
  title: PropTypes.string,
  message: PropTypes.string,
  severity: PropTypes.oneOf([1, 2, 3, 4]),
  dismissible: PropTypes.bool,
  isHtml: PropTypes.bool,
  contactSupport: PropTypes.bool,
  close: PropTypes.func,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired
};

_Notification.defaultProps = {
  prompt: null,
  title: null,
  message: '',
  severity: 1,
  dismissible: true,
  isHtml: false,
  contactSupport: false,
  close: null
};

const mapDispatchToProps = (dispatch) => ({
  handleClick: (action) => () => dispatch(action),
  openVerifyYourAccount: () => dispatch(Modals.actions.open('verifyYourAccount')),
  openAddPayment: () => dispatch(Modals.actions.open('addPayment')),
  openVerifyDocuments: () => dispatch(Modals.actions.open('verifyDocuments'))
});

export default connect(null, mapDispatchToProps)(_Notification);
