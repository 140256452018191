import React from 'react';
import { constants } from 'modules/User';
import './rewardCardStatusMarker.css';

interface StatusMarkerProps {
  rewardStatus: constants.RewardStatus;
}

const RewardCardStatusMarker = ({ rewardStatus }: StatusMarkerProps) => {
  if (rewardStatus === constants.RewardStatus.Playable) {
    return null;
  }

  return (
    <div className="rewardCardStatusMarker">
      <img src="/assets/images1/rewards/lockpad-closed.svg" alt="Locked" />
    </div>
  );
};

export default RewardCardStatusMarker;
