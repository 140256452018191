import React from 'react';
import cc from 'classcat';
import './inviteTileIcon.css';

interface InviteTileIconProps {
  isCompleted?: boolean;
  className?: string;
}

const InviteTileIcon = ({ isCompleted, className }: InviteTileIconProps) => (
  <div
    className={cc([
      'inviteTileIcon',
      className,
      {
        'inviteTileIcon--completed': isCompleted
      }
    ])}
  >
    <svg className="inviteTileIcon__icon">
      {isCompleted ? (
        <use href="/assets/images1/referAFriend/check.svg#icon" />
      ) : (
        <use href="/assets/images1/referAFriend/lock.svg#icon" />
      )}
    </svg>
  </div>
);

export default InviteTileIcon;
