import React, { useEffect } from 'react';
import Offers from 'modules/Offers';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import User from 'modules/User';
import Wallet from 'modules/Wallet';
import OfferPromoBanner from 'components/OfferPromoBanner';
import './depositOfferPromoBanner.css';

const DepositOfferPromoBanner = () => {
  const dispatch = useAppDispatch();
  const affiliate = useAppSelector(User.selectors.getAffiliate);
  const promoCode = useAppSelector(User.selectors.getRegistrationPromoCode);
  const offers = useAppSelector((state) => Offers.selectors.getOffersByName(state, 'registration'));
  const isPromoCodeDisabled = useAppSelector(User.selectors.getIsPromoCodeDisabled);
  const hasDeposited = useAppSelector(Wallet.selectors.getHasDeposited);
  const isWelcomeOfferVisible =
    !isPromoCodeDisabled && !hasDeposited && offers && offers.length > 0;

  useEffect(() => {
    void Offers.actions.registrationOffer({
      promoCode,
      // The promoCode has a higher priority than the affiliateId
      affiliateId: promoCode ? '' : affiliate?.affid
    })(dispatch);
  }, [dispatch, promoCode, affiliate]);

  if (!isWelcomeOfferVisible) {
    return null;
  }

  return (
    <form className="depositOfferPromoBanner">
      <OfferPromoBanner offer={offers[0]} />
    </form>
  );
};

export default DepositOfferPromoBanner;
