import React, { memo } from 'react';
import cc from 'classcat';
import './progressBar.css';

interface ProgressBarProps {
  percentage?: number;
  shouldAnimate?: boolean;
  className?: string;
  suffix?: JSX.Element | null;
}

const ProgressBar = ({ percentage = 0, shouldAnimate, className, suffix }: ProgressBarProps) => (
  <div
    className={cc([
      'progressBar',
      className,
      {
        'progressBar--animate': shouldAnimate
      }
    ])}
  >
    <div className="progressBar__bar">
      <div style={{ width: `${percentage}%` }} className="progressBar__fill" />
    </div>
    {suffix}
  </div>
);

export default memo(ProgressBar);
