import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { findKey, isEmpty, omit, flowRight, uniq, some, isNil } from 'lodash';
import { nanoid } from 'nanoid';
import Socket from 'services/Socket';
import module from 'lib/module';
import { paymentMethodType } from 'lib/constants';
import { actionTypes as userActionTypes } from 'modules/User';
import { getNamespace } from 'lib/redux-utils';
import {
  NAME,
  DELETE_PAYPAL,
  DEPOSIT_LIMIT_SOLICITATIONS_REQUESTS_STATE as DLS_REQUESTS_STATE
} from './constants';
import { AT, T } from './actionTypes';
import { initialState } from './model';
import { getMobilePaymentRef } from './selectors';

export const getPrimaryMethodRef = (state) => {
  const {
    paymentMethods: { entities, result }
  } = state;
  return result?.find((ref) => entities[ref].primary);
};

const updateBalance = (state, action) =>
  update(state, {
    balance: { $set: action.payload && (action.payload.value || action.payload) },
    lastTransactionType: { $set: (action.payload && action.payload.transactionType) || '' }
  });

const overrideBalance = (state, action) =>
  update(state, {
    balanceOverride: { $set: action.payload }
  });

const cancelOverrideBalance = (state) => update(state, { $unset: 'balanceOverride' });

const updatePaymentMethods = (state, { payload }) => {
  if (!payload.entities) return state;

  const requiresReverification = some(
    payload.result.map((id) => payload.entities[id]),
    { requiresReverification: true }
  );

  return update(state, {
    paymentMethods: {
      entities: Object.keys(payload.entities).reduce((acc, cur) => {
        acc[cur] = { $merge: omit(payload.entities[cur], ['allowed']) };
        return acc;
      }, {}),
      result: { $set: payload.result }
    },
    requiresReverification: { $set: requiresReverification },
    cardErrorMessage: { $set: '' },
    cardErrorCount: { $set: 0 }
  });
};

const updatePaymentMethodsDetailed = (state, action) =>
  action.payload.entities
    ? update(state, {
        paymentMethods: {
          $merge: action.payload
        },
        cardErrorMessage: { $set: '' },
        cardErrorCount: { $set: 0 }
      })
    : state;

const updatePaymentProviders = (state, action) =>
  action.payload.entities
    ? update(state, {
        paymentProviders: {
          $merge: action.payload
        }
      })
    : state;

const updatePaymentProvidersAvailable = (state, action) =>
  action.payload.entities
    ? update(state, {
        paymentProvidersAvailable: {
          $merge: action.payload
        }
      })
    : state;

const updateSinglePaymentMethod = (state, action) => {
  const ref = action.payload?.ref || action.payload?.paymentMethodRef;
  const methodExists =
    !isEmpty(state.paymentMethods.result) && state.paymentMethods.result.includes(ref);
  // FIXME: Do not used (memoized) selector in reducer
  const currentDepositMobileNumber = getMobilePaymentRef({ Wallet: state });
  if (methodExists) {
    return update(state, {
      paymentMethods: {
        entities: {
          [ref]: { $merge: action.payload }
        }
      }
    });
  } else if (action.payload?.type === paymentMethodType.MOBILE && currentDepositMobileNumber) {
    const depositMobileNumberIndexOf = state.paymentMethods.result.indexOf(
      currentDepositMobileNumber
    );
    const updater = {
      paymentMethods: {
        entities: {
          [ref]: { $set: action.payload },
          $unset: currentDepositMobileNumber
        },
        result: { $splice: [[depositMobileNumberIndexOf, 1, ref]] }
      }
    };
    return update(state, updater);
  } else if (ref) {
    const isPrimary = action.payload.primary;
    const currentPrimaryRef = getPrimaryMethodRef(state);
    const updater = {
      paymentMethods: {
        entities: {
          [ref]: { $set: action.payload }
        },
        result: { $push: [ref] }
      }
    };

    if (isPrimary && currentPrimaryRef && currentPrimaryRef !== ref) {
      updater.paymentMethods.entities[currentPrimaryRef] = {
        primary: { $set: false }
      };
    }

    return update(state, updater);
  }
  return state;
};

const changePrimary = (state, action) => {
  const ref = action.meta && action.meta.data && action.meta.data.ref;
  const currentPrimaryRef = findKey(state.paymentMethods.entities, 'primary');
  if (ref) {
    return update(state, {
      paymentMethods: {
        entities: {
          $apply: (entities) =>
            update(entities, {
              [currentPrimaryRef]: {
                primary: { $set: false }
              },
              [ref]: {
                primary: { $set: true }
              }
            })
        }
      }
    });
  }
  return state;
};

const removeCard = (state, action) => {
  const ref = action.meta && action.meta.data && action.meta.data.ref;
  if (ref) {
    const index = state.paymentMethods.result.indexOf(ref);
    if (index >= 0) {
      return update(state, {
        paymentMethods: {
          entities: {
            $unset: ref
          },
          result: {
            $splice: [[index, 1]]
          }
        }
      });
    }
  }
  return state;
};

const updateCardsError = (state, action) =>
  update(state, {
    cardErrorCount: { $set: state.cardErrorCount + 1 },
    cardErrorMessage: { $set: action.meta.msg }
  });

const getDepositLimit = (state, action) =>
  action.payload
    ? update(state, {
        depositLimitDaily: { $set: action.payload.depositLimitDaily },
        depositLimitDailyEffectiveAt: { $set: action.payload.depositLimitDailyEffectiveAt },
        depositLimitDailyRequest: { $set: action.payload.depositLimitDailyRequest },
        depositLimitDailyRequestAt: { $set: action.payload.depositLimitDailyRequestAt },
        depositLimitDailySetAt: { $set: action.payload.depositLimitDailySetAt },
        depositLimitMonthly: { $set: action.payload.depositLimitMonthly },
        depositLimitMonthlyEffectiveAt: { $set: action.payload.depositLimitMonthlyEffectiveAt },
        depositLimitMonthlyRequest: { $set: action.payload.depositLimitMonthlyRequest },
        depositLimitMonthlyRequestAt: { $set: action.payload.depositLimitMonthlyRequestAt },
        depositLimitMonthlySetAt: { $set: action.payload.depositLimitMonthlySetAt },
        depositLimitWeekly: { $set: action.payload.depositLimitWeekly },
        depositLimitWeeklyEffectiveAt: { $set: action.payload.depositLimitWeeklyEffectiveAt },
        depositLimitWeeklyRequest: { $set: action.payload.depositLimitWeeklyRequest },
        depositLimitWeeklyRequestAt: { $set: action.payload.depositLimitWeeklyRequestAt },
        depositLimitWeeklySetAt: { $set: action.payload.depositLimitWeeklySetAt }
      })
    : state;

const getTransactionsKey = (action) =>
  action.meta.args.startDate + action.meta.args.endDate + action.meta.args.sort;

const getExpandedTransactionId = (
  result,
  prevTransactions,
  currentExpandedBankTransactionId,
  isInitialRequest
) =>
  (!prevTransactions.length && result.length) || isInitialRequest
    ? result[0]
    : currentExpandedBankTransactionId;

const setOrMergeGameTransactions = (action, prevTransactions, isInitialRequest) => {
  if (!action.payload) return { $set: action.payload };
  if (isInitialRequest) {
    return { $set: action.payload && action.payload.contents };
  }

  return {
    entities: { $merge: action.payload.contents.entities || {} },
    result: {
      $set: prevTransactions
        ? uniq([...prevTransactions, ...action.payload.contents.result])
        : action.payload.contents.result
    }
  };
};

const gameTransactions = (state, action) => {
  // Determines if an action is the initial request
  const isInitialRequest =
    !action?.meta?.args?.cursor || state.gameTransactionsKey !== getTransactionsKey(action);

  return update(state, {
    expandedGameTransactionId: {
      $set: getExpandedTransactionId(
        action.payload.contents.result,
        state.gameTransactions.result,
        state.expandedGameTransactionId,
        isInitialRequest
      )
    },
    gameTransactionsKey: { $set: getTransactionsKey(action) },
    gameTransactionsPagination: { $set: action.payload && action.payload.pagination },
    gameTransactions: setOrMergeGameTransactions(
      action,
      state.gameTransactions.result,
      isInitialRequest
    )
  });
};

const bankTransactions = (state, action) => {
  // Determines if an action is the initial request
  const isInitialRequest =
    !action?.meta?.args?.cursor || state.bankTransactionsKey !== getTransactionsKey(action);

  return update(state, {
    expandedBankTransactionId: {
      $set: getExpandedTransactionId(
        action.payload.contents.result,
        state.bankTransactions.result,
        state.expandedBankTransactionId,
        isInitialRequest
      )
    },
    bankTransactionsKey: { $set: getTransactionsKey(action) },
    bankTransactionsPagination: { $set: action.payload && action.payload.pagination },
    bankTransactions: setOrMergeGameTransactions(
      action,
      state.bankTransactions.result,
      isInitialRequest
    )
  });
};

const bankTransactionsDeposit = (state, action) =>
  update(state, {
    bankTransactionsPaginationDeposit: { $set: action.payload && action.payload.pagination },
    bankTransactionsDeposit: { $set: action.payload && action.payload.contents }
  });

const setHasDeposit = (state, action) => update(state, { hasDeposited: { $set: action.payload } });

const deposit = (state, action) => {
  const isPrimary = action.payload.primary;
  const depositMethodRef = action.payload.paymentMethodRef;
  const currentPrimaryRef = getPrimaryMethodRef(state);
  const updater = {
    bankTransactions: {
      entities: {
        [action.payload.id]: { $merge: action.payload }
      },
      result: { $unshift: [action.payload.id] }
    },
    hasDeposited: { $set: true }
  };
  if (isPrimary && currentPrimaryRef !== depositMethodRef) {
    updater.paymentMethods = {
      entities: {
        [depositMethodRef]: {
          primary: { $set: true }
        }
      }
    };
    if (currentPrimaryRef) {
      updater.paymentMethods.entities[currentPrimaryRef] = {
        primary: { $set: false }
      };
    }
  }
  return action.payload && action.payload.id ? update(state, updater) : state;
};

const rejectPaypalDeposit = (state, { meta }) => {
  const ref = meta.data.paymentMethodRef;
  const index = state.paymentMethods.result?.indexOf(ref);
  return meta?.code === DELETE_PAYPAL && index >= 0
    ? update(state, {
        paymentMethods: {
          entities: {
            $unset: [ref]
          },
          result: {
            $splice: [[index, 1]]
          }
        }
      })
    : state;
};

const rejectPaypalCreation = (state) =>
  update(state, {
    dropinKey: { $set: nanoid() }
  });

const withdraw = (state, action) =>
  action.payload && action.payload.id
    ? update(state, {
        bankTransactions: {
          entities: {
            [action.payload.id]: { $merge: action.payload }
          },
          result: { $unshift: [action.payload.id] }
        },
        totalAmountPending: {
          $set: state.totalAmountPending + action.payload.amount
        }
      })
    : state;

const withdrawCancel = (state, action) =>
  action.meta &&
  action.meta.data &&
  action.meta.data.id &&
  state.bankTransactions.result.includes(action.meta.data.id)
    ? update(state, {
        bankTransactions: {
          entities: { $unset: action.meta.data.id },
          result: { $splice: [[state.bankTransactions.result.indexOf(action.meta.data.id), 1]] }
        },
        selectedTransactionsRefs: {
          $splice: [[state.selectedTransactionsRefs.indexOf(action.meta.data.id), 1]]
        }
      })
    : state;

const cancelMultipleWithdraws = (state, action) =>
  action.meta && action.meta.data && action.meta.data.ids
    ? update(state, {
        bankTransactionsDeposit: {
          entities: { $unset: action.meta.data.ids },
          result: {
            $apply: (currentValue) =>
              currentValue && currentValue.filter((value) => !action.meta.data.ids.includes(value))
          }
        },
        selectedTransactionsRefsDeposit: {
          $apply: (currentValue) =>
            currentValue && currentValue.filter((value) => !action.meta.data.ids.includes(value))
        },
        bankTransactions: {
          entities: { $unset: action.meta.data.ids },
          result: {
            $apply: (currentValue) =>
              currentValue && currentValue.filter((value) => !action.meta.data.ids.includes(value))
          }
        },
        selectedTransactionsRefs: {
          $apply: (currentValue) =>
            currentValue && currentValue.filter((value) => !action.meta.data.ids.includes(value))
        }
      })
    : state;

const updateGlobalJackpot = (state, action) =>
  update(state, {
    globalJackpotAmount: { $set: action.payload.amount || action.payload[0].amount }
  });

const setAgreedTerms = (state) =>
  update(state, {
    agreedPaymentTerms: { $set: true }
  });

const setPciProxyError = (state, action) =>
  update(state, {
    pciProxyError: { $set: action.payload }
  });

const setCurrentPci3dChallengeTransactionId = (state, action) =>
  update(state, {
    currentPci3dChallengeTransactionId: { $set: action.payload }
  });

const setHasOpenLoopWithdrawalStarted = (state, action) =>
  update(state, {
    hasOpenLoopWithdrawalStarted: { $set: action.payload }
  });

const gotAgreeTerms = (state, action) =>
  update(state, {
    agreedPaymentTerms: { $set: action.payload.flag }
  });

const getVerification = (state, action) =>
  update(state, {
    $merge: {
      canAttemptVerification: action.payload.canAttemptVerification,
      canSendVerification: action.payload.canSendVerification,
      requiresReverification: action.payload.requiresReverification,
      phoneNumber: action.payload.phoneNumber,
      phoneVerified: action.payload.verified
    }
  });

const setVerification = (state) =>
  update(state, {
    $merge: {
      canAttemptVerification: false,
      canSendVerification: false,
      phoneVerified: true,
      requiresReverification: false
    }
  });

const resetVerification = (state) =>
  update(state, {
    $unset: ['canAttemptVerification', 'canSendVerification']
  });

const selectTransactions = (state, action) =>
  update(state, { selectedTransactionsRefs: { $push: action.payload } });

const selectAllTransactions = (state, action) =>
  update(state, { selectedTransactionsRefs: { $set: action.payload } });

const deselectAllTransactions = (state) =>
  update(state, { selectedTransactionsRefs: { $set: [] } });

const deselectTransactions = (state, action) => {
  const { payload } = action;
  const index = state.selectedTransactionsRefs.indexOf(payload[0]);
  const length = payload.length;
  return update(state, { selectedTransactionsRefs: { $splice: [[index, length]] } });
};

const selectTransactionsDeposit = (state, action) =>
  update(state, { selectedTransactionsRefsDeposit: { $push: action.payload } });

const setDeviceData = (state, action) =>
  update(state, { deviceData: { $set: { data: action.payload } } });

const selectAllTransactionsDeposit = (state, action) =>
  update(state, { selectedTransactionsRefsDeposit: { $set: action.payload } });

const deselectAllTransactionsDeposit = (state) =>
  update(state, { selectedTransactionsRefsDeposit: { $set: [] } });

const deselectTransactionsDeposit = (state, action) => {
  const { payload } = action;
  const index = state.selectedTransactionsRefsDeposit.indexOf(payload[0]);
  const length = payload.length;
  return update(state, { selectedTransactionsRefsDeposit: { $splice: [[index, length]] } });
};

const paypalToken = (state, { payload }) =>
  update(state, {
    paypalToken: { $set: payload }
  });

const setToken = (state, { payload, meta }) =>
  update(state, {
    token: {
      [meta.args.instanceId]: {
        $set: payload
      }
    }
  });

// Avoid memory leak
const deleteToken = (state, { payload }) =>
  update(state, {
    token: { $unset: payload }
  });

const setSuggestedDepositAmounts = (state, { payload }) =>
  update(state, {
    suggestedDepositAmounts: { $set: payload }
  });

const withdrawProcessingTime = (state, { payload }) =>
  update(state, {
    withdrawProcessingTime: {
      $set: payload.hours
    }
  });

const ecospendBanks = (state, action) =>
  action?.payload?.banks
    ? update(state, {
        ecospendBanks: {
          $set: action.payload.banks
        }
      })
    : state;

const depositEcospendFirst = (state, action) => {
  const ecospendDepositState = {
    paylinkId: action.payload.paylinkId,
    depositAmount: action.payload.depositAmount,
    paymentUrl: action.payload.bankConsentPaymentUrl,
    qrCode: action.payload.qrCode,
    smsSentAt: null,
    smsSentTo: null,
    sentSmsCount: null
  };
  return update(state, {
    ecospendDeposit: {
      $set: ecospendDepositState
    }
  });
};

const depositEcospend = (state, action) => {
  const ecospendDepositState = {
    paylinkId: action.payload.paylinkId,
    depositAmount: action.payload.depositAmount,
    paymentUrl: action.payload.paymentUrl,
    qrCode: action.payload.qrCode,
    smsSentAt: null,
    smsSentTo: null,
    sentSmsCount: null
  };

  return update(state, {
    ecospendDeposit: {
      $set: ecospendDepositState
    }
  });
};

const updateEcospendSmsStatus = (state, action) => {
  const smsCount = state.ecospendDeposit.sentSmsCount;
  const ecospendDepositState = {
    smsSentAt: Date.now(),
    smsSentTo: action.payload.phoneNumber,
    sentSmsCount: smsCount ? smsCount + 1 : 1
  };

  return update(state, {
    ecospendDeposit: {
      $merge: ecospendDepositState
    }
  });
};

const clearEcospendDeposit = (state) =>
  update(state, {
    ecospendDeposit: {
      $set: {
        paylinkId: null,
        paymentUrl: null,
        qrCode: null,
        smsSentAt: null,
        smsSentTo: null,
        sentSmsCount: null
      }
    }
  });

const updatePendingWithdrawals = (state, action) =>
  update(state, {
    totalAmountPending: {
      $set: action.payload.totalAmount
    }
  });

const setExpandedBankTransactionId = (state, { payload }) =>
  update(state, {
    expandedBankTransactionId: { $set: payload }
  });

const setExpandedGameTransactionId = (state, { payload }) =>
  update(state, {
    expandedGameTransactionId: { $set: payload }
  });

const setDepositLimitSolicitations = (state, action) => {
  const solicitationsPayload = action.payload;
  return update(state, {
    depositLimitSolicitations: {
      // FIXME: More granular updates. This could be easily achieved if requestsState wasn't an object with only one key
      $set: {
        // FIXME: "solicitations". "Payload" is an action construct.
        solicitationsPayload,
        // TODO: "requestStatus"
        requestsState: {
          [DLS_REQUESTS_STATE.IS_GET_SUCCESSFUL]: { $set: true }
        }
      }
    }
  });
};

const updateDepositLimitSolicitationsRequestsStatus = (requestsState) => (state) =>
  update(state, {
    depositLimitSolicitations: {
      requestsState: { $merge: requestsState }
    }
  });

const agreePaymentTerms = (state) =>
  update(state, {
    agreedPaymentTerms: {
      $set: true
    }
  });

const moduleReducer = module(
  handleActions(
    {
      [AT.AGREE_PAYMENT_TERMS.FULFILLED]: agreePaymentTerms,
      [AT.UPDATE_BALANCE.FULFILLED]: updateBalance,
      [AT.PAYPAL_TOKEN.FULFILLED]: paypalToken,
      [AT.GET_TOKEN.FULFILLED]: setToken,
      [AT.COLLECT_DATA.FULFILLED]: setDeviceData,
      [AT.SUGGESTED_DEPOSIT_AMOUNTS.FULFILLED]: setSuggestedDepositAmounts,
      [AT.UPDATE_PAYMENT_CARD.FULFILLED]: updateSinglePaymentMethod,
      [AT.LIST_PAYMENT_METHODS.FULFILLED]: updatePaymentMethods,
      [AT.LIST_PAYMENT_METHODS_DETAILED.FULFILLED]: updatePaymentMethodsDetailed,
      [AT.LIST_PAYMENT_METHODS.REJECTED]: updateCardsError,
      [AT.LIST_PAYMENT_PROVIDERS.FULFILLED]: updatePaymentProviders,
      [AT.LIST_PAYMENT_PROVIDERS_AVAILABLE.FULFILLED]: updatePaymentProvidersAvailable,
      // TODO: Check error states with Iulian, make sure it conforms with our usual error (message) structure
      // TODO: Add error handling for the actions above
      // [AT.LIST_PAYMENT_PROVIDERS.REJECTED]: updatePaymentMethodsViews,
      // [AT.LIST_PAYMENT_PROVIDERS_AVAILABLE.REJECTED]: updatePaymentMethodsViews,
      [AT.GLOBAL_JACKPOT.FULFILLED]: updateGlobalJackpot,
      [AT.SMS_VERIFICATION_STATE.PENDING]: resetVerification,
      [AT.SMS_VERIFICATION_STATE.FULFILLED]: getVerification,
      [AT.VERIFY_SMS.FULFILLED]: flowRight([setVerification, updateSinglePaymentMethod]),
      [AT.GAME_TRANSACTIONS.FULFILLED]: gameTransactions,
      [AT.BANK_TRANSACTIONS.FULFILLED]: bankTransactions,
      [AT.BANK_TRANSACTIONS_DEPOSIT.FULFILLED]: bankTransactionsDeposit,
      [AT.DEPOSIT_CARD_BRAINTREE.FULFILLED]: deposit,
      [AT.DEPOSIT_CARD_PCI_PROXY.FULFILLED]: deposit,
      [AT.DEPOSIT_MOBILE.FULFILLED]: deposit,
      [AT.DEPOSIT_PAYPAL.FULFILLED]: deposit,
      [AT.DEPOSIT_PAYPAL.REJECTED]: rejectPaypalDeposit,
      [AT.CREATE_PAYPAL.FULFILLED]: updateSinglePaymentMethod,
      [AT.CREATE_PAYPAL.REJECTED]: rejectPaypalCreation,
      [AT.ADD_NEW_CARD.FULFILLED]: updateSinglePaymentMethod,
      [AT.CREATE_PAYMENT_METHOD.FULFILLED]: updateSinglePaymentMethod,
      [AT.WITHDRAW.FULFILLED]: withdraw,
      [AT.WITHDRAW_PROCESSING_TIME.FULFILLED]: withdrawProcessingTime,
      [AT.WITHDRAW_CANCEL.FULFILLED]: withdrawCancel,
      [AT.CANCEL_MULTIPLE_WITHDRAWS.FULFILLED]: cancelMultipleWithdraws,
      [AT.GET_DEPOSIT_LIMIT.FULFILLED]: getDepositLimit,
      [AT.SET_DEPOSIT_LIMIT.FULFILLED]: getDepositLimit,
      [AT.SET_DEPOSIT_LIMIT_DAILY.FULFILLED]: getDepositLimit,
      [AT.SET_DEPOSIT_LIMIT_WEEKLY.FULFILLED]: getDepositLimit,
      [AT.SET_DEPOSIT_LIMIT_MONTHLY.FULFILLED]: getDepositLimit,
      [AT.AGREED_PAYMENT_TERMS.FULFILLED]: setAgreedTerms,
      [AT.AGREED_PAYMENT_TERMS.FULFILLED]: gotAgreeTerms,
      [AT.CHANGE_PRIMARY_CARD.FULFILLED]: changePrimary,
      [AT.REMOVE_CARD.FULFILLED]: removeCard,
      [AT.PENDING_WITHDRAWALS.FULFILLED]: updatePendingWithdrawals,
      [AT.GET_DEPOSIT_LIMIT_SOLICITATIONS.FULFILLED]: setDepositLimitSolicitations,
      // Don't we want to clear solicitations (payload) here?
      [AT.GET_DEPOSIT_LIMIT_SOLICITATIONS.REJECTED]: updateDepositLimitSolicitationsRequestsStatus({
        [DLS_REQUESTS_STATE.IS_GET_SUCCESSFUL]: false
      }),
      [T.OVERRIDE_BALANCE]: overrideBalance,
      [AT.ECOSPEND_BANKS.FULFILLED]: ecospendBanks,
      [AT.DEPOSIT_ECOSPEND_FIRST.FULFILLED]: depositEcospendFirst,
      [AT.DEPOSIT_ECOSPEND.FULFILLED]: depositEcospend,
      [AT.ECOSPEND_SMS.FULFILLED]: updateEcospendSmsStatus,
      [T.DELETE_TOKEN]: deleteToken,
      [T.SET_HAS_DEPOSITED]: setHasDeposit,
      [T.SET_PCI_PROXY_ERROR]: setPciProxyError,
      [T.SET_CURRENT_PCI_3D_CHALLENGE_TRANSACTION_ID]: setCurrentPci3dChallengeTransactionId,
      [T.SET_HAS_OPEN_LOOP_WITHDRAWAL_STARTED]: setHasOpenLoopWithdrawalStarted,
      [T.CANCEL_OVERRIDE_BALANCE]: cancelOverrideBalance,
      [T.SELECT_TRANSACTIONS_DEPOSIT]: selectTransactionsDeposit,
      [T.SELECT_ALL_TRANSACTIONS_DEPOSIT]: selectAllTransactionsDeposit,
      [T.DESELECT_TRANSACTIONS_DEPOSIT]: deselectTransactionsDeposit,
      [T.DESELECT_ALL_TRANSACTIONS_DEPOSIT]: deselectAllTransactionsDeposit,
      [T.SELECT_TRANSACTIONS]: selectTransactions,
      [T.SELECT_ALL_TRANSACTIONS]: selectAllTransactions,
      [T.DESELECT_TRANSACTIONS]: deselectTransactions,
      [T.DESELECT_ALL_TRANSACTIONS]: deselectAllTransactions,
      [T.CLEAR_ECOSPED_DEPOSIT]: clearEcospendDeposit,
      [T.SET_EXPANDED_BANK_TRANSACTION_ID]: setExpandedBankTransactionId,
      [T.SET_EXPANDED_GAME_TRANSACTION_ID]: setExpandedGameTransactionId
    },
    initialState
  ),
  NAME
);
const externalReducer = handleActions(
  {
    [userActionTypes.AT.GET_CURRENT.FULFILLED]: (state, action) =>
      update(state, {
        hasDeposited: { $set: action.payload.hasDeposited }
      })
  },
  initialState
);

const reducer = (state = initialState, action) =>
  isNil(action.type) || getNamespace(action.type) !== NAME
    ? externalReducer(state, action)
    : moduleReducer(state, action);

export default Socket.reducer(reducer, NAME);
