import React, { useCallback } from 'react';
import cc from 'classcat';
import { useHistory } from 'react-router-dom';
import RouterLink from 'components/RouterLink';
import { constants } from 'modules/User';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import { Typography, TypographyVariant } from 'components/Typography';
import type { DfgDayStatus, Day } from './types';
import DfgDays from './src/DfgDays';
import RewardCardStatusMarker from './components/RewardCardStatusMarker';
import DailyFreeGamesRibbon from './components/DailyFreeGamesRibbon';
import NextGameCountdown from './components/NextGameCountdown';
import './rewardCard.css';

interface RewardCardProps {
  path: string;
  title?: string;
  description?: string;
  termsAndConditions?: string;
  imageUrl?: string;
  isDfg?: boolean;
  ribbonLabel?: string;
  currentDay?: Day;
  dfgDays?: DfgDayStatus | null;
  status?: constants.RewardStatus;
  openTCModal: (content: string) => void;
  openPlayGuide?: () => void;
  openHowToUnlockModal?: () => void;
  rewardAmount?: string;
}

const RewardCard = ({
  title = '',
  description = '',
  imageUrl,
  termsAndConditions = '',
  status,
  isDfg = false,
  dfgDays = null,
  ribbonLabel,
  currentDay,
  rewardAmount,
  path,
  openTCModal,
  openPlayGuide,
  openHowToUnlockModal
}: RewardCardProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLocked = status === constants.RewardStatus.Locked;
  const isPlayed = status === constants.RewardStatus.Played;
  const isPlayable = status === constants.RewardStatus.Playable;

  const gameLaunched = useCallback(() => {
    dispatch(
      Analytics.actions.gameLaunched({
        refName: path,
        source: 'Rewards'
      })
    );
  }, [path, dispatch]);

  const handleClickTC = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openTCModal(termsAndConditions);
    },
    [openTCModal, termsAndConditions]
  );

  const handleClickReadMore = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openPlayGuide?.();
    },
    [openPlayGuide]
  );

  const openGame = useCallback(() => {
    gameLaunched();
    history.push(`/secure/games/${path}`);
  }, [gameLaunched, history, path]);

  const handleDFGCardClick = useCallback(() => {
    if (isLocked) {
      openHowToUnlockModal?.();
    } else if (isPlayable) {
      openGame();
    }
  }, [openGame, openHowToUnlockModal, isLocked, isPlayable]);

  const handleCardClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDfg) {
        handleDFGCardClick();
      } else {
        openGame();
      }
    },
    [openGame, handleDFGCardClick, isDfg]
  );

  // Why does the root div have a onClick Handler?
  // The stakeholders of this feature want a click anywhere on the card to trigger the default action,
  // in this case, redirecting the user to the respective game.
  // Making the div have an onClick will give that behaviour, while buttons inside may have another
  // onClick functionality which will stop the onClick propagation (bubbling).
  // With the title being a RouterLink to the same location as the root div onClick handler
  // we get the desired behavior and still get to keep the accessibility working properly

  return (
    <div
      className={cc([
        'rewardCard',
        isPlayed && 'rewardCard--played',
        isPlayable && isDfg && 'rewardCard--wiggle'
      ])}
      onClick={handleCardClick}
    >
      {status && <RewardCardStatusMarker rewardStatus={status} />}
      <div className={cc(['rewardCard__content', isDfg && 'rewardCard__content--lightYellow'])}>
        <div className={cc(['rewardCard__thumbnail', isDfg && 'rewardCard__thumbnail--withBadge'])}>
          <img className="rewardCard__thumbnailImage" alt="game thumbnail" src={imageUrl} />
          {isDfg && <DailyFreeGamesRibbon label={ribbonLabel} />}
        </div>
        <div className="rewardCard__info text-truncate">
          <RouterLink
            href={isPlayable ? `/secure/games/${path}` : ''}
            title={`Play ${title}`}
            className="rewardCard__title text-truncate"
          >
            {title}
          </RouterLink>
          {description && !isPlayed && (
            <div className="rewardCard__subtitle text-truncate">{description}</div>
          )}
          {isDfg && isPlayed && (
            <div className="rewardCard__subtitle text-truncate">
              <NextGameCountdown />
            </div>
          )}
          {dfgDays && <DfgDays dayStatusMap={dfgDays} currentDay={currentDay} />}
          {(termsAndConditions || openPlayGuide) && (
            <div className="rewardCard__actions">
              {termsAndConditions && (
                <Typography variant={TypographyVariant.BodyXs}>
                  <a
                    onClick={handleClickTC}
                    className={cc([
                      'rewardCard__link text-truncate',
                      'button--fakeLink',
                      isDfg && 'rewardCard__link--underline'
                    ])}
                  >
                    T&Cs apply
                  </a>
                </Typography>
              )}
              {openPlayGuide && (
                <Typography variant={TypographyVariant.BodyXs}>
                  <a
                    onClick={handleClickReadMore}
                    className="rewardCard__link rewardCard__link--underline text-truncate"
                  >
                    Read more
                  </a>
                </Typography>
              )}
            </div>
          )}
        </div>
        {rewardAmount && <div className="rewardCard__count">{rewardAmount}</div>}
      </div>
    </div>
  );
};

export default RewardCard;
