import React, { memo, useState, Children, cloneElement } from 'react';
import type { RefAttributes, ReactElement, JSXElementConstructor } from 'react';
import cc from 'classcat';
import { useEffectOnce, useMediaQuery } from 'usehooks-ts';
import { PANEL_CORNER_SIDE, PANEL_CORNER } from 'lib/edgePanel';
import makeModal from 'components/Modals';
import './bottomSheet.css';

interface BottomSheetProps {
  name: string;
  breakpoint?: number;
  className?: string;
  children: JSX.Element;
}

const BottomSheet = ({ name, className, breakpoint = 470, children }: BottomSheetProps) => {
  const matches = useMediaQuery(`(max-width: ${breakpoint}px)`);
  const [BottomSheetModal, setBottomSheetModal] = useState<
    (props: RefAttributes<any>) => ReactElement<any, string | JSXElementConstructor<any>> | null
  >(() => null);

  useEffectOnce(() => {
    const modal = () => {
      const modalTransition = matches
        ? {
            name: `modal-slideEdgeFrom-${PANEL_CORNER_SIDE[PANEL_CORNER.BOTTOM_LEFT]}`,
            timeout: 400, // synced with the animation transition in modals.css
            component: 'div'
          }
        : { name: 'modal-fade', timeout: 400 };

      return makeModal(name, modalTransition, {
        className: 'modal'
      });
    };

    setBottomSheetModal(
      modal()((props: object) =>
        cloneElement(Children.only(children), {
          ...children.props,
          ...props
        })
      )
    );
  });

  return (
    BottomSheetModal && (
      <div className={cc(['bottomSheet', { 'bottomSheet--mobile': matches }, className])}>
        <BottomSheetModal {...children.props} />
      </div>
    )
  );
};

export default memo(BottomSheet);
