import { useEffect, useState } from 'react';
import getSecondsToUTCMidnight from './helpers/getSecondsToUTCMidnight';
import formatSecondsToHHMMSS from './helpers/formatSecondsToHHMMSS';

interface useTimeToUTCMidnightProps {
  onMidnight: () => void;
}

const useTimeToUTCMidnight = ({ onMidnight }: useTimeToUTCMidnightProps) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    // Calls getSecondsToUTCMidnight() every second for an accurate countdown,
    // avoiding drift from system time changes, tab inactivity, or device sleep.
    const updateTimeLeft = () => {
      const diffInSeconds = getSecondsToUTCMidnight();
      setTimeLeft(formatSecondsToHHMMSS(diffInSeconds > 0 ? diffInSeconds : 0));

      if (diffInSeconds <= 0 && onMidnight) {
        onMidnight();
      }
    };

    updateTimeLeft();

    const timer = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [onMidnight]);

  return timeLeft;
};

export default useTimeToUTCMidnight;
