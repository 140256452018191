import React from 'react';
import cc from 'classcat';
import { clampNumber } from 'lib/formatters/clampNumber';
import './invitedProgressPips.css';

interface InvitedProgressPipsProps {
  current: number;
  total: number;
}

const iconClassName = (isFaded: boolean) =>
  cc(['invitedProgressPips__icon', { 'invitedProgressPips__icon--faded': isFaded }]);

const InvitedProgressPips = ({ current, total }: InvitedProgressPipsProps) => {
  const currentClamped = clampNumber(current, 0, total);

  return (
    <div className="invitedProgressPips">
      {total > 0 ? (
        [...Array(total).keys()].map((pip, index) => (
          <svg key={pip} className={iconClassName(index >= currentClamped)}>
            <use href="/assets/images1/referAFriend/user.svg#icon" />
          </svg>
        ))
      ) : (
        <>
          <svg className={iconClassName(current === 0)}>
            <use href="/assets/images1/referAFriend/user.svg#icon" />
          </svg>
          <svg className={iconClassName(current === 0)}>
            <use href="/assets/images1/referAFriend/infinite.svg#icon" />
          </svg>
        </>
      )}
    </div>
  );
};

export default InvitedProgressPips;
