import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import 'components/Radio/radio.css';
import './radioGroup.css';

// Neutral is purely for deselecting, it must not dispatch change itself (non-controlled)
class RadioGroup extends PureComponent {
  static getDerivedStateFromProps({ max, value }, { defaultValue, selected }) {
    let result = null;
    let changed = false;

    if (max !== defaultValue) {
      changed = true;
      result = { defaultValue: max };
    }
    if (selected !== 0 && value === 0) {
      if (changed) {
        result.selected = value;
        result.active = false;
      } else {
        result = { active: false, selected: value };
      }
    } else if (selected !== value) {
      if (changed) {
        result.selected = value;
      } else {
        result = { selected: value };
      }
    }

    return result;
  }

  constructor(props) {
    super(props);
    // State and handleSelectChange refer to the <select> element!
    this.state = { value: null, active: false, defaultValue: props.max };
  }

  componentDidUpdate(_, { selected }) {
    if (selected !== 0 && this.state.selected === 0 && this.neutral) this.neutral.click();
  }

  setNeutralRef = (_) => {
    this.neutral = _;
  };

  handleSelectChange = (event) => {
    const { value } = event.target;
    this.setState({ value });
    if (value !== this.state.selected) this.props.onChange(event, { value, name: this.props.uid });
  };

  handleClick = (event) => {
    const { name, value } = event.target;
    if (name === 'selectCustom') {
      event.preventDefault();
      this.neutral.click();
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
    if (value !== this.state.selected) this.props.onChange(event, { value, name: this.props.uid });
  };

  render() {
    const {
      max,
      values,
      custom,
      uid,
      alwaysSelected,
      variant,
      className,
      disabled,
      valueFormatter
    } = this.props;
    const { value, active, defaultValue, selected } = this.state;
    const options =
      custom &&
      new Array(max).fill().map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key -- needs to be like this
        <option value={i + 1} key={i + 1}>
          {valueFormatter(i + 1)}
        </option>
      ));

    const group = values.map(
      (val, i) =>
        (max === null || max >= parseInt(val, 10)) && (
          <Fragment key={val}>
            <input
              type="radio"
              id={val + i + uid}
              value={val}
              checked={!active && selected === val}
              onChange={this.handleClick}
              disabled={disabled}
            />
            <label className="radioGroup__radioButton" htmlFor={val + i + uid}>
              {valueFormatter(val)}
            </label>
          </Fragment>
        )
    );

    const selectGroup = cc([
      className,
      'select-group',
      custom && 'select-group--withCustom',
      variant && `select-group--${variant}`,
      disabled && 'disabled'
    ]);

    return (
      <div className={selectGroup}>
        {!alwaysSelected && (
          <input
            type="radio"
            value="0"
            defaultChecked={!selected || selected === '0'}
            ref={this.setNeutralRef}
          />
        )}
        {group}
        {custom && max > 0 && (
          <select
            className={`radioGroup__radioButton ${active ? 'active' : ''}`}
            name="selectCustom"
            value={value || defaultValue}
            onClick={this.handleClick}
            onChange={this.handleSelectChange}
          >
            {options}
          </select>
        )}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  valueFormatter: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  /** Empty is `tertiary` */
  max: PropTypes.number,
  variant: PropTypes.oneOf(['blue', 'purple']),
  uid: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  custom: PropTypes.bool,
  alwaysSelected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool
};

RadioGroup.defaultProps = {
  valueFormatter: (value) => value,
  max: null,
  custom: false,
  variant: 'blue',
  alwaysSelected: false,
  disabled: false
};

export default RadioGroup;
