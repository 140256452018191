import { paymentMethodProvider } from 'lib/constants';

export interface PaymentMethodOption {
  type: string;
  title: string;
  icon: string;
  tag?: string;
  value: PaymentMethodMapValues;
}

// This is for mapping the relevant deposit modal component to the payment method type.
export const paymentMethodMap = {
  [paymentMethodProvider.PCI_PROXY]: 'pci-proxy',
  [paymentMethodProvider.BRAINTREE]: 'braintree',
  [paymentMethodProvider.FONIX]: 'mobile',
  [paymentMethodProvider.PAYPAL]: 'paypal',
  [paymentMethodProvider.ECOSPEND]: 'ecospend',
  [paymentMethodProvider.TRUELAYER]: 'truelayer'
} as const;

export type PaymentMethodMapKeys = keyof typeof paymentMethodMap;
export type PaymentMethodMapValues = (typeof paymentMethodMap)[PaymentMethodMapKeys];
