import React, { useCallback, useState } from 'react';
import BottomSheet from 'components/BottomSheet';
import { TermsConditions } from 'components/TermsConditions';
import GamifiedReferralsInviteProgress from './components/GamifiedReferralsInviteProgress';
import GamifiedReferralsMoreInfo from './components/GamifiedReferralsMoreInfo';
import { tAndCsScreenTitle } from './data';

interface GamifiedReferralsModalProps {
  shouldShowMoreInfo: boolean;
  shouldShowTAndCs: boolean;
  onMoreInfoClick: () => void;
  onMoreInfoOnBackClick: () => void;
  onTAndCsClick: () => void;
  onTAndCsOnBackClick: () => void;
  onClose: () => void;
  close?: () => void;
}

const GamifiedReferralsModal = ({
  shouldShowMoreInfo,
  shouldShowTAndCs,
  onMoreInfoClick,
  onMoreInfoOnBackClick,
  onTAndCsClick,
  onTAndCsOnBackClick,
  onClose,
  close
}: GamifiedReferralsModalProps) => {
  if (!close) {
    return null;
  }

  const handleCloseModal = () => {
    onClose();
    close();
  };

  return shouldShowMoreInfo ? (
    <GamifiedReferralsMoreInfo close={handleCloseModal} onBack={onMoreInfoOnBackClick} />
  ) : shouldShowTAndCs ? (
    <TermsConditions
      title={tAndCsScreenTitle}
      close={handleCloseModal}
      onBack={onTAndCsOnBackClick}
    />
  ) : (
    <GamifiedReferralsInviteProgress
      close={handleCloseModal}
      onMoreInfoClick={onMoreInfoClick}
      onTAndCsClick={onTAndCsClick}
    />
  );
};

const GamifiedReferralsModalAdapter = () => {
  const [shouldShowMoreInfo, setShouldShowMoreInfo] = useState(false);
  const [shouldShowTAndCs, setShouldShowTAndCs] = useState(false);

  const handleMoreInfoClick = useCallback(() => setShouldShowMoreInfo(true), []);
  const handleMoreInfoOnBackClick = useCallback(() => setShouldShowMoreInfo(false), []);
  const handleTAndCsClick = useCallback(() => setShouldShowTAndCs(true), []);
  const handleTAndCsOnBackClick = useCallback(() => setShouldShowTAndCs(false), []);
  const handleClose = useCallback(() => {
    setShouldShowMoreInfo(false);
    setShouldShowTAndCs(false);
  }, []);

  return (
    <BottomSheet name="referAFriend" className="GamifiedReferralsModal">
      <GamifiedReferralsModal
        shouldShowMoreInfo={shouldShowMoreInfo}
        shouldShowTAndCs={shouldShowTAndCs}
        onMoreInfoClick={handleMoreInfoClick}
        onMoreInfoOnBackClick={handleMoreInfoOnBackClick}
        onTAndCsClick={handleTAndCsClick}
        onTAndCsOnBackClick={handleTAndCsOnBackClick}
        onClose={handleClose}
      />
    </BottomSheet>
  );
};

export default GamifiedReferralsModalAdapter;
