import React, { PureComponent } from 'react';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import { DEPOSIT_LIMIT_INTERVAL } from 'lib/depositLimit';
import Analytics from 'modules/Analytics';
import UserBox from 'components/UserBox';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import makeModal from 'components/Modals';
import { VALUE_NOT_SET } from 'components/SimpleSelect';
import DepositLimitForm from './DepositLimitForm';
import './depositLimit.css';

class _DepositLimitBase extends PureComponent {
  componentDidMount() {
    this.props.init();
  }

  submitWithInitials = (limits) => this.props.submit(limits, this.props.onDone);

  render() {
    const {
      noSubmitInitial,
      submitLoading,
      loading,
      close,
      onDone,
      exposeExperiment,
      depositLimitDaily,
      depositLimitWeekly,
      depositLimitMonthly
    } = this.props;
    const fields = {
      [DEPOSIT_LIMIT_INTERVAL.DAILY]: {
        initial: depositLimitDaily?.toString() || VALUE_NOT_SET
      },
      [DEPOSIT_LIMIT_INTERVAL.WEEKLY]: {
        initial: depositLimitWeekly?.toString() || VALUE_NOT_SET
      },
      [DEPOSIT_LIMIT_INTERVAL.MONTHLY]: {
        initial: depositLimitMonthly?.toString() || VALUE_NOT_SET
      }
    };
    return (
      <MagicMove>
        {loading ? (
          <LoadingElement key="LoadingElement" />
        ) : (
          <DepositLimitForm
            fields={fields}
            submit={this.submitWithInitials}
            loading={submitLoading}
            onDone={onDone || close}
            exposeExperiment={exposeExperiment}
            noSubmitInitial={!isNil(noSubmitInitial) ? noSubmitInitial : true} // Just default to true
          />
        )}
      </MagicMove>
    );
  }
}

const _DepositLimit = (props) => (
  <UserBox
    id="depositLimitBox"
    className="depositLimit modal"
    title="Set Spend Limit"
    closeCallback={props.close}
  >
    {/* eslint-disable-next-line react/jsx-pascal-case  -- it needs to be like this */}
    <_DepositLimitBase {...props} />
  </UserBox>
);

const mapStateToProps = (state) => ({
  [DEPOSIT_LIMIT_INTERVAL.DAILY]: Wallet.selectors.model(state).depositLimitDaily,
  [DEPOSIT_LIMIT_INTERVAL.WEEKLY]: Wallet.selectors.model(state).depositLimitWeekly,
  [DEPOSIT_LIMIT_INTERVAL.MONTHLY]: Wallet.selectors.model(state).depositLimitMonthly,
  submitLoading: isLoading(state, Wallet.actionTypes.AT.SET_DEPOSIT_LIMIT._),
  loading: isLoading(state, [
    Wallet.actionTypes.AT.GET_DEPOSIT_LIMIT._,
    Wallet.actionTypes.AT.SET_DEPOSIT_LIMIT_DAILY._,
    Wallet.actionTypes.AT.SET_DEPOSIT_LIMIT_WEEKLY._,
    Wallet.actionTypes.AT.SET_DEPOSIT_LIMIT_MONTHLY._
  ])
});

const mapDispatchToProps = (dispatch) => ({
  init: async () => {
    Api.actions.wallet.getDepositLimit()(dispatch);
  },
  submit: (limits, onDone) => {
    const payload = [
      DEPOSIT_LIMIT_INTERVAL.DAILY,
      DEPOSIT_LIMIT_INTERVAL.WEEKLY,
      DEPOSIT_LIMIT_INTERVAL.MONTHLY
    ].reduce((acc, next) => {
      if (limits[next] === VALUE_NOT_SET) return acc;
      acc[next] = parseInt(limits[next], 10);
      return acc;
    }, {});
    const res = Api.actions.wallet.setDepositLimit(null, payload)(dispatch, true);
    if (onDone) {
      Api.actions.wallet.getDepositLimit()(dispatch); // sync Play Safely account section
      return onDone(res);
    }
    return res;
  },
  exposeExperiment: (experimentData) => dispatch(Analytics.actions.exposeExperiment(experimentData))
});

export default compose(
  makeModal(
    'depositLimit',
    { name: 'modal-fade', timeout: 200 },
    { className: 'depositLimit modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_DepositLimit);

export const DepositLimitBase = connect(mapStateToProps, mapDispatchToProps)(_DepositLimitBase);
