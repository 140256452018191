import React, { useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Api from 'services/Api';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import amplitude from 'lib/analytics';
import { phone as phoneRegex, email as emailRegex, name as nameRegex } from 'lib/valRegexes';
import { hasDuplicateValues } from 'lib/valFuncs';
import { Typography, TypographyVariant } from 'components/Typography';
import Heading from 'components/Heading';
import InviteForm from '../InviteForm';
import { ContactFieldType } from '../../constants';
import { referralTypeText } from '../../data';
import './inviteScreen.css';

const scrollTo = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

/**
 * Extract properties and prepare them for the "Referral Sent" and "Referral Rejected" events.
 * @param {string} type - the type of referral
 * @param {string} errorType - the type of error
 */
const extractProperties = (contactFieldType: ContactFieldType, errorType?: string) => ({
  'Referral Type': contactFieldType,
  ...(errorType && { 'Error Type': errorType })
});

interface InviteResponse {
  success?: boolean;
}

export interface FieldSchema {
  value?: string;
  required: boolean;
  error: string;
  onChange: (
    value: string,
    formFields: RefereeFields,
    fieldName: string
  ) => string | boolean | null;
}

export type RefereeFields = { [key: string]: FieldSchema };

interface InviteScreenAdapterProps {
  contactFieldType?: ContactFieldType | null;
  onInviteSuccess?: () => void;
}

export const InviteScreen = ({ contactFieldType, onInviteSuccess }: InviteScreenAdapterProps) => {
  const dispatch = useAppDispatch();
  const freeRounds = useAppSelector(User.selectors.getFreeRounds);
  const numRequired = useRef<number>(1);
  const initialFieldRef = nanoid();
  const [fieldRefs, setFieldRefs] = useState([initialFieldRef]);

  const scrollIfLastRow = (rowId: string, fieldName: string) => {
    if (fieldRefs[fieldRefs.length - 1] === rowId) {
      setTimeout(() => scrollTo(fieldName));
    }
  };

  const setRequired = (a: string, b: string, required: boolean, value?: string) => {
    if (required || numRequired.current > 1) {
      numRequired.current += required ? 1 : -1;
      const aVal = required
        ? {
            required
          }
        : {
            required,
            initial: ''
          };
      setTimeout(() => {
        fields.current = {
          ...fields.current,
          [a]: {
            ...fields.current[a],
            ...aVal
          },
          [b]: {
            ...fields.current[b],
            required,
            initial: required ? value : ''
          }
        };
      }, 0);
    }
  };

  const onEmailChange = (value: string, formFields: RefereeFields, fieldName: string) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = formFields[`name_${rowId}`];
    if (otherField.value === '' && value === '') {
      setRequired(`name_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!formFields[`name_${rowId}`].required) {
        setRequired(`name_${rowId}`, fieldName, true, value);
      }
      if (!emailRegex.test(value.trim())) {
        scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid email address';
      }
      if (hasDuplicateValues(fieldName, value, formFields)) {
        scrollIfLastRow(rowId, fieldName);
        return `You've already entered this email address`;
      }
    }
    return true;
  };

  const onTelChange = (value: string, formFields: RefereeFields, fieldName: string) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = formFields[`name_${rowId}`];
    if (otherField.value === '' && value === '') {
      setRequired(`name_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!formFields[`name_${rowId}`].required) {
        setRequired(`name_${rowId}`, fieldName, true, value);
      }
      if (!phoneRegex.test(value.trim())) {
        scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid phone number';
      }
      if (hasDuplicateValues(fieldName, value, formFields)) {
        scrollIfLastRow(rowId, fieldName);
        return `You've already entered this phone number`;
      }
    }
    return true;
  };

  const onNameChange = (value: string, formFields: RefereeFields, fieldName: string) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = formFields[`contactDetails_${rowId}`];
    if (otherField.value === '' && value === '') {
      setRequired(`contactDetails_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!formFields[`contactDetails_${rowId}`].required) {
        setRequired(`contactDetails_${rowId}`, fieldName, true, value);
      }
      if (!nameRegex.test(value.trim())) {
        scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid name';
      }
    }
    return true;
  };

  const emailFieldSchema: FieldSchema = {
    required: true,
    error: 'Email is required',
    onChange: onEmailChange
  };

  const telFieldSchema: FieldSchema = {
    required: true,
    error: 'Phone number is required',
    onChange: onTelChange
  };

  const nameFieldSchema: FieldSchema = {
    required: true,
    error: 'Name is required',
    onChange: onNameChange
  };

  const fields = useRef({
    ...[initialFieldRef].reduce((result: { [key: string]: object }, fieldRef) => {
      // eslint-disable-next-line no-param-reassign -- reduce accumulator
      result[`name_${fieldRef}`] = nameFieldSchema;
      // eslint-disable-next-line no-param-reassign -- reduce accumulator
      result[`contactDetails_${fieldRef}`] =
        contactFieldType === ContactFieldType.EMAIL ? emailFieldSchema : telFieldSchema;
      return result;
    }, {})
  });

  const addField = () => {
    // Limit total winnable free spins to 999
    const count = fieldRefs.length;
    if (count * freeRounds < 1000) {
      const key = nanoid();
      setFieldRefs([...fieldRefs, key]);
      fields.current = {
        ...fields.current,
        [`name_${key}`]: { ...nameFieldSchema, required: true },
        [`contactDetails_${key}`]:
          contactFieldType === ContactFieldType.EMAIL ? emailFieldSchema : telFieldSchema
      };
      setTimeout(() => scrollTo(`name_${key}`));
    }
  };

  const removeField = (fieldRef: string) => {
    setFieldRefs(fieldRefs.filter((ref) => ref !== fieldRef));
    fields.current = Object.keys(fields.current)
      .filter(
        (fieldKey: string) =>
          fieldKey !== `name_${fieldRef}` && fieldKey !== `contactDetails_${fieldRef}`
      )
      .reduce(
        (acc, fieldKey: string) => ({
          ...acc,
          [fieldKey]: fields.current[fieldKey]
        }),
        {}
      );
  };

  const submitReferrals = async (data: any) => {
    let res: InviteResponse;
    const referees: any[] = Object.keys(data)
      .map((key, idx, keys) =>
        idx % 2 === 0
          ? {
              name: data[key].trim(),
              value: data[keys[idx + 1]].trim()
            }
          : undefined
      )
      .filter(Boolean);
    if (contactFieldType === ContactFieldType.EMAIL) {
      res = (await Api.actions.user.referByEmail(null, { referees })(
        dispatch,
        true
      )) as InviteResponse;
    } else {
      res = (await Api.actions.user.referBySms(null, { referees })(
        dispatch,
        true
      )) as InviteResponse;
    }

    if (res.success) {
      // Refresh the referrals list
      await Api.actions.user.getReferralStatus()(dispatch);
      // Notification that invites are sent
      if (contactFieldType) {
        dispatch(
          Modals.actions.create(nanoid(), {
            notification: true,
            creationDate: Date.now(),
            message: referralTypeText[contactFieldType].notification,
            expiration: 5000,
            announcement: false,
            pinned: true,
            blocking: false,
            closeOnNavigation: true,
            severity: 2,
            destroyOnClose: true
          })
        );
      }
      // Close RaF modal
      if (onInviteSuccess) {
        onInviteSuccess();
      }
    }

    return res;
  };

  const handleReferSuccess = async (request: Promise<void>) => {
    await request.then(() => {
      if (contactFieldType) {
        amplitude.track('Referral Sent', extractProperties(contactFieldType));
      }
    });
  };

  const handleReferError = ({
    data
  }: {
    data: { existingReferees: string[]; failedRefers: string[] };
  }) => {
    if (!contactFieldType || !data) {
      return;
    }

    const { existingReferees, failedRefers } = data;

    if (existingReferees.length > 0) {
      amplitude.track('Referral Rejected', extractProperties(contactFieldType, 'Existing Referee'));
    }
    if (failedRefers.length > 0) {
      amplitude.track('Referral Rejected', extractProperties(contactFieldType, 'Failed Referral'));
    }
    const successfulInvites = fieldRefs.length - (existingReferees.length + failedRefers.length);
    if (successfulInvites > 0) {
      amplitude.track('Referral Sent', extractProperties(contactFieldType));
    }
  };

  return contactFieldType ? (
    <div className="inviteScreen">
      <Typography variant={TypographyVariant.HeaderXs}>
        <Heading level={2} className="inviteScreen__heading">
          Invite friends
        </Heading>
      </Typography>
      <InviteForm
        contactFieldType={contactFieldType}
        fields={fields.current}
        fieldRefs={fieldRefs}
        addField={addField}
        removeField={removeField}
        submit={submitReferrals}
        onSuccess={handleReferSuccess}
        onError={handleReferError}
        setRequired={setRequired}
        validateOnPropChange
      />
    </div>
  ) : null;
};

export default InviteScreen;
