import React from 'react';
import cc from 'classcat';
import TileMediaCardSkeleton from './components/TileMediaCardSkeleton';
import TileMediaCardBody from './components/TileMediaCardBody';
import TileMediaCardAction from './components/TileMediaCardAction';
import './tileMediaCard.css';

interface TileMediaCardProps {
  children: React.ReactNode;
  className?: string;
}

const TileMediaCard = ({ children, className }: TileMediaCardProps) => (
  <div className={cc(['tileMediaCard', className])}>{children}</div>
);

export {
  TileMediaCard as Root,
  TileMediaCardSkeleton as Skeleton,
  TileMediaCardBody as Body,
  TileMediaCardAction as Action
};
