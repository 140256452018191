import type { RootState } from 'src/store';
import { NAME } from './constants';
import * as m from './model';
import type { AuthState } from './types';

type Selector<A extends undefined | keyof AuthState = undefined> = (
  state: RootState
) => A extends keyof AuthState ? AuthState[A] : AuthState;

export const model: Selector = (state) => (state[NAME] || m.initialState) as AuthState;
export const getPartialUserRef = (state: RootState) => model(state).partialUserRef;
export const getLoggedThisWindow = (state: RootState) => model(state).loggedThisWindow;
export const getIsAuthenticated = (state: RootState) => model(state).authenticated;
export const getLoginError = (state: RootState) => model(state).loginError;
export const getRegisterError = (state: RootState) => model(state).registerError;
export const getMultiFactorAuthentication = (state: RootState) =>
  model(state).multiFactorAuthentication;
export const isMultiFactorRequired = (state: RootState) => model(state).isMultiFactorRequired;
export const getUsername = (state: RootState) => model(state).username;
export const getPassword = (state: RootState) => model(state).password;
