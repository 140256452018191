import React from 'react';
import type { FormEvent, MouseEvent, FocusEventHandler } from 'react';
import FlipMove from 'react-flip-move';
import reform from 'components/Reform';
import FormError from 'components/FormError';
import Button from 'components/Button';
import { easing } from 'lib/constants';
import { inviteFormName, type ContactFieldType } from '../../constants';
import InviteFormFields from '../InviteFormFields';
import { referralTypeText } from '../../data';
import './inviteForm.css';

interface InviteFormProps {
  contactFieldType: ContactFieldType;
  addField: () => void;
  close: () => void;
  removeField: (field: string) => void;
  handleChange: (e: FormEvent<HTMLFormElement>) => void;
  handleFocus: FocusEventHandler<HTMLInputElement>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<any>;
  loading: boolean;
  errorMessage: string;
  fieldRefs: string[];
  formValid: boolean;
}

const InviteForm = ({
  contactFieldType,
  addField,
  removeField,
  close,
  handleChange,
  handleFocus,
  onSubmit,
  loading,
  errorMessage,
  fieldRefs,
  formValid,
  ...rest
}: InviteFormProps) => {
  const shouldShowDeleteButton = fieldRefs.length > 1;

  const handleDeleteRow = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    removeField(e.currentTarget.name);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- expected async handleSubmit
    <form onSubmit={onSubmit} name={inviteFormName[contactFieldType]}>
      <div className="inviteForm__rowsContainer">
        <FlipMove delay={0} duration={350} easing={easing} staggerDelayBy={80}>
          {fieldRefs.map((fieldRef) => (
            <div key={fieldRef}>
              <InviteFormFields
                fieldId={fieldRef}
                nameField={rest[`name_${fieldRef}` as keyof typeof rest]}
                contactField={rest[`contactDetails_${fieldRef}` as keyof typeof rest]}
                contactFieldType={contactFieldType}
                shouldShowDeleteButton={shouldShowDeleteButton}
                handleDeleteRow={handleDeleteRow}
                handleFormChange={handleChange}
                handleFocus={handleFocus}
              />
            </div>
          ))}
        </FlipMove>
      </div>
      <div className="referAFriendModal__buttonGroup">
        <Button
          className="button--fullWidth button__grey"
          onClick={addField}
          name="referFriendAddRow"
          buttonText="+ Add another friend"
          type="button"
        />
        <Button
          className="button--fullWidth"
          disabled={!formValid}
          loading={loading}
          title="Send"
          name="referFriendSend"
          buttonText={referralTypeText[contactFieldType].button}
          type="submit"
        />
      </div>
      <FormError errorMessage={errorMessage} />
    </form>
  );
};

export default reform()(InviteForm);
