import React, { forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classcat';
import { debounce } from 'lodash';
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import { useEffectOnce } from 'usehooks-ts';
import amplitude, { useExperimentData, experiments } from 'lib/analytics';
import { AccordionVertical } from 'components/Transitions';
import FieldError from 'components/FieldError';
import RevealPasswordIcon from 'components/IconsLegacy/RevealPasswordIcon';
import withLazyStyle from 'components/LazyStyle';
import fieldsetStyle from 'css/components/fieldset.css?lazy';

const getMaskedValue = ({ value, type }, inputType) =>
  value && (type === 'password' || inputType === 'password')
    ? '<masked password>'
    : value || undefined;

/**
 * @typedef {import("./types").InputChangedAnalytics} InputChangedAnalytics
 */

/**
 * Extract properties and prepare them for the "Input Changed" event. Falsy values turn to undefined so that they're
 * excluded from the event payload.
 *
 * @param e {import('react').MouseEvent}
 * @param properties
 * @param [properties.icon] {string}
 * @param [properties.required] {boolean | null}
 * @param properties.inputType {string} the nominal input type, which may be different than what is rendered
 * @returns {InputChangedAnalytics}
 */
export const extractProperties = ({ target }, { icon, required, inputType }) => ({
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_NAME]: target.name,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ID]: target.id || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS]: target.className || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ARIA_LABEL]:
    target.getAttribute('aria-label') || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_LABEL]:
    target.labels?.[0]?.textContent?.trim?.() || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TITLE]: target.title || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_VALUE]: getMaskedValue(target, inputType),
  'Input Has Icon': !!icon,
  'Input Type': target.type,
  'Input Is Required': !!required,
  'Input Min': target.min,
  'Input Max': target.max,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TAG]: target.tagName.toLowerCase(),
  [constants.AMPLITUDE_EVENT_PROP_FORM_NAME]: target.form?.name || undefined,
  [constants.AMPLITUDE_EVENT_PROP_FORM_ID]: target.form?.id || undefined
});

/**
 *
 * @param { InputChangedAnalytics } properties
 * @returns {void}
 * @private
 */
const _trackInputChanged = (properties) => {
  amplitude.track('Input Changed', properties);
};

const Fieldset = forwardRef(
  (
    {
      dynamic,
      name,
      className,
      field,
      inputType,
      autoComplete,
      id,
      min,
      max,
      inline,
      step,
      labelText,
      labelTextPreview,
      editable,
      style,
      tag: Input,
      onChange,
      onFocus,
      maxLength,
      onKeyPress,
      icon: _icon,
      isOutsideIcon,
      iconWidth: _iconWidth,
      onBlur,
      hideAsterisk,
      showOptional,
      testId
    },
    forwardedRef
  ) => {
    const inputRef = useRef();
    const trackInputChanged = useRef(debounce(_trackInputChanged, 500));
    const errorMessageExperiment = useExperimentData(experiments.hideErrorMessageBeforeBlur.flag);
    const [hasBlurred, setHasBlurred] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const showServerError = !field.dirty && field.valid && field.serverError;
    const hasRevealPasswordIcon = inputType === 'password' && editable;
    const togglePasswordVisibility = () => {
      setPasswordVisible((state) => !state);
      (forwardedRef || inputRef).current.focus();
    };
    const icon = hasRevealPasswordIcon ? (
      <RevealPasswordIcon
        name="newPasswordConfirmVisible"
        passwordVisible={passwordVisible}
        togglePasswordVisibility={togglePasswordVisibility}
      />
    ) : (
      _icon
    );
    const iconWidth = hasRevealPasswordIcon ? 22 : _iconWidth;
    const rootStyle = useMemo(() => ({ '--iconWidth': `${iconWidth}px` }), [iconWidth]);

    let errorMessage;
    if (editable && (field.valid === false || field.warn || showServerError)) {
      if (field.valid && showServerError) {
        errorMessage = field.serverError;
      } else if (field.valid && field.warn) {
        errorMessage = field.warn;
      } else if (field.error) {
        errorMessage = field.error;
      } else if (field.valid) {
        errorMessage = null;
      }
    } else if (field.valid) {
      errorMessage = null;
    }

    // Not memoized, as value changes frequently.
    // Using DOM events outside the render function is not an option as this debounced function
    // may fire long after the element/component is removed from the view.
    const handleChange = (e) => {
      trackInputChanged.current(
        extractProperties(e, { icon, required: field.required, inputType })
      );
      onChange(e);
    };

    const handleBlur = useCallback(() => {
      if (!hasBlurred) {
        setHasBlurred(true);
      }
      return onBlur;
    }, [hasBlurred, onBlur]);

    useEffectOnce(() => {
      errorMessageExperiment?.exposeExperiment();

      // On unmount
      return trackInputChanged.current.flush;
    });

    const hideErrorMessageBeforeBlur = errorMessageExperiment?.isTreatment && !hasBlurred;
    const shouldDisplayErrorMessage = !!errorMessage && !hideErrorMessageBeforeBlur;

    const fieldClass = classNames([
      'fieldset',
      name,
      className,
      {
        'fieldset--dynamic': dynamic,
        'fieldset--fullwidth': !inline,
        'fieldset--hidden': field.hidden,
        'fieldset--outsideIcon': isOutsideIcon,
        'fieldset--insideIcon': !isOutsideIcon,
        'fieldset--hasIcon': icon,
        'fieldset--clickableIcon': _icon?.props.onClick,
        valid: editable && field.valid === true,
        invalid:
          editable && shouldDisplayErrorMessage && (field.valid === false || showServerError),
        noEdit: !editable,
        notEmpty: editable && (field.value || field.initial)
      }
    ]);
    const inputClass = classNames([name, 'userText', 'amp-block-track']);
    const domId = id || name;

    return (
      <div className={fieldClass} title={field.tooltip} style={rootStyle}>
        <div className="fieldset__iconAndInput">
          {icon && <div className="fieldset__icon">{icon}</div>}
          <Input
            hidden={field.hidden}
            ref={forwardedRef || inputRef}
            className={inputClass}
            name={name}
            id={domId}
            type={
              editable
                ? (passwordVisible && 'text') || inputType
                : (labelTextPreview && 'text') || inputType
            }
            data-lpignore={inputType === 'password' ? 'true' : undefined}
            min={min}
            max={max}
            maxLength={maxLength}
            step={step}
            disabled={!editable}
            autoComplete={autoComplete}
            value={
              editable
                ? !field.dirty && field.initial
                  ? field.initial
                  : field.value
                : labelTextPreview || field.initial || field.value
            }
            placeholder={editable ? '' : field.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={handleBlur}
            style={style}
            onKeyPress={onKeyPress}
            data-test-id={testId}
          />
          {!editable && (field.value || field.initial) ? null : (
            <label className="fieldset__label" htmlFor={domId}>
              {/* TODO: `<span aria-label="required">*</span>` */}
              {field.required && !hideAsterisk ? '* ' : ''}
              {labelText}
              {!field.required && showOptional ? ' (optional)' : ''}
            </label>
          )}
        </div>
        <AccordionVertical childKey={`${name}-errorMsg`} className="errorMsg-container">
          {shouldDisplayErrorMessage ? (
            <FieldError
              errorMessage={errorMessage}
              fieldDomId={domId}
              sanitizedFieldValue={getMaskedValue(field, inputType)}
              fieldRef={inputRef}
            />
          ) : null}
        </AccordionVertical>
      </div>
    );
  }
);

Fieldset.displayName = 'Fieldset';

Fieldset.propTypes = {
  dynamic: PropTypes.bool,
  editable: PropTypes.bool,
  id: PropTypes.string,
  tag: PropTypes.string,
  autoComplete: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // can accept an Icon too for example
  labelTextPreview: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.number,
  step: PropTypes.string,
  style: PropTypes.object,
  field: PropTypes.object.isRequired,
  inputType: PropTypes.oneOf(['text', 'number', 'password', 'email', 'tel', 'search']).isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  testId: PropTypes.string,
  icon: PropTypes.element,
  isOutsideIcon: PropTypes.bool,
  iconWidth: PropTypes.number,
  hideAsterisk: PropTypes.bool,
  showOptional: PropTypes.bool,
  onBlur: PropTypes.func
};

Fieldset.defaultProps = {
  dynamic: true,
  editable: true,
  inline: false,
  className: '',
  autoComplete: null,
  id: null,
  min: null,
  max: null,
  maxLength: null,
  step: null,
  tag: 'input',
  style: null,
  onKeyPress: null,
  labelTextPreview: '',
  labelText: '',
  testId: null,
  icon: null,
  isOutsideIcon: false,
  iconWidth: 30,
  hideAsterisk: false,
  showOptional: false,
  onBlur: null
};

export default memo(withLazyStyle(fieldsetStyle)(Fieldset));
