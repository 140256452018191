import { nanoid } from 'nanoid';
import Api from 'services/Api';
import { collectData as collectDeviceData } from 'lib/braintree';
import Modals from 'modules/Modals';
import { AT, T } from './actionTypes';

// AC Implementation functions
// Cam be split into an 'actionImpl.js' file
async function _refresh() {
  return Api.request.wallet.updateBalance();
}

// Action creators
export const refresh = () => ({
  type: AT.REFRESH,
  payload: _refresh()
});

export const updateBalance = (balance) => ({
  type: AT.UPDATE_BALANCE,
  payload: balance
});

export const subscribe = {
  type: AT.WS_SUBSCRIBE._,
  payload: {}
};

export const unsubscribe = {
  type: AT.WS_UNSUBSCRIBE._,
  payload: {}
};

export const getPaymentMethods = (dispatch) => {
  Api.actions.wallet.listPaymentMethods()(dispatch);
};

export const getPaymentMethodsDetailed = (dispatch) => {
  Api.actions.wallet.listPaymentMethodsDetailed()(dispatch);
};

export const getBalance = () => (dispatch) => {
  Api.actions.wallet.updateBalance()(dispatch);
};

export const deleteToken = (id) => ({
  type: T.DELETE_TOKEN,
  payload: id
});

export const setHasDeposited = (hasDeposited) => ({
  type: T.SET_HAS_DEPOSITED,
  payload: hasDeposited
});

export const setPciProxyError = (hasError, message) => ({
  type: T.SET_PCI_PROXY_ERROR,
  payload: { hasError, message }
});

export const setCurrentPci3dChallengeTransactionId = (transactionId) => ({
  type: T.SET_CURRENT_PCI_3D_CHALLENGE_TRANSACTION_ID,
  payload: transactionId
});

export const setHasOpenLoopWithdrawalStarted = (hasStarted) => ({
  type: T.SET_HAS_OPEN_LOOP_WITHDRAWAL_STARTED,
  payload: hasStarted
});

export const handlePciProxyError = (hasError, message, names) => (dispatch, getState) => {
  if (Array.isArray(names) && Modals.selectors.isVisible(getState(), names)) {
    // show message in the opened modal
    dispatch(setPciProxyError(hasError, message));
  } else if (message) {
    // show message as a toast notification
    dispatch(
      Modals.actions.create(nanoid(), {
        notification: true,
        creationDate: Date.now(),
        message,
        isHtml: true,
        expiration: 5000,
        announcement: false,
        pinned: true,
        blocking: false,
        severity: 1,
        prompt: null,
        destroyOnClose: true
      })
    );
  }
};

export const handleOpenLoopWithdrawalStarted = () => (dispatch, getState) => {
  const state = getState();
  if (state.Wallet.hasOpenLoopWithdrawalStarted) {
    dispatch(Modals.actions.open('withdraw'));
    dispatch(setHasOpenLoopWithdrawalStarted(false));
  }
};

export const _collectData = async (clientToken, isPaypal, isKount) => {
  const collected = await collectDeviceData(clientToken, isPaypal, isKount);
  return collected.deviceData;
};

export const collectData = (clientToken, isPaypal, isKount) => (dispatch) =>
  dispatch({
    type: AT.COLLECT_DATA._,
    payload: _collectData(clientToken, isPaypal, isKount)
  });

export const setMobile = (phoneNumber) => ({
  type: T.SET_MOBILE,
  payload: phoneNumber
});

export const unsetMobile = () => ({
  type: T.UNSET_MOBILE
});

export const updateMobile = (phoneNumber) => async (dispatch) => {
  const res = Api.actions.wallet.updateMobile(null, { phoneNumber })(dispatch);
  try {
    await res;
    dispatch(setMobile(phoneNumber));
    await Api.actions.wallet.sendSmsVerification()(dispatch);
    Api.actions.wallet.smsVerificationState()(dispatch);
    return res;
  } catch (e) {
    return res;
  }
};

export const overrideBalance = (balance) => ({
  type: T.OVERRIDE_BALANCE,
  payload: balance
});

export const cancelOverrideBalance = { type: T.CANCEL_OVERRIDE_BALANCE };

export const selectTransactions = (refName) => ({
  type: T.SELECT_TRANSACTIONS,
  payload: refName
});

export const selectAllTransactions = (refName) => ({
  type: T.SELECT_ALL_TRANSACTIONS,
  payload: refName
});

export const deselectTransactions = (refName) => ({
  type: T.DESELECT_TRANSACTIONS,
  payload: refName
});

export const deselectAllTransactions = {
  type: T.DESELECT_ALL_TRANSACTIONS
};

export const clearEcospendDeposit = {
  type: T.CLEAR_ECOSPED_DEPOSIT
};

export const selectTransactionsDeposit = (refName) => ({
  type: T.SELECT_TRANSACTIONS_DEPOSIT,
  payload: refName
});

export const selectAllTransactionsDeposit = (refName) => ({
  type: T.SELECT_ALL_TRANSACTIONS_DEPOSIT,
  payload: refName
});

export const deselectTransactionsDeposit = (refName) => ({
  type: T.DESELECT_TRANSACTIONS_DEPOSIT,
  payload: refName
});

export const deselectAllTransactionsDeposit = {
  type: T.DESELECT_ALL_TRANSACTIONS_DEPOSIT
};

export const setExpandedBankTransactionId = (id) => ({
  type: T.SET_EXPANDED_BANK_TRANSACTION_ID,
  payload: id
});

export const setExpandedGameTransactionId = (id) => ({
  type: T.SET_EXPANDED_GAME_TRANSACTION_ID,
  payload: id
});

export const pciProxy3dChallengePending = {
  type: AT.PCI_PROXY_3D_CHALLENGE.PENDING
};

export const pciProxy3dChallengeRejected = {
  type: AT.PCI_PROXY_3D_CHALLENGE.REJECTED
};

export const pciProxy3dChallengeFulfilled = {
  type: AT.PCI_PROXY_3D_CHALLENGE.FULFILLED
};

export const depositCardPciProxyRejected = {
  type: AT.DEPOSIT_CARD_PCI_PROXY.REJECTED
};
