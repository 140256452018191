import React from 'react';
import { formatCurrency } from 'lib/formatters';
import './depositRange.css';

interface DepositRangeProps {
  min: number;
  max: number;
}

const DepositRange = ({ min, max }: DepositRangeProps) => (
  <div className="depositRange">
    Deposit range:&nbsp;
    <strong className="depositRange__values">
      {formatCurrency(min)} - {formatCurrency(max)}
    </strong>
  </div>
);

export default DepositRange;
