import React from 'react';
import RouterLink from 'components/RouterLink';
import { Typography, TypographyVariant } from 'components/Typography';
import './invitedSummaryCard.css';

interface InvitedSummaryCardProps {
  registeredCount?: number;
  depositedCount?: number;
}

const InvitedSummaryCard = ({
  registeredCount = 0,
  depositedCount = 0
}: InvitedSummaryCardProps) => (
  <RouterLink href="/secure/user/account/refer-a-friend" className="invitedSummaryCard">
    <div className="invitedSummaryCard__detailList">
      <div className="invitedSummaryCard__detail">
        <Typography variant={TypographyVariant.HeaderXs} tabletVariant={TypographyVariant.HeaderSm}>
          <span className="invitedSummaryCard__count">{registeredCount}</span>
        </Typography>
        <Typography
          variant={TypographyVariant.BodySmStrong}
          tabletVariant={TypographyVariant.BodyMdStrong}
        >
          <span>
            {registeredCount === 1 ? 'Friend registered only' : 'Friends registered only'}
          </span>
        </Typography>
      </div>

      <div className="invitedSummaryCard__detail">
        <Typography variant={TypographyVariant.HeaderXs} tabletVariant={TypographyVariant.HeaderSm}>
          <span className="invitedSummaryCard__count invitedSummaryCard__count--deposited">
            {depositedCount}
          </span>
        </Typography>
        <Typography
          variant={TypographyVariant.BodySmStrong}
          tabletVariant={TypographyVariant.BodyMdStrong}
        >
          <span>{depositedCount === 1 ? 'Friend deposited' : 'Friends deposited'}</span>
        </Typography>
      </div>
    </div>
    <svg className="invitedSummaryCard__chevronIcon">
      <use href="/assets/images1/chevron-right.svg#icon" />
    </svg>
  </RouterLink>
);

export default InvitedSummaryCard;
