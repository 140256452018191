import Api from 'services/Api';
import type { AppDispatch } from '../../../../store';

type RegistrationOfferArgs = {
  affiliateId?: string;
  promoCode?: string;
};

type GetOfferArgs = {
  offerRef: string;
};

type RewardsCarouselArgs = {
  requestRetryCount?: number;
};

export const registrationOffer = (args: RegistrationOfferArgs) => (dispatch: AppDispatch) =>
  Api.actions.offers.registration(args)(dispatch);

export const referAFriendOffer = () => (dispatch: AppDispatch) =>
  Api.actions.offers.referAFriend()(dispatch);

export const rewardsCarousel = (args: RewardsCarouselArgs) => (dispatch: AppDispatch) =>
  Api.actions.offers.rewardsCarousel(args)(dispatch);

export const getOffer = (args: GetOfferArgs) => (dispatch: AppDispatch) =>
  Api.actions.offers.getOffer(args)(dispatch);
