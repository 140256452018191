import React, { memo } from 'react';
import Button from 'components/Button';
import FormError from 'components/FormError';
import type { getFormatted } from './helpers';

interface RejectViewProps {
  formattedValues: ReturnType<typeof getFormatted>;
  actionRequestErrorMessage: string | null;
  onTakeMeBack: () => void;
  onCancelLimitRaise: () => void;
}

const RejectView = ({
  formattedValues: formatted,
  actionRequestErrorMessage,
  onTakeMeBack,
  onCancelLimitRaise
}: RejectViewProps) => (
  <div>
    <p>
      If you cancel the change now, your{' '}
      <b>
        {formatted.current} a {formatted.period}
      </b>{' '}
      Spend Limit will remain.
    </p>

    <p>To change you would have to send another new request.</p>

    <FormError errorMessage={actionRequestErrorMessage} />

    <br />

    <form>
      <Button className="submit" onClick={onTakeMeBack} name="goBack" id="solicitations-goBack">
        Take me back
      </Button>
      <Button
        className="submit button__grey button--danger"
        onClick={onCancelLimitRaise}
        name="cancelLimitChange"
      >
        Cancel Spend Limit change
      </Button>
    </form>
  </div>
);

export default memo(RejectView);
