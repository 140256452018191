import { formatCurrency } from 'lib/formatters';

export const DEPOSIT_LIMIT_INTERVAL = {
  DAILY: 'depositLimitDaily',
  WEEKLY: 'depositLimitWeekly',
  MONTHLY: 'depositLimitMonthly'
};

export const NO_LIMIT = {
  VALUE: -1,
  LABEL: 'No limit'
} as const;

export const VALUE_NOT_SET = {
  value: 'VALUE_NOT_SET',
  LABEL: 'No Spend Limit set'
};

export const DAILY_AMOUNTS = [
  10,
  20,
  30,
  40,
  50,
  100,
  200,
  300,
  400,
  500,
  750,
  1000,
  2000,
  5000,
  NO_LIMIT.VALUE
] as const;
export const WEEKLY_AMOUNTS = [
  10,
  20,
  30,
  40,
  50,
  100,
  200,
  300,
  400,
  500,
  750,
  1000,
  2000,
  5000,
  10_000,
  NO_LIMIT.VALUE
] as const;
export const MONTHLY_AMOUNTS = [
  10,
  20,
  30,
  40,
  50,
  100,
  200,
  300,
  400,
  500,
  750,
  1000,
  2000,
  5000,
  10_000,
  20_000,
  NO_LIMIT.VALUE
] as const;

export const amountFormatter = (value: number) =>
  value < 0
    ? NO_LIMIT.LABEL
    : formatCurrency(value, {
        precise: false
      });

export const labelFormatter = (value?: number) =>
  value ? amountFormatter(value) : VALUE_NOT_SET.LABEL;
