import type { AuthState } from './types';

export const initialState: AuthState = {
  loggedThisWindow: false,
  partialUserRef: null,
  authenticated: null,
  loginError: null,
  registerError: null,
  isMultiFactorRequired: false,
  multiFactorAuthentication: null,
  username: null,
  password: null
};
