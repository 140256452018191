/* eslint-disable @typescript-eslint/no-unsafe-return -- Redux modules missing full TS support */
import React, { memo } from 'react';
import RewardCard from 'components/RewardCard';
import User from 'modules/User';
import { useAppSelector } from 'hooks/redux';
import useDailyFreeGames from './hooks/useDailyFreeGames';

export const DailyFreeGamesList = () => {
  const dailyFreeGames = useAppSelector((state) => User.selectors.getDailyFreeGames(state));
  const dailyFreeGameRefs = useAppSelector((state) => User.selectors.getDailyFreeGameRefs(state));
  const { openRewardTCModal, openPlayGuide, openHowToUnlockModal } = useDailyFreeGames();

  return (
    <>
      {dailyFreeGameRefs.map((ref: string) => {
        const {
          title,
          days,
          image,
          toc,
          currentDay,
          description,
          ribbonLabel,
          state,
          lockedStateOffer,
          playGuideOffer
        } = dailyFreeGames[ref];

        return (
          <RewardCard
            key={ref}
            title={title}
            description={description}
            ribbonLabel={ribbonLabel}
            status={state}
            imageUrl={image}
            path={ref}
            currentDay={currentDay}
            termsAndConditions={toc}
            isDfg
            dfgDays={days}
            openTCModal={openRewardTCModal}
            openPlayGuide={playGuideOffer && (() => openPlayGuide(playGuideOffer))}
            openHowToUnlockModal={
              lockedStateOffer && (() => openHowToUnlockModal(lockedStateOffer))
            }
          />
        );
      })}
    </>
  );
};
export default memo(DailyFreeGamesList);
