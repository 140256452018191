import React from 'react';
import cc from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import Button from 'components/Button';
import './limitsLabel.css';

interface LimitsLabelProps {
  onToggle: () => void;
  showLimits: boolean;
  limitsSaved: boolean;
}

const LimitsLabel = ({ onToggle, showLimits, limitsSaved }: LimitsLabelProps) => (
  <form className={cc(['limitsLabel', showLimits && 'limitsLabel__showLimits'])}>
    <Typography variant={TypographyVariant.HeaderXxs}>
      {limitsSaved ? <div>Spend limits saved!</div> : <div>Set Spend Limits?</div>}
    </Typography>
    <Typography variant={TypographyVariant.BodySm}>
      {limitsSaved ? (
        <span>You may change your Spend Limits later from your account.</span>
      ) : (
        <span>You may set Spend Limits now or later from your account. </span>
      )}
    </Typography>
    {!limitsSaved && (
      <Button
        name="toggleSetSpendLimits"
        className="button--fullWidth limitsLabel__setLimitsAction"
        unstyled
        onClick={onToggle}
      >
        <Typography variant={TypographyVariant.BodySmStrong}>
          <span>Set Spend Limits</span>
        </Typography>
        <svg width="20px" height="20px" color="var(--primaryColor)">
          <use
            href={
              showLimits
                ? '/assets/images1/chevron-filled-up.svg?cache=bust5#icon'
                : '/assets/images1/chevron-filled-down.svg?cache=bust5#icon'
            }
          />
        </svg>
      </Button>
    )}
  </form>
);

export default LimitsLabel;
