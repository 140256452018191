import { nanoid } from 'nanoid';
import { INITIAL_DEPOSIT_LIMIT_SOLICITATIONS } from './constants';

export const initialState = {
  balance: 0,
  lastTransactionType: '',
  lastDeposit: null,
  hasDeposited: undefined,
  firstWithdrawal: null,
  lastWithdrawal: null,
  merchantUrl: '',
  jsonFromServerSdk: '',
  agreedPaymentTerms: false,
  /** Key used to force remount the Paypal dropin in case of error */
  dropinKey: nanoid(),
  paymentMethods: {},
  paymentProviders: {},
  paymentProvidersAvailable: {},
  suggestedDepositAmounts: {},
  cardErrorCount: 0,
  cardErrorMessage: '',
  globalJackpotAmount: 0,
  pciProxyError: {
    hasError: false,
    message: ''
  },
  currentPci3dChallengeTransactionId: null,
  hasOpenLoopWithdrawalStarted: false,
  bankTransactionsDeposit: {},
  bankTransactions: {
    entities: {},
    result: []
  },
  gameTransactions: {
    entities: {},
    result: []
  },
  selectedTransactionsRefsDeposit: [],
  ecospendBanks: {},
  ecospendDeposit: {
    paylinkId: null,
    paymentUrl: null,
    smsSentAt: null,
    smsSentTo: null,
    sentSmsCount: null,
    qrCode: null,
    depositAmount: 0
  },
  token: {},
  expandedBankTransactionId: null,
  expandedGameTransactionId: null,
  selectedTransactionsRefs: [],
  totalAmountPending: 0,
  depositLimitSolicitations: INITIAL_DEPOSIT_LIMIT_SOLICITATIONS,
  depositLimitDaily: null,
  depositLimitWeekly: null,
  depositLimitMonthly: null
};

export const hasDeposited = (model) => model?.hasDeposited;
export const gameTransactions = (model) =>
  (model && model.gameTransactions && model.gameTransactions.entities) || undefined;
export const gameTransactionRefs = (model) =>
  (model && model.gameTransactions && model.gameTransactions.result) || undefined;
export const gameTransactionsHasNext = (model) =>
  (model && model.gameTransactionsPagination && model.gameTransactionsPagination.hasNext) || false;
export const gameTransactionsCursor = (model) =>
  (model && model.gameTransactionsPagination && model.gameTransactionsPagination.cursor) ||
  undefined;
export const bankTransactions = (model) =>
  (model && model.bankTransactions && model.bankTransactions.entities) || undefined;
export const bankTransactionRefs = (model) =>
  (model && model.bankTransactions && model.bankTransactions.result) || undefined;
export const bankTransactionsHasNext = (model) =>
  (model && model.bankTransactionsPagination && model.bankTransactionsPagination.hasNext) || false;
export const bankTransactionsCursor = (model) =>
  (model && model.bankTransactionsPagination && model.bankTransactionsPagination.cursor) ||
  undefined;
export const bankTransactionsDeposit = (model) =>
  (model && model.bankTransactionsDeposit && model.bankTransactionsDeposit.entities) || undefined;
export const bankTransactionRefsDeposit = (model) =>
  (model && model.bankTransactionsDeposit && model.bankTransactionsDeposit.result) || undefined;
export const bankTransactionsCountDeposit = (model) =>
  (model &&
    model.bankTransactionsPaginationDeposit &&
    model.bankTransactionsPaginationDeposit.totalElements) ||
  undefined;
export const selectedTransactionsRefs = (model) => (model && model.selectedTransactionsRefs) || [];
export const selectedTransactionsRefsDeposit = (model) =>
  (model && model.selectedTransactionsRefsDeposit) || [];
export const paymentMethods = (model) => model?.paymentMethods?.entities || undefined;
export const paymentMethodRefs = (model) => model?.paymentMethods?.result || undefined;
export const paymentProviders = (model) => model?.paymentProviders?.entities || undefined;
export const paymentProvidersRefs = (model) => model?.paymentProviders?.result || undefined;
export const paymentProvidersAvailable = (model) =>
  model?.paymentProvidersAvailable?.entities || undefined;
export const paymentProvidersAvailableRefs = (model) =>
  model?.paymentProvidersAvailable?.result || undefined;
export const suggestedDepositAmounts = (model) => model?.suggestedDepositAmounts;
export const withdrawProcessingTime = (model) => model && model.withdrawProcessingTime;
export const ecospendBanks = (model) =>
  (model && model.ecospendBanks && model.ecospendBanks.entities) || {};
export const ecospendBanksIds = (model) =>
  (model && model.ecospendBanks && model.ecospendBanks.result) || [];
export const totalAmountPending = (model) => (model && model.totalAmountPending) || 0;
export const expandedBankTransactionId = (model) => model && model.expandedBankTransactionId;
export const expandedGameTransactionId = (model) => model?.expandedGameTransactionId;
export const ecospendDeposit = (model) => (model && model?.ecospendDeposit) || {};
export const globalJackpotAmount = (model) => model?.globalJackpotAmount;
export const depositLimitDaily = (model) => model?.depositLimitDaily;
export const depositLimitWeekly = (model) => model?.depositLimitWeekly;
export const depositLimitMonthly = (model) => model?.depositLimitMonthly;
export const dropinKey = (model) => model?.dropinKey;
export const agreedPaymentTerms = (model) => model?.agreedPaymentTerms;
export const hasOpenLoopWithdrawalStarted = (model) => model?.hasOpenLoopWithdrawalStarted;
