import { flowRight } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import { isLoading } from 'lib/redux-utils';
import {
  NAME,
  kyc,
  GamifiedOfferStatus,
  kycMessages,
  VerificationType,
  VerificationProvider
} from './constants';
import * as m from './model';
import { AT } from './actionTypes';

export const model = (state) => state[NAME] || m.initialState;

export const getReferees = flowRight([m.referees, model]);
export const getRefereeRefs = flowRight([m.refereeRefs, model]);
export const getDisabledTabs = flowRight([m.disabledTabs, model]);
export const getRewards = flowRight([m.rewards, model]);
export const getDailyFreeGames = flowRight([m.dailyFreeGames, model]);
export const getDailyFreeGameRefs = flowRight([m.dailyFreeGameRefs, model]);
export const getDailyFreeGamesLoaded = flowRight([m.dailyFreeGamesLoaded, model]);
export const getSlotRewardsLoaded = flowRight([m.slotRewardsLoaded, model]);
export const getRewardOffers = flowRight([m.rewardOffers, model]);
export const getRewardsCount = flowRight([m.rewardsCount, model]);
export const getUsername = flowRight([m.username, model]);
export const getAffiliate = flowRight([m.affiliate, model]);
export const getFreeRounds = flowRight([m.freeRounds, model]);
export const getFreeRoundsGames = flowRight([m.freeRoundsGames, model]);
export const getFreeRoundsGamesStatus = flowRight([m.freeRoundsGamesStatus, model]);
export const getRawRequiresVerification = flowRight([m.requiresVerification, model]);
export const getVerificationStatus = flowRight([m.verificationStatus, model]);
export const getVerificationType = flowRight([m.verificationType, model]);
export const getVerificationProvider = flowRight([m.verificationProvider, model]);
export const getLiveChatUnreadCount = flowRight([m.liveChatUnreadCount, model]);
export const getIsLiveChatLoaded = flowRight([m.liveChatLoaded, model]);
export const getIsLauncherVisible = flowRight([m.isLauncherVisible, model]);
export const getReturningPlayer = flowRight([m.returningPlayer, model]);
export const getRegistrationPromoCode = flowRight([m.registrationPromoCode, model]);
export const getGnattaProfile = flowRight([m.gnattaProfile, model]);

// PBA stands for Potential Bonus Abuser, and promotions (promo codes) are disabled for these users.
export const getIsPromoCodeDisabled = flowRight([m.pbaFlag, model]);
export const getRefereesForStep = (refereeStep) =>
  createCachedSelector(getRefereeRefs, getReferees, (refereeRefs, referees) =>
    refereeRefs
      .filter((ref) => referees[ref].step === refereeStep)
      .map((ref) => ({
        ...referees[ref],
        referredDate: new Date(referees[ref].referredDate)
      }))
      // sort by newest
      .sort(
        (refereeA, refereeB) => refereeB.referredDate.valueOf() - refereeA.referredDate.valueOf()
      )
  )(
    () => refereeStep, // Use as cache key
    {
      cacheObject: new LruObjectCache({ cacheSize: 1 })
    }
  );

export const getFreeRoundsGameName = createSelector([getFreeRoundsGames], (freeRoundsGames) =>
  freeRoundsGames.length ? freeRoundsGames[0].name : ''
);

export const getGamifiedReferralStatus = createSelector(
  [getFreeRoundsGames, getFreeRoundsGamesStatus],
  (freeRoundsGames, freeRoundsGamesStatus) =>
    freeRoundsGamesStatus
      ? freeRoundsGames.map((game) => ({
          ...game,
          ...(game.ref && game.ref in freeRoundsGamesStatus && freeRoundsGamesStatus[game.ref])
        }))
      : freeRoundsGames
);

export const getIsAnyGamifiedOfferClaimable = (state) => {
  const gamifiedOffers = getGamifiedReferralStatus(state);
  return gamifiedOffers.some(
    ({ offerStatus, canClaim }) => offerStatus === GamifiedOfferStatus.ACTIVE && canClaim
  );
};

export const getHasLoadedAllRewards = createSelector(
  [getDailyFreeGamesLoaded, getSlotRewardsLoaded],
  (dfgs, slotRewards) => dfgs && slotRewards
);

export const getRewardsExist = createSelector(
  [getRewards, getDailyFreeGameRefs],
  (rewards, dailyFreeGames) => rewards.length > 0 || dailyFreeGames.length > 0
);

export const getRequiresVerification = createSelector(
  [getRawRequiresVerification, getVerificationType, getVerificationProvider],
  (requiresVerification, journey, provider) =>
    requiresVerification &&
    !(journey === VerificationType.EDD && provider === VerificationProvider.EMAIL)
);

export const getAllRewardsLoading = (state) =>
  isLoading(state, AT.GET_REWARDS._) || isLoading(state, AT.GET_DAILY_FREE_GAMES._);

export const getNetDeposits = flowRight([m.netDeposits, model]);
export const getNetBets = flowRight([m.netBets, model]);

export const getVerifiedPhoneNumber = (state) =>
  model(state).phoneVerified && model(state).phoneNumber;

export const getUnverifiedPhoneNumber = (state) =>
  !model(state).phoneVerified && model(state).phoneNumber;

export const getFirstName = (state) => model(state).firstName;
export const getFullName = (state) => `${model(state).firstName} ${model(state).lastName}`;
export const getRef = (state) => model(state).ref;

export const getKycStatus = (state) => {
  const status = model(state).kycStatus;
  const providers = model(state).kycProviders;
  const hasProviders = providers?.length > 0;

  if (status === kyc.PASS) {
    return kyc.PASS;
  }
  if (!providers) {
    return null;
  }
  if (status === kyc.FAIL && hasProviders) {
    return kyc.REFER;
  }
  if (status === kyc.REFER && !hasProviders) {
    return kyc.FAIL;
  }

  return status;
};

export const getFailedKycNotification = (kycStatus) => {
  if (kycStatus === kyc.PENDING) {
    return kycMessages.PENDING;
  } else if (kycStatus === kyc.NONE) {
    return kycMessages.NONE_NOTIFICATION;
  } else if (kycStatus === kyc.REFER) {
    return kycMessages.REFER_NOTIFICATION;
  } else if (kycStatus === kyc.FAIL) {
    return kycMessages.FAIL_NOTIFICATION;
  } else {
    return false;
  }
};

export const homeAddress = flowRight([m.homeAddress, model]);
export const getAvatar = flowRight([m.avatar, model]);
export const getRealityCheckInterval = flowRight([m.realityCheckInterval, model]);
export const getEmail = flowRight([m.email, model]);
export const getMarketingPreferences = flowRight([m.marketingPreferences, model]);
export const getConsentToMarketingPreferences = flowRight([m.consentToMarketingPreferences, model]);
