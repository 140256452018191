import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import loadable from '@loadable/component';
import { ErrorBoundary } from '@sentry/react';
import ScrollRestoration from 'components/ScrollRestoration';
import BaseRoutes from './router';
import store, { history } from './store';

const CrashFallback = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "CrashFallback" */
    'components/CrashFallback/CrashFallback'
  )
);

const Starter = () => (
  <ErrorBoundary fallback={<CrashFallback />}>
    <HelmetProvider>
      <Provider store={store}>
        <Router history={history}>
          <ScrollRestoration />
          <BaseRoutes />
        </Router>
      </Provider>
    </HelmetProvider>
  </ErrorBoundary>
);

export default Starter;
