import { useEffect } from 'react';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import usePrevious from 'hooks/usePrevious';

/**
 * Custom hook to refresh user rewards following a deposit transaction.
 */
const useRefreshRewards = () => {
  const dispatch = useAppDispatch();
  const lastTransactionType = useAppSelector(Wallet.selectors.getLastTransactionType);
  const balance = useAppSelector(Wallet.selectors.getBalanceReal);
  const prevBalance = usePrevious(balance);

  useEffect(() => {
    if (prevBalance < balance && lastTransactionType.includes('DEPOSIT')) {
      void Api.actions.user.getRewards()(dispatch);
      void Api.actions.user.getRewardOffers()(dispatch);
      void Api.actions.user.getDailyFreeGames()(dispatch);
    }
  }, [lastTransactionType, prevBalance, balance, dispatch]);
};

export default useRefreshRewards;
