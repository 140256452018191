import React from 'react';
import type { FormEvent, MouseEvent, FocusEventHandler } from 'react';
import cc from 'classcat';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import { ContactFieldType } from '../../constants';
import './inviteFormFields.css';

interface InviteFormFieldsProps {
  fieldId: string;
  nameField: object;
  contactField: object;
  contactFieldType: ContactFieldType;
  shouldShowDeleteButton: boolean;
  handleDeleteRow: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleFormChange: (e: FormEvent<HTMLFormElement>) => void;
  handleFocus: FocusEventHandler<HTMLInputElement>;
}

const InviteFormFields = ({
  fieldId,
  nameField,
  contactField,
  contactFieldType,
  shouldShowDeleteButton,
  handleDeleteRow,
  handleFormChange,
  handleFocus
}: InviteFormFieldsProps) => (
  <div className="inviteFormFields__container">
    <div
      className={cc([
        'inviteFormFields',
        { 'inviteFormFields--withDeleteButton': shouldShowDeleteButton }
      ])}
    >
      <div className="inviteFormFields__inputWrapper inviteFormFields__inputWrapperName">
        <Fieldset
          // @ts-expect-error -- field is not assignable because Fieldset is not typed
          field={nameField}
          inputType="text"
          autoComplete="off"
          name={`name_${fieldId}`}
          labelText="Friend's Name"
          onChange={handleFormChange}
          onFocus={handleFocus}
        />
      </div>
      <div className="inviteFormFields__inputWrapper">
        <Fieldset
          // @ts-expect-error -- field is not assignable because Fieldset is not typed
          field={contactField}
          inputType={contactFieldType}
          autoComplete="falsy"
          name={`contactDetails_${fieldId}`}
          labelText={contactFieldType === ContactFieldType.EMAIL ? 'Email address' : 'Mobile no.'}
          onChange={handleFormChange}
          onFocus={handleFocus}
        />
      </div>
      {shouldShowDeleteButton && (
        <Button
          className="button--iconButton button--quaternary inviteFormFields__deleteButton"
          title="Delete"
          name={fieldId}
          icon="/assets/images1/trash-danger.svg"
          onClick={handleDeleteRow}
          data-test-id="friend-button-delete"
        />
      )}
    </div>
  </div>
);

export default InviteFormFields;
