import React from 'react';
import cc from 'classcat';
import Tile from 'components/Tile';
import './tileMediaCardBody.css';

interface TileMediaCardBodyProps {
  imageUrl: string;
  imageAlt: string;
  imageSize?: number;
  isDarkVariant?: boolean;
  tileChildren?: React.ReactNode | null;
  className?: string;
  children: React.ReactNode;
}

const TileMediaCardBody = ({
  imageUrl,
  imageAlt,
  imageSize,
  isDarkVariant,
  tileChildren,
  className,
  children
}: TileMediaCardBodyProps) => (
  <div className={cc(['tileMediaCardBody', className])}>
    <Tile
      className="tileMediaCardBody__tile"
      imageUrl={imageUrl}
      resizeWidth={imageSize}
      imageAlt={imageAlt}
      isDarkVariant={isDarkVariant}
    >
      {tileChildren}
    </Tile>
    <div className="tileMediaCardBody__content">{children}</div>
  </div>
);

export default TileMediaCardBody;
