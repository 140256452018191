import React, { useState } from 'react';
import { MagicMove } from 'components/Transitions';
import { DepositLimitBase } from 'components/DepositLimit';
import { Typography, TypographyVariant } from 'components/Typography';
import LimitsLabel from './components/LimitsLabel';
import './setLimits.css';

interface SetLimitsProps {
  onDoneDepositLimit: () => void;
}

const SetLimits = ({ onDoneDepositLimit }: SetLimitsProps) => {
  const [showLimits, setShowLimits] = useState(false);
  const [limitsCompleted, setLimitsCompleted] = useState(false);

  const onToggle = () => setShowLimits((state) => !state);
  const handleLimitDone = async (request: Promise<any>) => {
    // The request is passed from DepositLimitBase and we are awaiting it here before going to the next step.
    // TODO: Add loading state, as we are waiting for the request to finish before going to the next step.
    setLimitsCompleted(true);
    await request;
    onDoneDepositLimit();
  };

  return (
    <MagicMove
      className="depositLimit collapsible"
      childKey={showLimits ? 'showLimits' : 'showToggle'}
    >
      <div>
        <LimitsLabel limitsSaved={limitsCompleted} onToggle={onToggle} showLimits={showLimits} />
        {!limitsCompleted && showLimits && (
          <>
            <Typography variant={TypographyVariant.BodySm}>
              <form className="setLimits__instruction">
                A Spend Limit lets you know how much you have left to deposit taking into
                consideration your withdrawals. Example: If your Spend Limit is £20 and you deposit
                £20, but then withdraw £10, you can deposit £10 more.
              </form>
            </Typography>
            <DepositLimitBase
              // @ts-ignore -- Until we refactor DepositLimitBase to TS
              onDone={handleLimitDone}
              noSubmitInitial={false}
            />
          </>
        )}
      </div>
    </MagicMove>
  );
};

export default SetLimits;
