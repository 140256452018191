import React, { memo, useState, useLayoutEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import cc from 'classcat';
import cfImage from 'lib/cfImage';
import { formatCurrency } from 'lib/formatters';
import RouterLink from 'components/RouterLink';
import * as Animation from 'components/Animation';
import { useTouchIsolation } from 'lib/useTouchIsolation';
import withLazyStyle from 'components/LazyStyle';
import { Typography, TypographyVariant } from 'components/Typography';
import { getExperiment } from 'lib/analytics';
import cdnImage from 'lib/cdnImage';
import style from './tile.css?lazy';

export enum Variant {
  Square = 'square',
  VerticalRectangle = 'rectangle'
}

export enum VariantHover {
  Scale = 'scale',
  Brightness = 'brightness',
  None = 'none'
}

const variantMap = {
  [Variant.Square]: 'tile--square',
  [Variant.VerticalRectangle]: 'tile--verticalRectangle'
};

const variantHoverMap = {
  [VariantHover.Scale]: 'tile--hoverScale',
  [VariantHover.Brightness]: 'tile--hoverBrightness',
  [VariantHover.None]: ''
};

export interface TileProps {
  variant?: Variant;
  variantHover?: VariantHover;
  imageUrl: string;
  imageAlt?: string;
  id?: string;
  title?: string;
  link?: string;
  isBlurred?: boolean;
  isDisabled?: boolean;
  isDarkVariant?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
  className?: string;
  isLoading?: boolean;
  /**
   * Indicates how the browser should load the image, see [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#loading)
   */
  imageLoadingType?: 'lazy' | 'eager';
  isolateTouch?: boolean;
  jackpotAmount?: number | null;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children?: ReactNode;
}

const shouldUseImageCDN = !!getExperiment('use-image-cdn')?.key;

const getImageSrc = shouldUseImageCDN ? cdnImage : cfImage;

const Tile = (props: TileProps) => {
  const containerRef = useRef(null);
  const {
    variant = Variant.Square,
    variantHover = VariantHover.Scale,
    link,
    imageUrl,
    imageAlt = '',
    title,
    isBlurred = false,
    isDisabled = false,
    isDarkVariant,
    resizeWidth,
    resizeHeight,
    className,
    isLoading = false,
    imageLoadingType,
    id,
    isolateTouch = false,
    jackpotAmount,
    onClick,
    children
  } = props;
  const shouldBlur = isBlurred || isDisabled;
  const shouldOptimiseImage = resizeWidth || resizeHeight || shouldBlur;

  // TODO: Remove or control with prop (isSmallImage ?) to improve performance if needed
  // Ticket: https://mrq.atlassian.net/browse/MQG-243
  // Discussion: https://github.com/lindar-joy/mrq-front/pull/2481#discussion_r1452572278
  // Runtime performance may be affected by the extra render as well as the CSS transition.
  // Also note that starting with isImageLoaded true, we'll randomly see
  // a very short browser default broken image before custom fallback.
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageError(true);
  };

  useTouchIsolation({ containerRef, isActive: isolateTouch });

  const src = !shouldOptimiseImage
    ? imageUrl
    : getImageSrc(imageUrl, {
        width: resizeWidth,
        height: resizeHeight,
        ignoreGif: !shouldBlur,
        blur: shouldBlur ? 25 : undefined,
        brightness: shouldBlur ? 0.8 : undefined
      });

  useLayoutEffect(() => {
    // Do not abuse this pattern!
    // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
    // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
    setIsImageLoaded(false);
    setIsImageError(false);
  }, [src]);

  const rootClassName = cc([
    'tile',
    className,
    variantMap[variant],
    variantHoverMap[variantHover],
    {
      'tile--darker': isDarkVariant
    }
  ]);
  const imageClassName = cc(['tile__image', { 'tile__image--loaded': isImageLoaded }]);
  const body = (
    <>
      {isLoading ? (
        <Animation.Root className="tile__shimmer">
          {isDarkVariant ? <Animation.ShimmerInputSecondary /> : <Animation.ShimmerInputPrimary />}
        </Animation.Root>
      ) : isImageError ? (
        <>
          <div className="tile__imageError">
            <span className="tile__imageErrorContent">{imageAlt || title || 'Missing image'}</span>
          </div>
          {children ? <div className="tile__content">{children}</div> : null}
        </>
      ) : (
        <>
          {isDisabled && !children && (
            <div className="tile__overlay--disabled">
              <img
                alt="Maintenance icon"
                className="marker__icon tile__overlay__logo--disabled"
                src="/assets/images1/maintenance-icon.svg"
              />
              <Typography
                variant={TypographyVariant.BodyXsStrong}
                desktopVariant={TypographyVariant.BodyXsStrong}
              >
                <span className="tile__overlay__title-disabled">Game under maintenance</span>
              </Typography>
            </div>
          )}

          {isBlurred && !isDisabled && !children && (
            <Typography
              variant={TypographyVariant.BodyMdStrong}
              desktopVariant={TypographyVariant.BodyMdStrong}
            >
              <span className="tile__title">{imageAlt || title}</span>
            </Typography>
          )}

          <img
            // Warning! `loading` must come before `src` in Safari and Firefox
            // https://github.com/facebook/react/issues/25883
            loading={imageLoadingType}
            className={imageClassName}
            alt={isDisabled ? `${imageAlt} game disabled` : imageAlt}
            // Due to the bug above, srcSet goes between `loading` and `src`
            src={src}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />

          {isImageLoaded && <div className="tile__content">{children}</div>}
        </>
      )}

      {!isLoading && jackpotAmount && (
        <Typography variant={TypographyVariant.BodyMdStrong}>
          <span className="tile__jackpot">{formatCurrency(jackpotAmount)}</span>
        </Typography>
      )}
    </>
  );

  return link ? (
    <RouterLink
      id={id}
      className={rootClassName}
      href={link}
      title={title}
      innerRef={containerRef}
      onClick={onClick}
    >
      {body}
    </RouterLink>
  ) : (
    <div id={id} className={rootClassName} title={title} ref={containerRef} onClick={onClick}>
      {body}
    </div>
  );
};

export default memo(withLazyStyle(style)(Tile));
