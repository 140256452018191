import React from 'react';
import { logos, paymentMethodType } from 'lib/constants';

export const derivePaymentMethodLogo = (method) => {
  const operatorLogo = Object.keys(logos).includes(method.operator) && method.operator;
  const typeLogo = Object.keys(logos).includes(method.type) && method.type;
  const bankLogo = method.bankLogo;
  return bankLogo || logos[operatorLogo || typeLogo] || logos.CARD;
};

export const deriveMethodLabel = (method) => {
  switch (method.type) {
    case paymentMethodType.CARD:
      return (
        <>
          Ending in <b>{method.identifier}</b>
        </>
      );
    case paymentMethodType.MOBILE:
    case paymentMethodType.PAYPAL:
      return <b>{method.identifier}</b>;
    case paymentMethodType.BANK_ACCOUNT:
      return <b>{method.identifier || method.operator}</b>;
    default:
      return 'Payment Method';
  }
};

export const deriveRemoveMessage = (method) => {
  switch (method.type) {
    case paymentMethodType.CARD:
      return `Are you sure you want to remove ${method.operator} ending in ${method.identifier}?`;
    case paymentMethodType.BANK_ACCOUNT: {
      const bankLabel = method.operator || 'the';
      if (method.identifier)
        return `Are you sure you want to remove ${bankLabel} bank account with identfier ${method.identifier}?`;
      else if (method.operator) return `Are you sure you want to remove ${bankLabel} bank account?`;
      return `Are you sure you want to remove the bank account?`;
    }
    default:
      return `Are you sure you want to remove the payment method?`;
  }
};
