const scriptRegistry = {};

export function importScript(url, namespace, position = 'head') {
  scriptRegistry[url] =
    scriptRegistry[url] ||
    new Promise((resolve, reject) => {
      if (typeof window[namespace] === 'undefined') {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => resolve(window[namespace]);
        script.onerror = (message, source, lineno, colno, error) => {
          // eslint-disable-next-line no-console -- expect error
          console.error(message);
          delete scriptRegistry[url];
          reject(error);
        };

        document[position].appendChild(script);
      } else {
        resolve(window[namespace]);
      }
    });

  return scriptRegistry[url];
}

export function removeScript(url, position = 'head') {
  const scriptElements = document[position].getElementsByTagName('script');
  for (let i = 0; i < scriptElements.length; i++) {
    if (scriptElements[i].src === url) {
      document[position].removeChild(scriptElements[i]);
      delete scriptRegistry[url];
      break;
    }
  }
}

export default importScript;
