import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import amplitude from 'lib/analytics';
import isAppPath from 'lib/externals';
import Modals from 'modules/Modals';
import { openLiveChat } from 'components/LiveChat';

/**
 * @typedef {import("./types").LinkClickedAnalytics} LinkClickedAnalytics
 */

/**
 * @param {LinkClickedAnalytics} properties
 * @returns {import("@amplitude/analytics-types").AmplitudeReturn<import("@amplitude/analytics-types").Result>}
 */
const trackLinkClicked = (properties) => amplitude.track('Link Clicked', properties);

const onClickLiveChatLink = () => openLiveChat('link');

/**
 * Extract properties and prepare them for the "Button Clicked" event.
 *
 * @param e {import('react').MouseEvent}
 * @param properties
 * @param [properties.actionRefs] {string[]}
 * @returns {LinkClickedAnalytics}
 */
export const extractProperties = ({ currentTarget }, { actionRefs }) => ({
  // Consider using data-ampli-id
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ID]: currentTarget.id || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS]: currentTarget.className || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TEXT]: currentTarget.textContent?.trim?.() || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ARIA_LABEL]:
    currentTarget.getAttribute('aria-label') || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TITLE]: currentTarget.title || undefined,
  'Link Target': currentTarget.target || '_self',
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TAG]: currentTarget.tagName.toLowerCase(),
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_HREF]: currentTarget.href || undefined,
  'Action Refs': actionRefs
});
const openLiveChatAction = '$open_live_chat';

const RouterLink = (props) => {
  const {
    href,
    title,
    target,
    className: incomingClassName,
    rel,
    children,
    style,
    onClick,
    replace,
    id,
    innerRef,
    actionRefs
  } = props;
  const isMrQApp = href && isAppPath(href);
  const isMail = href && href.substring(0, 6) === 'mailto';
  const isAction = props && Object.keys(props).includes(href);
  const action = isAction ? props[href] : undefined;
  const defaultRel = cc(['noreferrer', 'noopener', !isMrQApp && 'nofollow']);
  const isAnchor = /^(?:https?:)?\/\//.test(href) || isMail || !isMrQApp || isAction;
  const className = cc([incomingClassName, 'amp-block-track']);

  const handleClick = useCallback(
    (e) => {
      trackLinkClicked(extractProperties(e, { actionRefs }));
      if (action && isAnchor) {
        action(e);
      } else if (onClick) {
        onClick(e);
      }
    },
    [action, actionRefs, isAnchor, onClick]
  );

  if (!href) {
    return (
      <a className={className} style={style} onClick={onClick} title={title} ref={innerRef}>
        {children}
      </a>
    );
  }

  if (href === openLiveChatAction) {
    return (
      <a
        className={className}
        style={style}
        onClick={onClickLiveChatLink}
        title={title}
        ref={innerRef}
      >
        {children}
      </a>
    );
  }

  return isAnchor ? (
    <a
      href={!isAction ? href : undefined}
      title={title}
      target={!isMail ? target || '_blank' : target || null}
      rel={!target || target === '_blank' ? defaultRel : rel}
      className={className}
      id={id}
      style={style}
      onClick={handleClick}
      ref={innerRef}
    >
      {children}
    </a>
  ) : (
    <Link
      to={href}
      title={title}
      target={target}
      rel={rel}
      className={className}
      id={id}
      style={style}
      onClick={handleClick}
      replace={replace}
      ref={innerRef}
    >
      {children}
    </Link>
  );
};

RouterLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  href: PropTypes.string,
  title: PropTypes.string,
  actionRefs: PropTypes.arrayOf(PropTypes.string),
  target: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  rel: PropTypes.string
};

RouterLink.defaultProps = {
  href: null,
  title: null,
  actionRefs: undefined,
  target: null,
  onClick: undefined,
  className: null,
  id: undefined,
  style: null,
  innerRef: null,
  rel: null
};

// TODO MRQ-1486: Refactor `LatestTerms` to use `onLinkClick` like in `DialogArticle`, instead of connecting this
//  to redux
const mapDispatchToProps = (dispatch) => ({
  openTermsConditions: (ev) => {
    ev.preventDefault();
    dispatch(Modals.actions.open('termsConditions'));
  }
});

export default connect(null, mapDispatchToProps)(RouterLink);
