import React from 'react';
import { formatCurrency } from 'lib/formatters';
import { MIN_DEPOSIT } from 'lib/valFunctors';
import { Typography, TypographyVariant } from 'components/Typography';
import Button from 'components/Button';
import './exceededSpendLimits.css';

interface ExceededSpendLimitsProps {
  nextAllowedDepositDate: string;
  onConfirm: () => void;
}

const ExceededSpendLimits = ({ nextAllowedDepositDate, onConfirm }: ExceededSpendLimitsProps) => (
  <form className="exceededSpendLimits">
    <Typography variant={TypographyVariant.BodySm}>
      <div>
        You’re almost at your Spend Limit with under {formatCurrency(MIN_DEPOSIT)} left so you
        cannot make a deposit. Try again on {nextAllowedDepositDate}.
      </div>
    </Typography>
    <div>
      <Button onClick={onConfirm} name="exceededLimitsConfirm" className="button--fullWidth">
        Got it
      </Button>
    </div>
  </form>
);

export default ExceededSpendLimits;
