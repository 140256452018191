export enum VerificationDocumentType {
  POI = 'POI', // Proof of Identity
  ADDITIONAL_POI = 'ADDITIONAL_POI', // Additional Proof of Identity
  POA = 'POA', // Proof of Address
  POO_CARD = 'POO_CARD', // Proof of Ownership of Card
  POO_MOBILE = 'POO_MOBILE', // Proof of Ownership of Mobile
  ID_NEXT_TO_FACE = 'ID_NEXT_TO_FACE', // ID next to face
  ID_NEXT_TO_FACE_AND_DATE = 'ID_NEXT_TO_FACE_AND_DATE', // ID next to face and date written on card
  THIRD_PARTY_ID = 'THIRD_PARTY_ID' // Third party ID (for third party payment method)
}

export enum VerificationDocumentStatus {
  REQUESTED = 'REQUESTED', // Document has been requested
  IN_PROGRESS = 'IN_PROGRESS', // Player has started the process of sending us the document
  REJECTED = 'REJECTED', // Document has been rejected
  APPROVED = 'APPROVED', // Document has been approved
  RECEIVED = 'RECEIVED' // Document has been received
}

export enum VerificationDocumentSendType {
  HOOYU = 'HOOYU',
  EMAIL = 'EMAIL'
}

export type VerificationDocument = {
  id: string;
  type: VerificationDocumentType;
  sendType: VerificationDocumentSendType;
  status: VerificationDocumentStatus;
  statusReason?: string;
  identifier?: string;
};

export interface KycState {
  verificationDocuments: VerificationDocument[];
}
