export enum Screen {
  INITIAL = 'initial',
  EMAIL = 'email',
  TEL = 'tel',
  COMPLETED = 'completed'
}

export const screens: { [id: string]: Screen } = {
  initial: Screen.INITIAL,
  email: Screen.EMAIL,
  tel: Screen.TEL,
  completed: Screen.COMPLETED
};

export enum ContactFieldType {
  TEL = 'tel',
  EMAIL = 'email'
}

export const screenToContactFieldType: { [key in Screen]: ContactFieldType | null } = {
  [Screen.INITIAL]: null,
  [Screen.TEL]: ContactFieldType.TEL,
  [Screen.EMAIL]: ContactFieldType.EMAIL,
  [Screen.COMPLETED]: null
};

export const contactFieldTypeToScreen: { [key in ContactFieldType]: Screen | null } = {
  [ContactFieldType.TEL]: Screen.TEL,
  [ContactFieldType.EMAIL]: Screen.EMAIL
};

export const inviteFormName: { [key in ContactFieldType]: string } = {
  [ContactFieldType.TEL]: 'referAFriendTel',
  [ContactFieldType.EMAIL]: 'referAFriendEmail'
};
