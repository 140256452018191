import { differenceInSeconds } from 'date-fns';

const getSecondsToUTCMidnight = () => {
  const now = new Date();
  const utcNow = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );

  // Construct the next midnight *in UTC* instead of local time
  const nextUtcMidnight = new Date(
    Date.UTC(utcNow.getUTCFullYear(), utcNow.getUTCMonth(), utcNow.getUTCDate() + 1, 0, 0, 0)
  );

  return differenceInSeconds(nextUtcMidnight, utcNow);
};

export default getSecondsToUTCMidnight;
