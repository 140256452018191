import { useState, useEffect, useCallback, type RefObject } from 'react';

interface ScrollVisibilityState {
  hasHorizontalScroll: boolean;
  hasVerticalScroll: boolean;
}

const useScrollVisibility = (containerRef: RefObject<HTMLElement>): ScrollVisibilityState => {
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

  const checkScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollWidth, clientWidth, scrollHeight, clientHeight } = container;

    setHasHorizontalScroll(scrollWidth > clientWidth);
    setHasVerticalScroll(scrollHeight > clientHeight);
  }, [containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    checkScroll(); // Immediate check on mount

    window.addEventListener('resize', checkScroll);

    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, [checkScroll, containerRef]);

  return {
    hasHorizontalScroll,
    hasVerticalScroll
  };
};

export default useScrollVisibility;
