import React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { isLoading as _isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import User from 'modules/User';
import { RefereeStep } from 'components/ReferAFriendRefereeLists';
import { Typography, TypographyVariant } from 'components/Typography';
import Button from 'components/Button';
import UserBox from 'components/UserBox';
import InviteActionCard from 'components/InviteActionCard';
import InvitedSummaryCard from 'components/InvitedSummaryCard';
import InviteOfferCard, {
  InviteOfferCardSkeleton,
  type GamifiedOffer
} from 'components/InviteOfferCard';
import Confetti from 'components/Confetti';
import { inviteProgressScreenTitle, inviteProgressScreenIntro } from '../../data';
import './gamifiedReferralsInviteProgress.css';

interface GamifiedReferralsInviteProgressAdapterProps {
  close?: () => void;
  onMoreInfoClick: () => void;
  onTAndCsClick: () => void;
}

interface GamifiedReferralsInviteProgressProps {
  isLoading?: boolean;
  username: string;
  registeredInvitesCount?: number;
  depositedInvitesCount?: number;
  gamifiedOffers: GamifiedOffer[];
  isAnyOfferClaimable?: boolean;
  onMoreInfoClick: () => void;
  onTAndCsClick: () => void;
  close?: () => void;
}

export const GamifiedReferralsInviteProgress = ({
  isLoading,
  username,
  registeredInvitesCount,
  depositedInvitesCount,
  gamifiedOffers,
  isAnyOfferClaimable,
  onMoreInfoClick,
  onTAndCsClick,
  close
}: GamifiedReferralsInviteProgressProps) => {
  const showInvitedSummary = Boolean(registeredInvitesCount || depositedInvitesCount);

  return (
    <UserBox
      title={inviteProgressScreenTitle}
      closeCallback={close}
      className="gamifiedReferralsInviteProgress"
    >
      {isAnyOfferClaimable && <Confetti className="gamifiedReferralsInviteProgress__confetti" />}

      <div className="gamifiedReferralsInviteProgress__intro">
        <Typography
          variant={TypographyVariant.BodyMdStrong}
          tabletVariant={TypographyVariant.BodyLgStrong}
        >
          <span>{inviteProgressScreenIntro}</span>
        </Typography>

        <div className="gamifiedReferralsInviteProgress__summary">
          {showInvitedSummary && (
            <InvitedSummaryCard
              registeredCount={registeredInvitesCount}
              depositedCount={depositedInvitesCount}
            />
          )}

          <InviteActionCard username={username} />
        </div>
      </div>

      {isLoading ? (
        <div className="gamifiedReferralsInviteProgress__offers">
          {Array.from(Array(3).keys()).map((i) => (
            <InviteOfferCardSkeleton key={i} />
          ))}
        </div>
      ) : gamifiedOffers.length ? (
        <div className="gamifiedReferralsInviteProgress__offers">
          {gamifiedOffers.map(
            ({
              numberOfFreeRounds,
              title,
              src,
              invitedReferees,
              totalReferees,
              offerStatus,
              canClaim
            }: GamifiedOffer) => (
              <InviteOfferCard
                key={title}
                freeRounds={numberOfFreeRounds}
                freeRoundsGameName={title}
                imageUrl={src}
                invitedCount={invitedReferees}
                maxInvitedCount={totalReferees}
                offerStatus={offerStatus}
                canClaim={canClaim}
              />
            )
          )}
        </div>
      ) : null}

      <div className="gamifiedReferralsInviteProgress__footer">
        <Button
          className="button--quaternary gamifiedReferralsInviteProgress__footerButton"
          name="gamifiedReferralMoreInfo"
          onClick={onMoreInfoClick}
        >
          <Typography variant={TypographyVariant.BodySm}>
            <span>More info</span>
          </Typography>
        </Button>
        <Button
          className="button--quaternary gamifiedReferralsInviteProgress__footerButton"
          name="gamifiedReferralTAndCsApply"
          onClick={onTAndCsClick}
        >
          <Typography variant={TypographyVariant.BodySm}>
            <span>T&Cs apply</span>
          </Typography>
        </Button>
      </div>
    </UserBox>
  );
};

const GamifiedReferralsInviteProgressAdapter = ({
  onMoreInfoClick,
  onTAndCsClick,
  close
}: GamifiedReferralsInviteProgressAdapterProps) => {
  const dispatch = useAppDispatch();
  const getFreeRounds = () => Api.actions.user.getFreeRounds()(dispatch);
  const getReferrals = () => Api.actions.user.getReferralStatus()(dispatch);
  const isLoading = useAppSelector((state) =>
    _isLoading(state, User.actionTypes.AT.GET_REFERRAL_STATUS._)
  );
  const username = useAppSelector(User.selectors.getUsername);
  const gamifiedOffers = useAppSelector(User.selectors.getGamifiedReferralStatus);
  const isAnyOfferClaimable = useAppSelector(User.selectors.getIsAnyGamifiedOfferClaimable);
  const registered = useAppSelector(User.selectors.getRefereesForStep(RefereeStep.REGISTERED));
  const deposited = useAppSelector(User.selectors.getRefereesForStep(RefereeStep.DEPOSITED));

  useEffectOnce(() => {
    void getReferrals();
    void getFreeRounds();
  });

  return (
    <GamifiedReferralsInviteProgress
      isLoading={isLoading}
      username={username}
      registeredInvitesCount={registered.length}
      depositedInvitesCount={deposited.length}
      gamifiedOffers={gamifiedOffers}
      isAnyOfferClaimable={isAnyOfferClaimable}
      onMoreInfoClick={onMoreInfoClick}
      onTAndCsClick={onTAndCsClick}
      close={close}
    />
  );
};

export default GamifiedReferralsInviteProgressAdapter;
