import React, { useCallback } from 'react';
import cc from 'classcat';
import { type SpinDay as SpinDayType, SpinDayStatus } from 'modules/Offers';
import { Typography, TypographyVariant } from 'components/Typography';
import RouterLink from 'components/RouterLink';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import { EffectTypes } from 'services/Effects';
import Markdown from 'components/Markdown';
import { spinDayData } from './data';
import './spinDay.css';

type SpinDayProps = Omit<SpinDayType, 'ref'> & { refName: string };

const SpinDay = ({
  refName,
  status,
  dateText,
  isBlocked,
  thumbnailUrl,
  expiresText,
  isAssigningFreeSpins,
  playerNeedsToReLogin,
  details
}: SpinDayProps) => {
  const dispatch = useAppDispatch();
  const { game, numberOfFreespins } = details ?? {};
  const isReadyToCollect = status === SpinDayStatus.CURRENT && !isBlocked && !isAssigningFreeSpins;
  const link = isReadyToCollect ? `/secure/games/${game}` : '';
  const isCurrent = status === SpinDayStatus.CURRENT;
  const isFuture = status === SpinDayStatus.FUTURE;
  const shouldShowSpinsInfo = !isFuture && numberOfFreespins;
  const shouldShowExpiresText = isCurrent && expiresText;

  const trackCardClicked = useCallback(() => {
    dispatch(
      Analytics.actions.offerClicked({
        refName,
        type: 'Mayhem',
        source: 'Mayhem',
        isBlocked,
        isAssigningFreeSpins,
        dayStatus: status,
        interactionType: EffectTypes.OfferClickedInteractionType.CardClick
      })
    );
  }, [refName, isBlocked, status, isAssigningFreeSpins, dispatch]);

  return (
    <RouterLink
      href={link}
      onClick={trackCardClicked}
      className={cc(['spinDay', { 'spinDay--active': isReadyToCollect || isAssigningFreeSpins }])}
    >
      <div className="spinDay__content" style={{ backgroundImage: `url(${thumbnailUrl})` }}>
        <div className="spinDay__textWrapper spinDay__date">
          <Typography variant={TypographyVariant.BodyXxsStrong}>
            <span>{dateText}</span>
          </Typography>
        </div>

        {isCurrent && isAssigningFreeSpins ? (
          <div className="spinDay__pending">
            <Markdown
              content={
                playerNeedsToReLogin ? spinDayData.needsReLogin : spinDayData.isAssigningFreeSpins
              }
            />
          </div>
        ) : (
          <>
            {shouldShowSpinsInfo && (
              <div className="spinDay__quantity">
                <Typography variant={TypographyVariant.HeaderXl}>
                  <div className="spinDay__quantityValue">{numberOfFreespins}</div>
                </Typography>
                <Typography variant={TypographyVariant.BodyMdStrong}>
                  <div className="spinDay__quantitySpins">Spins</div>
                </Typography>
              </div>
            )}

            {shouldShowExpiresText && (
              <div className="spinDay__textWrapper spinDay__expiring">
                <Typography variant={TypographyVariant.BodyXxsStrong}>
                  <div>{expiresText}</div>
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </RouterLink>
  );
};

export default SpinDay;
