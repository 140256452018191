import React from 'react';
import Button from 'components/Button';
import VerticalSteps from 'components/VerticalSteps';
import { stepsData } from './constants';
import './confirmWithdrawal.css';

interface ConfirmWithdrawalProps {
  openWithdraw: () => void;
  close: () => void;
}

const ConfirmWithdrawal = ({ openWithdraw, close }: ConfirmWithdrawalProps) => (
  <div className="confirmWithdrawal">
    <img
      className="userBox__image"
      src="/assets/images1/withdrawal.svg"
      alt=""
      data-chromatic="ignore"
    />
    <VerticalSteps items={stepsData} />
    <div className="confirmWithdrawal__buttons">
      <Button
        title="Continue"
        buttonText="Proceed with withdrawal"
        name="openWithdrawal"
        id="confirmWithdrawal-openWithdrawal"
        onClick={openWithdraw}
      />
      <Button
        className="button__grey"
        title="Cancel"
        buttonText="Cancel"
        onClick={close}
        name="close"
        id="confirmWithdrawal-close"
      />
    </div>
  </div>
);

export default ConfirmWithdrawal;
