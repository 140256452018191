import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import InvitedProgressPips from '../InvitedProgressPips';
import './upcomingOfferCard.css';

interface UpcomingOfferCardProps {
  freeRounds: number;
  freeRoundsGameName: string;
  maxInvitedCount: number;
}

const UpcomingOfferCard = ({
  freeRounds,
  freeRoundsGameName,
  maxInvitedCount
}: UpcomingOfferCardProps) => (
  <div className="upcomingOfferCard">
    <div className="upcomingOfferCard__bodyDetails">
      <Typography variant={TypographyVariant.BodySm} tabletVariant={TypographyVariant.BodyMd}>
        <span>
          <Typography
            variant={TypographyVariant.BodySmStrong}
            tabletVariant={TypographyVariant.BodyMdStrong}
          >
            <em className="upcomingOfferCard__freeSpinHighlight">{freeRounds} spins</em>
          </Typography>{' '}
          for each friend
        </span>
      </Typography>

      <InvitedProgressPips current={0} total={maxInvitedCount} />
    </div>

    <div className="upcomingOfferCard__bodyFooter">
      <Typography variant={TypographyVariant.BodyXs} tabletVariant={TypographyVariant.BodySm}>
        <span className="upcomingOfferCard__smallText">
          <Typography
            variant={TypographyVariant.BodyXsStrong}
            tabletVariant={TypographyVariant.BodySmStrong}
          >
            <span className="upcomingOfferCard__gameNameHighlight">On {freeRoundsGameName}</span>
          </Typography>
          <span className="upcomingOfferCard__invitedCountText">
            • 0/
            {maxInvitedCount > 0 ? maxInvitedCount : 'Unlimited'} Friends
          </span>
        </span>
      </Typography>
    </div>
  </div>
);

export default UpcomingOfferCard;
