import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  type MarketingPreferencesFormKeys,
  initialMarketingPreferencesFormState
} from 'components/MarketingPreferences';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import User, { type MarketingPreferencesState } from 'modules/User';
import Api from 'services/Api';

export const useUpdateAccountMarketingPreferences = () => {
  const methods = useForm({ defaultValues: initialMarketingPreferencesFormState });
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<MarketingPreferencesFormKeys | null>(null);
  const marketingPreferences: MarketingPreferencesState = useAppSelector(
    User.selectors.getMarketingPreferences
  );

  useEffect(
    () => () =>
      void (async () => {
        await Api.actions.user.getSettings()(dispatch);
      })(),
    [dispatch]
  );

  useEffect(() => {
    methods.reset((prev) => ({ ...prev, ...marketingPreferences }));
  }, [methods, marketingPreferences]);

  const submitFormData = useCallback(
    async (key?: MarketingPreferencesFormKeys) => {
      const data = methods.getValues();

      const { CASINO, BINGO } = data;

      if (key) setLoading(key);

      try {
        await Api.actions.user.updateMarketingPreferences(null, {
          verticals: {
            CASINO,
            BINGO
          }
        })(dispatch);
        setLoading(null);
      } catch (err) {
        await Api.actions.user.getSettings()(dispatch);
        setLoading(null);
      }
    },
    [dispatch, methods]
  );

  return { methods, loading, submitFormData };
};
